<template class="top">
  <v-app light>
    <v-responsive>
      <v-toolbar class="white">
        <v-toolbar-side-icon
          ><img src="../../public/logo.png" alt="logo" class="logo" />
        </v-toolbar-side-icon>

        <v-spacer></v-spacer>

        <v-list class="d-flex align center hidden-sm-and-up">
          <v-list-item
            link
            v-for="(menu, index) in menus"
            @click="$vuetify.goTo(menu.href)"
            :key="index"
            :to="menu.route"
          >
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-btn :href="paths" @input="getlogin" class="primary "
          >Connexion</v-btn
        >
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-1" text v-bind="attrs" v-on="on">
              <v-icon color="grey">
                mdi-earth
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-title>
              <v-btn text width="100%" @click="changedtofr">
                Français
              </v-btn>
            </v-list-item-title>

            <v-list-item-title>
              <v-btn text width="100%" @click="changedtoen">
                English
              </v-btn>
            </v-list-item-title>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-responsive>
    <v-main>
      <section class="section" id="accueil">
        <v-responsive>
          <v-carousel cycle hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item>
              <v-parallax :src="parallax" height="490">
                <v-layout
                  column
                  align-center
                  justify-center
                  class="carousel-title"
                >
                  <h1 class="mb-2 display-1 text-xs-center ">
                    Bienvenue dans votre application de gestion prefere
                  </h1>
                  <div class="white--text subheading mb-3 text-xs-center">
                    Unlesh your creativity without limitations
                  </div>
                  <v-btn
                    :href="paths"
                    @input="getlogin"
                    class="primary mt-5 dark large"
                  >
                    Connexion
                  </v-btn>
                </v-layout>
              </v-parallax>
            </v-carousel-item>
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            >
            </v-carousel-item>
          </v-carousel>
        </v-responsive>
      </section>
    </v-main>
    <v-divider></v-divider>
    <v-section class="pricing section-bg">
      <div class="section-title">
        <h2>{{ $t("indexs.grille") }}</h2>
      </div>
      <v-container class="container">
        <div class="row">
          <div class="ps-10 pe-12 col-lg-4 col-md-6">
            <div class="box">
              <h3>Bronze</h3>
              <h4>
                <sup>XOF</sup>
                15000
                <span>/ Ans</span>
              </h4>
              <ul class="high">
                <li><v-icon>mdi-check-all</v-icon>4 {{ $t("indexs.user") }}</li>
                <li><v-icon>mdi-check-all</v-icon>1 {{ $t("indexs.boutique") }}</li>
                <li><v-icon>mdi-check-all</v-icon>1 {{ $t("indexs.box") }}</li>
                <v-btn
                  :href="path"
                  @input="getregister"
                  class="command"
                  color="primary"
                  >commander</v-btn
                >
              </ul>
            </div>
          </div>
          <div class="ps-10 pe-12 col-lg-4 col-md-6 mt-4 mt-md-0">
            <div class="box featured">
              <h3>Argent</h3>
              <h4>
                <sup style="color:white">XOF</sup>
                60000
                <span style="color:white">/ ans</span>
              </h4>
              <ul class="high">
                <li><v-icon>mdi-check-all</v-icon>10 {{ $t("indexs.user") }}</li>
                <li><v-icon>mdi-check-all</v-icon>2 {{ $t("indexs.boutique") }}</li>
                <li><v-icon>mdi-check-all</v-icon>4 {{ $t("indexs.box") }}</li>
                <v-btn
                  :href="path"
                  @input="getregister"
                  class="command"
                  color="primary"
                  >commander</v-btn
                >
              </ul>
            </div>
          </div>
          <div class="ps-10 pe-12 col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div class="box">
              <h3>{{ $t("indexs.grille1") }}</h3>
              <h4>
                {{ $t("indexs.devis") }}
              </h4>
              <ul class="high">
                <li>
                  <v-icon>mdi-check-all</v-icon> {{ $t("indexs.devis1") }}
                </li>
                <li>
                  <v-icon>mdi-check-all</v-icon> {{ $t("indexs.devis2") }}
                </li>

                <v-btn
                  :href="path"
                  @input="getregister"
                  class="command"
                  color="primary"
                  >commander</v-btn
                >
              </ul>
            </div>
          </div>
        </div>
      </v-container>
    </v-section>
    <v-divider></v-divider>
    <section class="mt-16" id="a-propos">
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm4 class="my-3 pb-4">
          <div class="text-xs-justify">
            <h1>
            {{ $t("indexs.h1") }}
            </h1>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent ">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">
                      {{ $t("indexs.h3") }}
                    </div>
                  </v-card-title>
                  <v-card-text class="text">
                    <br />
                    {{ $t("indexs.one") }}
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">
                      {{ $t("indexs.h4") }}
                    </div>
                  </v-card-title>
                  <v-card-text class="text">
                    <br />
                    {{ $t("indexs.two") }}
                    &nbsp;
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card class="elevation-0  ">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-xs-center">
                      {{ $t("indexs.h5") }}
                    </div>
                  </v-card-title>
                  <v-card-text class="text">
                    <br />
                    {{ $t("indexs.tree") }}
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>
    <v-divider></v-divider>
  </v-app>
</template>
<script>
import  i18n from "../i18n"
export default {
  name: "App",
  data: function() {
    return {
      parallax: require("../assets/slide1.png"),
      path: "",
      paths: "",
      menus: [
        { title: "Acceuil", href: "#accueil" },

        { title: "A-propos", href: "#a-propos" },
      ],
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      items: [
        {
          src: "../assets/parallax.png",
        },
        {
          src: require("../assets/slide2.png"),
        },
        {
          src: require("../assets/slide4.png"),
        },
        {
          src: require("../assets/slide3.png"),
        },
        {
          src: require("../assets/slide5.png"),
        },
      ],
    };
  },
  mounted() {
    this.getlogin();
    this.getregister();
  },

  methods: {
    getlogin() {
      if (
        window.location.pathname == "/fr/" ||
        window.location.pathname == "/fr"
      ) {
        this.paths = "/fr/login";
      } else if (
        window.location.pathname == "/en/" ||
        window.location.pathname == "/en"
      ) {
        this.paths = "/en/login";
      }
    },
    getregister() {
      if (
        window.location.pathname == "/fr/" ||
        window.location.pathname == "/fr"
      ) {
        this.path = "/fr/register";
      } else if (
        window.location.pathname == "/en/" ||
        window.location.pathname == "/en"
      ) {
        this.path = "/en/register";
      }
    },
    changedtofr:function()
    {
      i18n.locale="fr";
      let language = this.$route.params.lang;
      if(language === "en")
      {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(()=> {})
      }
    },
    changedtoen: function() {
      i18n.locale = "en";
      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
  },

  computed: {},
};
</script>
<style scoped>
.command {
  border-radius: 50px;
}
.high li {
  font-size: 20px;
  margin-bottom: 10px;
}
.container {
  max-width: 90rem;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #312783;
}
.section-bg {
  background: whitesmoke;
  overflow: hidden;
  padding: 60px 0;
  color: white;
}
.pricing .box {
  padding: 20px;
  background: white;
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.pricing .box h3 {
  font-weight: 400;
  padding: 15px;
  color: #2b2b2b;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.pricing .box h4 {
  font-size: 42px;
  font-weight: 500;
  color: #2b2b2b;
  margin-bottom: 20px;
}

.pricing .box h4 sup {
  font-size: 22px;
  top: -15px;
  color: #2b2b2b;
  left: -3px;
}

.pricing .box h4 span {
  font-size: 22px;
  color: #2b2b2b;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  color: #2b2b2b;
  list-style: none;
  font-size: 17px;
}

.pricing li {
  padding-bottom: 16px;
  color: #2b2b2b;
  text-align: center;
}

.pricing .box ul i {
  color: #e03a3c;
  font-size: 16px;
  padding-right: 4px;
}

.pricing .box ul .na {
  text-decoration: line-through;
  color: #2b2b2b;
}

.pricing .box .btn-wrap {
  padding: 15px;
  text-align: center;
}

.pricing .featured {
  background: #312783;
}
.pricing .featured h3 {
  color: white;
}
.pricing .featured h4 {
  color: white;
}
.pricing .featured {
  color: white;
}
.pricing .featured li {
  color: white;
}

.responsive {
  max-width: 100% !important;
  height: auto !important;
}
.carousel-title {
  color: #312783 !important;
}
.top {
  padding-top: 0px !important;
  padding-right: 0px !important;
}
.v-sheet {
  box-shadow: none !important;
}
.text {
  text-align: justify;
  text-shadow: none !important;
  font-weight: normal !important;
}
.center {
  text-align: center !important;
}
.v-main {
  padding: 0 0 0 !important;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 0 !important;
}
.primary {
  background-color: #312783 !important;
  color: white !important;
}
.second {
  background-color: #e74c3c !important;
  color: white !important;
}
.text {
  font-weight: 900;
  text-shadow: 3px 2px #000000;
}
.sub-text {
  font-weight: 900;
  text-shadow: 2px 2px #000000;
}
.logo {
  height: 60px;
}
</style>
