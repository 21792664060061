<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog v-model="dialog" scrollable persistent max-width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="dialog = true" color="primary" dark class="mb-2" v-on="on">
                      <v-icon left>mdi-plus-circle-outline</v-icon>{{$t('client.new')}}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 300px;">
                      <v-container grid-list-md>
                        <v-form ref="formClient">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.nom"
                                :rules="[v => !!v || $t('client.required1')]"
                                :label="$t('client.name1')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.prenom"
                                :rules="[v => !!v || $t('client.required2')]"
                                :label="$t('client.name2')"
                                required
                              ></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.telephone"
                                :label="$t('client.contact')"
                                :rules="[v => !!v || 'Le numéro de téléphone est obligatoire']"
                                type="number"
                                required
                              ></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.adresse"
                                :rules="[v => !!v || 'Renseignez l\'adresse du client']"
                                :label="$t('client.address')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{$t('close')}}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{$t('client.deleting')}}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="red darken-1" @click="delete_dialog=false" text>{{$t('no')}}</v-btn>
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                      >{{$t('yes')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
                
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchClient"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('client.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchClient"
        sort-by="nom"
        class="elevation-1"
        hide-default-footer
        disable-filtering
        disable-pagination
        disable-sort
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{$t('datatable-title.client.listeclient')}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.nom}}</td>
            <td>{{item.prenom}}</td>
            <td>{{item.telephone}}</td>
            <td>{{item.adresse }}</td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small @click="deleteItem(item, 0)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{$t('facture.nodata')}}</p>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-if="searchClient"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import { mask } from "vue-the-mask";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  directives: {
    mask
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchClient: "",
    client:{},
    current_pag:1,
    dialog: false,
    snackbar: false,
    loading: false,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    //patients: [],
    editedIndex: -1,
    editedItem: {
      boutique: JSON.parse(window.localStorage.getItem('boutiqueId')),
      nom: "",
      prenom: "",
      telephone: "",
      adresse: "",
    },
    defaultItem: {
      nom: "",
      prenom: "",
      telephone: "",
      adresse: ""
    }
  }),

  computed: {
    headers(){
      return [
      {
        text: this.$t('datatable-header.client.nom'),
        align: "left",
        sortable: true,
        value: "client.nom"
      },
      { text: this.$t('datatable-header.client.prenom'), value: "client.prenom" },
      { text: this.$t('datatable-header.client.contact'), value: "client.telephone" },
      { text: this.$t('datatable-header.client.addresse'), value: "client.adresse" },
      { text: "Actions", value: "action", sortable: false }
    ]
    },

    
     total_page() {
        
      if (this.client && this.client.last_page) {
        return this.client.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.client != null && this.client.data) {
        return this.client.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.client && this.client.last_page) {
        return this.client.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken:"login/gettoken",
      allClients: "client/getAllClients"
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t('client.save')
        : this.$t('client.update');
    },

    formAction() {
      return this.editedIndex === -1 
      ? this.$t('client.save1') 
      : this.$t('client.update1');
    },
    
    isLoading() {
      return this.loading;
    }
  },

  watch: {
   
    createDossierDialog(val) {
      val || this.close();
    }
  },

   mounted() {
    this.axiosList();
  },


  created() {
    this.loading = true;
    this.clients();
    this.$store
      .dispatch("client/FETCH_ALL_CLIENTS")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

   

  methods: {
    ...mapActions({
      clients: "client/FETCH_ALL_CLIENTS",
      addNewClient: "client/ADD_CLIENT",
      updateClient: "client/UPDATE_CLIENT",
      deleteClient: "client/DELETE_CLIENT"
    }),
  async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      await axios
        .get(
          APIENDPOINT +
            "/api/client/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.client = response.data;
            console.log(this.client)
            if (this.client.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      if (this.searchClient) {
        await axios
          .get(
            APIENDPOINT +
              "/api/client/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchClient,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              console.log(response.data)
              this.client = response.data;

              if (this.client.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
          console.log(error);
        });
      } else {
        this.axiosList();
      }
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedIndex = 0
      this.dialog = true;
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t('client.warning') +
          item.nom +
          " " +
          item.prenom +
          ". " +
          this.$t('client.warning1') +
          this.$t('client.warning2');
        this.delete_dialog = true;
        this.axiosList()
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteClient(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t('client.msg');
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
          })
          .catch(() => {
            this.loading = false;
            var msg =
              this.$t('client.msg1');
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
        this.axiosList()
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },



    async save() {

      if (this.editedIndex > -1) {
        this.loading = true;
        await this.updateClient(this.editedItem)
          .then(() => {
            this.loading = false;
            var msg =
              this.$t('client.success1');
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
          })
          .catch(() => {
            this.loading = false;
            var msg =
              this.$t('client.error1');
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
        this.axiosList()
        this.close();
      } else {
        if (this.$refs.formClient.validate()) {
          this.loading = true;
          // const Client = this.editedItem;
          let boutiqueId= JSON.parse(window.localStorage.getItem('boutiqueId'));
          let items ={};
          items= Object.assign(this.editedItem);
          items.nom= this.editedItem.nom
          items.boutique=boutiqueId
          items.prenom=this.editedItem.prenom
          items.telephone=this.editedItem.telephone
          items.adresse=this.editedItem.adresse
          await this.addNewClient(items)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              // console.log(this.ed)
              var msg = this.$t('client.success');
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg
              });
            })
            .catch(() => {
              this.loading = false;
          // console.log(Client);
              var msg =this.$t('client.error');
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg
              });
            });
        }
        this.axiosList()
        this.close();
      }
    }
  }
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>