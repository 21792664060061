<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <SuccessSnackBar />
        <ErrorSnackBar />

        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-6">
              <v-card-title>
                <img
                  class="logo"
                  src="../../../public/lg.png"
                  height="150"
                  alt="logo"
                />
              </v-card-title>

              <v-card-text>
                <div class="justify-center">
                  <v-col>
                    <v-alert shaped prominent type="error" v-model="alert">
                      {{ $t("registerform.error.existemail") }}
                    </v-alert>
                  </v-col>
                </div>
                <div class="justify-center">
                  <v-col>
                    <v-alert shaped prominent type="error" v-model="alert2">
                      {{ $t("registerform.error.tel") }}
                    </v-alert>
                  </v-col>
                </div>
                <v-form @submit.prevent="save" ref="formRegister">
                  <v-container>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.nom"
                        :label="$t('client.name1')"
                        @change="isValid"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.prenom"
                        :label="$t('client.name2')"
                        @change="isValid"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.email"
                        :label="$t('client.email')"
                        @input="Existemail"
                        @change="isValid"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-autocomplete
                        v-model="modelSelectedPays"
                        :items="allPays"
                        item-text="nom"
                        item-value="id"
                        :label="$t('client.pays')"
                        prepend-icon="mdi-database-search"
                        @change="isValid"
                        return-object
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.telephone"
                        :label="$t('client.contact')"
                        type="number"
                        :prefix="prefix"
                        @input="Existtel"
                        @change="isValid"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                      <v-text-field
                        v-model="editedItem.adresse"
                        :label="$t('client.address')"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-btn
                      :disabled="!valid"
                      class="mt-4"
                      block
                      color="primary"
                      type="submit"
                      value="register"
                      >{{ $t("registerform.register") }}</v-btn
                    >
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import axios from "axios";
import { APIENDPOINT } from "../../api/app.config";

export default {
  name: "Login",
  components: {
    ErrorSnackBar,
    SuccessSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    show1: false,
    alert: false,
    alert2: false,
    confirm: false,
    valid: false,
    modelSelectedPays: null,
    editedItem: {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      adresse: "",
    },
  }),
  computed: {
    ...mapGetters({
      allPays: "categorie/getAllPays",
    }),

    prefix() {
      return this.modelSelectedPays != null
        ? this.modelSelectedPays.indicatif
        : "";
    },
  },

  watch: {},
  created() {
    this.getAllPays();
  },
  methods: {
    ...mapActions({
      new: "client/ADD_CLIENT_AUTH2",
      getAllPays: "categorie/FETCH_ALl_PAYS",
    }),

    isValid() {
      if (
        this.editedItem.nom != "" &&
        this.editedItem.prenom != "" &&
        this.editedItem.email != "" &&
        this.editedItem.telephone != "" &&
        this.modelSelectedPays != null
      ) {
        if (!this.alert && !this.alert2) {
          this.valid = true;
          // return true;
        } else {
          this.valid = false;
          // return false;
        }
      } else {
        this.valid = false;
        // return false;
      }
    },

    match() {
      if (this.password == this.confirmpassword) {
        this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
          show: false,
          text: msg2,
        });
        return true;
      } else {
        var msg2 = "Les deux mots de passe doivent etre identique";
        this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
          show: true,
          text: msg2,
        });
        return false;
      }
    },
    async Existemail() {
      if (this.editedItem.email) {
        await axios
          .get(
            APIENDPOINT +
              "/api/auth/check/client/win/email/" +
              this.editedItem.email
          )
          .then((response) => {
            if (response.data == true) {
              this.alert = true;
              this.isValid();
            } else {
              this.alert = false;
              this.isValid();
            }
          })
          .catch((error) => {
            this.isValid();
            console.log(error);
          });
      }
    },

    async Existtel() {
      if (this.editedItem.telephone) {
        await axios
          .get(
            APIENDPOINT +
              "/api/auth/check/client/win/tel/" +
              this.editedItem.telephone
          )
          .then((response) => {
            if (response.data == true) {
              this.alert2 = true;
              this.isValid();
            } else {
              this.alert2 = false;
              this.isValid();
            }
          })
          .catch((error) => {
            this.isValid();
            console.log(error);
          });
      }
    },

    save() {
      if (this.$refs.formRegister.validate()) {
        this.editedItem.telephone = this.prefix + this.editedItem.telephone;
        this.new(this.editedItem)
          .then(() => {
            var msg2 = this.$t("client.success");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg2,
            });
            this.editedItem = {};
          })
          .catch((error) => {
            var msg2 = this.$t("client.error");
            this.$store.dispatch("snackbar/error", {
              show: true,
              text: msg2,
            });
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  color: black;
}
.dark h2 {
  color: black !important;
}
.theme--light .v-icon {
  color: black !important;
}
</style>
