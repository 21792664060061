<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="livraison' Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />

    <!-- Fin Components -->

    <v-divider></v-divider>


    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <!-- Début dialog de pay ement d'une facture -->
                <v-dialog
                  v-model="dialogView"
                  scrollable
                  persistent
                  max-width="700"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("livraison.make")
                      }}</span>
                    </v-card-title>

                    <v-card-text style="height: 400px;">
                      <v-container grid-list-md>
                        <v-form ref="formPayer">
                          <v-layout wrap>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.num") }}:
                                <h3 class="black--text">
                                  {{ showItem.numeroFacture }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span class="ml-2">
                                {{ $t("facture.customer3") }}:
                                <h2 class="black--text ml-2">
                                  {{
                                    showItem.commande.client.nom +
                                      " " +
                                      showItem.commande.client.prenom
                                  }}
                                </h2>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.type") }}:
                                <h3 class="red--text">
                                  {{ showItem.typeFacture.libelle }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.amount") }}:
                                <h2 class="red--text" id="total" name="total">
                                  {{
                                    showItem.montantFacture - showItem.remise
                                  }}
                                  FCFA
                                </h2>
                              </span>
                            </v-col>
                            <v-flex xs6 sm10 md10>
                              <v-autocomplete
                                v-model="modelSelectedChaufeur"
                                :items="matchedChauffeurs"
                                item-text="nom"
                                item-value="nom"
                                :label="$t('reception.name')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex>
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="indigo"
                                @click="newchauff = true"
                              >
                                <v-icon dark>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </v-flex>
                            <!-- <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.nomChaufeur"
                                :rules="[(v) => !!v || $t('bill.remitted')]"
                                type="text"
                                :label="$t('bill.remitted1')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.contactChauffeur"
                                :rules="[(v) => !!v || $t('bill.received')]"
                                type="number"
                                :label="$t('bill.paid')"
                                required
                              ></v-text-field>
                            </v-flex> -->
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                        :disabled="isValidPayement"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ $t("bill.remitted") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialogAnnuler"
                  scrollable
                  persistent
                  max-width="400"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("livraison.annuler")
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <h3>{{ $t("livraison.faire") }}</h3>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="closeAnnuler"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("no") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="annuler"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ $t("yes") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog de payement d'une facture -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <template v-if="isDirector">
                <v-text-field
                v-model="searchlivraisonDir"
                @input="axiosSearchDir"
                append-icon="mdi-magnify"
                :label="$t('livraison.search')"
                single-line
                hide-details
              ></v-text-field>
              </template>
              <template v-else>
                <v-text-field
                v-model="searchlivraison"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('livraison.search')"
                single-line
                hide-details
              ></v-text-field>
              </template>
              
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <template v-if="isDirector">
        <v-data-table
          :headers="headersAdmin"
          :items="itemListDir"
          :loading="loading"
          :search="searchlivraisonDir"
          sort-by="commande.client.nom"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{
                $t("datatable-title.livraison.listelivraison")
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>

                {{ item.idLivraison }}
              </td>
              <td>
                {{ item.facture.ligneBoutique.boutiquesPK.user.username }}
              </td>
              <td v-if="item.chauffeure != null">
                {{ item.chauffeure.nom }} </td>
              <td v-else>{{ item.nomChaufeur }}</td>
              <td>
                {{ item.facture.commande.client.nom }}
              </td>
              <td>
                {{
                  item.facture.livre
                    ? $t("livraison.deliver")
                    : $t("livraison.notdeliver")
                }}
              </td>
              <td>{{ convertDate(item.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="showFacture(item)" v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="livraisonAnnuler(item)" v-on="on"
                      >mdi-close-circle-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("livraison.annuler") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <v-pagination
          v-if="searchlivraisonDir"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosListDir"
          circle
        >
        </v-pagination>
      </template>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="itemList"
          :loading="loading"
          :search="searchlivraison"
          sort-by="commande.client.nom"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ $t("livraison.myDeliver") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.idLivraison }}
              </td>
              <td v-if="item.chauffeure != null">{{ item.chauffeure.nom }}</td>
              <td v-else>{{ item.nomChaufeur }}</td>
              <td v-if="item.chauffeure != null">
                {{ item.chauffeure.telephone }}
              </td>
              <td>{{ item.facture.commande.client.nom }}</td>
              <td>
                {{
                  item.facture.livre
                    ? $t("livraison.deliver")
                    : $t("livraison.notdeliver")
                }}
              </td>
              <td>{{ convertDate(item.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="showFacture(item)" v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <div class="mt-4">
          <v-pagination
            v-if="searchlivraison"
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosSearch"
            circle
          >
          </v-pagination>
          <v-pagination
            v-else
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosList"
            circle
          >
          </v-pagination>
        </div>
      </template>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
//import $ from "jquery";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
//import PageTitle from "../components/titles/PageTitle";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import i18n from "../../i18n";
import axios from "axios";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    //PageTitle,
  },
  data: () => ({
    mask: "########",
    searchlivraisonDir: "",
    searchlivraison: "",
    storage: window.localStorage,
    facture: null,
    dialog: false,
    dialogAnnuler: false,
    livraisons: {},
    current_pag: 1,
    dialogView: false,
    dialogOneFac: false,
    modelSelectedCaisse: null,
    modelSelectedChaufeur: null,
    newchauff: false,
    nomChaufeur: "",
    contactChauffeur: "",
    loading: false,
    search: null,
    inputRemise: false,
    articles: new Array(),
    ajoutArticle: new Array(),
    //ajouter: false,
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    quantite: "",
    quantiteArt: 0,
    reste: 0,
    oldRemise: 0,
    newRemise: 0,
    editedItem: {
      facture: 0,
      nomChaufeur: "",
      contactChauffeur: "",
    },
    defaultItem: {
      facture: 0,
      nomChaufeur: "",
      contactChauffeur: "",
    },
    idLiv: 0,
    showItem: {
      idFacture: 0,
      commande: {
        client: {
          idClient: 0,
          nom: "",
        },
      },
      file: "",
      montantFacture: 0,
      remise: 0,
      solde: false,
      typeFacture: {
        idTypeFacture: 0,
        libelle: "",
      },
      createdAt: "",
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("facture.code"),
          align: "left",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("livraison.name1"),
          align: "left",
          sortable: false,
          value: "nomChaufeur",
        },
        { text: this.$t("livraison.nomClient"), value: "contactChauffeur" },
        { text: this.$t("livraison.status"), value: "status" },
        { text: this.$t("facture.date"), value: "createdAt" },
        { text: "Actions", value: "action", sortable: true },
      ];
    },
    headersAdmin() {
      return [
        {
          text: this.$t("livraison.id"),
          align: "left",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("login.username"),
          value: "ligneBoutique.boutiquesPK.user.nom",
          sortable: true,
        },
        {
          text: this.$t("datatable-header.livraison.nom"),
          align: "left",
          sortable: false,
          value: "nomChaufeur",
        },
        {
          text: this.$t("livraison.nomClient"),
          value: "facture.commande.client",
        },
        { text: this.$t("datatable-header.livraison.status"), value: "status" },
        { text: this.$t("datatable-header.facture.date"), value: "createdAt" },
        { text: "Actions", value: "action", sortable: true },
      ];
    },

    total_page() {
      if (this.livraisons && this.livraisons.last_page) {
        return this.livraisons.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.livraisons != null && this.livraisons.data) {
        return this.livraisons.data;
      } else {
        return [];
      }
    },
    matchedChauffeurs() {
      if (this.allChauffeur != null) {
        return this.allChauffeur;
      } else {
        return [];
      }
    },
    itemListDir() {
      if (this.livraisons != null && this.livraisons.data) {
        return this.livraisons.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.livraisons && this.livraisons.last_page) {
        return this.livraisons.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      allLivraison: "facture/getAllLivraison",
      allChauffeur: "chauffeur/getAllChauffeur",
      _role: "login/getUrole",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("bill.customer")
        : this.$t("bill.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("facture.bill3")
        : this.$t("facture.update1");
    },

    itemC() {
      return this.allCaisses.map((caisse) => {
        const libelles = caisse.libelle;
        return Object.assign({}, caisse, { libelles });
      });
    },
    isDirector() {
      const isObjectPresent = this._role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
    isValidPayement() {
      if (
        this.editedItem.nomChaufeur === "" 
      )
        return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created()
  {
    this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");
  },
  mounted() {
    if (this.isDirector) {

      this.axiosListDir();
    } else {
      this.axiosList();
    }
  },

  methods: {
    ...mapActions({
      livraison: "facture/FETCH_ALL_LIVRAISON",
      chauffeurs: "chauffeur/FETCH_ALL_CHAUFFEUR",
      addnewchauffeur: "chauffeur/ADD_CHAUFFEUR",
      annulerLiv: "facture/ANNULER_LIV",
      
    }),
    async axiosListDir() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      // console.log(boutiqueId.idBoutique);
      // console.log(
      //   APIENDPOINT +
      //     "/api/livraison/directeur/page/" +
      //     boutiqueId.idBoutique +
      //     "/" +
      //     this.current_pag
      // );
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/livraison/directeur/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.livraisons = response.data;
            // console.log(this.livraisons);
            if (this.livraisons.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearchDir() {
      
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      
      if (this.searchlivraisonDir) {
        await axios
          .get(
            APIENDPOINT +
              "/api/livraison/directeur/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchlivraisonDir,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              console.log(response.data);
              this.livraisons = response.data;
              if (this.livraisons.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosListDir();
      }
    },
    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/livraison/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.livraisons = response.data;
            if (this.livraisons.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      console.log(this.searchlivraison);

      if (this.searchlivraison) {
        await axios
          .get(
            APIENDPOINT +
              "/api/livraison/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchlivraison,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.livraisons = response.data;

              if (this.livraisons.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD hh:mm:ss");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY à HH:mm:ss");
      }
      if (date != null) return convert_date;
      else return null;
    },

    calculReste() {
      const rest =
        parseInt(this.editedItem.montant) -
        parseInt(this.editedItem.montantPaye);
      this.reste = rest;
    },

    async showFacture(item) {
      const facture = APIENDPOINT + "/api/downloadFile/" + item.facture.file;
      await instance
        .get(facture, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    payementFacture(item) {
      // console.log(item);
      this.editedIndex = this.allfacture.indexOf(item);
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },
    
    livraisonAnnuler(item) {
      // console.log(item);
      this.idLiv = item.idLivraison;
      this.dialogAnnuler = true;
    },

    closePayement() {
      this.dialogView = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async downloadFacture(item) {
      await instance
        .get(APIENDPOINT + "/e2v/api/downloadFile/" + item.codeFac, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    getTotalOneFacture(facture) {
      let somme = 0;
      facture.analyseList.forEach((element) => {
        somme += !element.netPayBeneficiaire
          ? element.price
          : element.netPayBeneficiaire;
      });
      return somme - facture.remise;
    },

    close() {
      this.dialogView = false;
      setTimeout(() => {
        this.editedItem.montantPaye = "";
        this.modelSelectedCaisse = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    closeAnnuler() {
      this.dialogAnnuler = false;
      this.idLiv = 0;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.$refs.formPayer.validate()) {
        this.loading = true;
        let items = {};

        items.facture = this.showItem.idFacture;
        items.nomChaufeur = parseInt(this.editedItem.nomChaufeur);
        items.contactChauffeur = parseInt(this.editedItem.contactChauffeur);

        await this.livrerFacture(items)
          .then(() => {
            this.loading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            var msg = this.$t("bill.msg");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            this.$store
              .dispatch("facture/FETCH_ALL_FACTURE_NON_LIVRE")
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
            this.axiosList();
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("bill.msg1");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
      }
      this.axiosList();
      this.close();
    },
    async annuler() {
      this.loading = true;
        let items = {};

        items.idLivraison = this.idLiv;

        await this.annulerLiv(items)
          .then(() => {
            this.loading = false;
            var msg = this.$t("bill.msg");
            this.axiosListDir();
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("bill.msg1");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
      this.closeAnnuler();
    },
  },
};
</script>
<style scoped>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
