<template dark>
  <div>
    <v-container class="cadre" fluid>
      <SuccessSnackBar />
      <ErrorSnackBar />
      <v-toolbar-title>
        <v-dialog v-model="dialog" scrollable persistent max-width="700">
          <v-card>
            
          </v-card>
        </v-dialog>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-layout align-center justify-center color="primary">
        <v-flex login-form text-xs-center>
          <v-card>
            <v-card-text class="subheading">
              <template>
                <img style="width: 20rem ; padding-left: 10rem ;" src="../../../public/logo.png"/>
                <div class="d-flex" style="justify-content: space-between;">
                  <div>
                    {{ $t("login.forgetPass") }}
                  </div>
                  <div class="nav-item">
                    <v-menu open-on-hover offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-1" text v-bind="attrs" v-on="on">
                          <v-icon color="grey">
                            mdi-earth
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-title>
                          <v-btn text width="100%" @click="changedtofr">
                            Français
                          </v-btn>
                        </v-list-item-title>

                        <v-list-item-title>
                          <v-btn text width="100%" @click="changedtoen">
                            English
                          </v-btn>
                        </v-list-item-title>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </template>
              <v-form @keyup.native.enter="forgetPass" ref="loginForm">
                <v-text-field
                  v-model="logins.email"
                  prepend-icon="mdi-account"
                  label="email"
                  type="email"
                >
                </v-text-field>
                <v-container>
                  <v-btn
                    @click.prevent="forgetPass"
                    block
                    class="mt-4"
                    color="primary"
                    type="submit"
                  >
                    <span
                      v-if="request_status === '' || request_status === 'error'"
                    >
                      {{ $t("login.envoyer") }}
                      <v-icon>mdi-login</v-icon>
                    </span>
                    <span v-if="request_status === 'loading'"
                      >{{ $t("login.wait") }}...</span
                    >
                  </v-btn>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>

import i18n from "../../i18n";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";

export default {
  name: "Login",
  request: "Connexion",
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  data() {
    return {

      href:"register",
      show1: false,
      image_src: require("../../assets/logo.png"),
      modelSelectedBoutique: null,
      dialog: false,
      logins: {
        email: "",
        langue: "",
      },
      defaultLogin: {
        email: "",
        langue: "",
      },
    };
  },
  methods: {
    getlogin() {
      if (
        window.location.pathname == "/fr/" ||
        window.location.pathname == "/fr"
      ) {
        this.paths = "/fr/login";
      } else if (
        window.location.pathname == "/en/" ||
        window.location.pathname == "/en"
      ) {
        this.paths = "/en/login";
      }
    },

    forgetPass: async function() {
      if (this.logins.email!="") {
        this.logins.langue = i18n.locale;

        await this.$store
          .dispatch("login/FORGET_PASS", this.logins)
          .then(() => {
            this.$router
              .push(`/${i18n.locale}/login`)
              .catch(() => {});
            var msg = this.$t("login.msgForget");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.logins = Object.assign({}, this.defaultLogin);
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: this.$t("login.msgForget1"),
            });
          });
      }
    },

    resetLogForm() {(this.logins.password = "");
    },

    save() {
      //this.loading = true;
      var app = this;
      app.$router.push(`/${i18n.locale}/app/accueil`).catch(() => {});
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.modelSelectedBoutique = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    changedtofr: function() {
      i18n.locale = "fr";

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },

    changedtoen: function() {
      i18n.locale = "en";
      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
  },
  computed: {
    request_status() {
      return this.$store.state.login.status;
    },
    isDisabled() {
      if (this.request_status === "loading") return true;
      else return false;
    },

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created: function() {
    
  },
};
</script>
<style scoped>
body {
  background-color: black;
}
.login-form {
  max-width: 500px;
}
.cadre {
  margin-top: 200px;
}
</style>
