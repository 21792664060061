<template v-if="loaded">
  <Dashboard :full_name="fullName" />
</template>
<script>
import Dashboard from "../components/Dashboard";
export default {
  components: {
    Dashboard,
  },
  data: () => ({
    storage: window.localStorage,
    loaded: false,
  }),
  mounted() {
    this.storage = window.localStorage;
    this.loaded = true;
  },
  computed: {
    fullName() {
      const connected_user = JSON.parse(this.storage.user);
      return connected_user.nom + " " + connected_user.prenom;
    },
  },
};
</script>
