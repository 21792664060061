<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <v-row>
            <v-dialog v-model="dialog" scrollable persistent max-width="700">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="dialog = true"
                  color="primary"
                  dark
                  class="me-4 ms-4 col-10 col-md-2 mt-3"
                  v-on="on"
                >
                  <v-icon left>mdi-plus-circle-outline</v-icon>{{ $t("item.new") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline blue justify-center">
                  <span class="headline white--text">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text style="height: 510px">
                  <v-container grid-list-md>
                    <v-form ref="formCaisse">
                      <v-layout wrap>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            v-model="editedItem.article.designation"
                            :rules="[(v) => !!v || $t('item.err1')]"
                            :label="$t('item.name')"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            v-model="editedItem.article.prix"
                            :rules="[(v) => !!v || $t('item.err2')]"
                            type="number"
                            :label="$t('item.prix')"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            v-model="editedItem.article.prixAchat"
                            :rules="[(v) => !!v || $t('item.err2')]"
                            type="number"
                            :label="$t('item.prixAchat')"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            v-model="editedItem.stockMin"
                            :rules="[(v) => !!v || $t('item.err3')]"
                            type="number"
                            :label="$t('item.stockMin')"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                          <v-text-field
                            v-model="editedItem.quantiteEntree"
                            :rules="[(v) => !!v || $t('item.err4')]"
                            type="number"
                            :label="$t('item.quantite')"
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                          <v-autocomplete
                            v-model="modelSelectedCategorie"
                            :items="itemC"
                            :search-input.sync="searchC"
                            item-text="libelle"
                            item-value="idCategorie"
                            :label="$t('item.cat')"
                            :placeholder="$t('item.choose1')"
                            prepend-icon="mdi-database-search"
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-autocomplete
                            v-model="modelSelectedFournisseur"
                            :items="itemF"
                            :search-input.sync="searchF"
                            item-text="raisonSociale"
                            item-value="idFournisseur"
                            :label="$t('item.furnisher')"
                            :placeholder="$t('item.choose2')"
                            prepend-icon="mdi-database-search"
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-autocomplete
                            v-model="modelSelectedDepot"
                            :items="itemD"
                            :search-input.sync="searchD"
                            item-text="libelle"
                            item-value="idDepot"
                            :label="$t('item.depot')"
                            :placeholder="$t('item.choose2')"
                            prepend-icon="mdi-database-search"
                            return-object
                          ></v-autocomplete>
                        </v-flex>
                        <v-flex xs12 sm12 md12>
                          <v-text-field
                            v-model="editedItem.article.description"
                            :label="$t('item.desc')"
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                    <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    :disabled="isLoading"
                    :loading="isLoading"
                    tile
                    outlined
                    color="blue accent-4"
                    @click="save"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    {{ formAction }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- add image for articles -->
            <v-dialog v-model="dialogImage" scrollable persistent max-width="600">
              <v-card>
                <v-card-title class="headline blue justify-center">
                  <span class="headline white--text">{{ $t("item.image") }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-img
                      cover
                      :src="imageUrl"
                    ></v-img>
                    <v-col cols="12">
                      <v-file-input
                        v-model="image"
                        accept="image/png, image/jpg, image/jpeg, image/bmp,image/webp"
                        :label="$t('item.select')"
                        prepend-icon="mdi-paperclip"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip small label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-container>
        
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ma-2"
                    tile
                    color="red accent-4"
                    @click="closeImage"
                    dark
                  >
                    <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    tile
                    color="blue accent-4"
                    @click="imageItem"
                    dark
                  >
                    <v-icon left>mdi-close-circle-outline</v-icon>{{ formAction }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Debut dialog d'affichage des details de l'article -->
            <v-dialog v-model="dialogDetaile" scrollable persistent max-width="600">
              <v-card>
                <v-card-title class="headline blue justify-center">
                  <span class="headline white--text">{{ $t("item.detail") }}</span>
                </v-card-title>
                <v-card-title>
                  <div class="text-h4 mb-2">
                    {{ showItem.article.designation }}
                  </div>
                </v-card-title>
                <v-card-title>
                  <div class="text-h6 font-weight-regular grey--text">
                    {{ showItem.article.description }}
                  </div>
                </v-card-title>
                <v-card-title>
                  <div class="text-h6 font-weight-regular grey--text">
                    Prix achat:
                    <v-chip class="mr-2" @click="lights">
                      <v-icon left> mdi-cash-100 </v-icon>
                      {{ showItem.article.prixAchat }} FCFA
                    </v-chip>
                    Prix vente:
                    <v-chip class="mr-2" @click="lights">
                      <v-icon left> mdi-cash-100 </v-icon>
                      {{ showItem.article.prix }} FCFA
                    </v-chip>
                  </div>
                </v-card-title>

                <v-divider class="mt-6 mx-4"></v-divider>

                <v-card-text>
                  <v-chip class="mr-2 mt-2" @click="lights">
                    <v-icon left> mdi-alarm-plus </v-icon>
                    {{ $t("item.create") + convertDate(showItem.article.createdAt) }}
                  </v-chip>
                  <v-chip class="mr-2 mt-2" @click="alarm">
                    <v-icon left> mdi-alarm-check </v-icon>
                    {{ $t("item.updated") + convertDate(showItem.article.updatedAt) }}
                  </v-chip>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ma-2"
                    tile
                    color="red accent-4"
                    @click="closeDetails"
                    dark
                  >
                    <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--Fin dialog d'affichage des details de l'article -->

            <!-- Début Dialog de suppression   -->
            <v-dialog v-model="delete_dialog" persistent max-width="500">
              <v-card>
                <v-card-title class="headline red darken-1 white--text">{{
                  $t("deleting")
                }}</v-card-title>
                <v-card-text class="black--text mt-2">
                  <p>{{ deleteMessage }}</p>
                </v-card-text>
                <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn color="red darken-1" @click="delete_dialog = false" text>{{
                    $t("no")
                  }}</v-btn>
                  <v-btn color="blue darken-1" @click="deleteItem(delete_item, 1)" text>{{
                    $t("yes")
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Dialog de suppression   -->

            <v-spacer></v-spacer>
            <v-text-field
              class="me-4 ms-4 col-10 col-md-2"
              v-model="searchArticle"
              append-icon="mdi-magnify"
              :label="$t('item.search')"
              @input="axiosSearch"
              single-line
              hide-details
            >
            </v-text-field>
            <!-- <v-app-bar color="grey lighten-4" class="col-md-4 col-sm-5 elevation-20 ">
          </v-app-bar> -->
          </v-row>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-layout v-resize="onResize" column style="padding-top: 56px">
      <v-container
        id="scroll-target"
        style="max-height: 100%; max-width: 100%"
        class="overflow-y-auto"
      >
        <v-data-table
          :headers="headers"
          :items="itemList"
          :loading="loading"
          :search="searchArticle"
          sort-by="nom"
          class="elevation-1"
          :hide-default-header="isMobile"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{
                $t("datatable-title.article.listarticles")
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr v-if="!isMobile">
              <td>
                <template v-if="item.stockMin == item.quantiteEntree">
                  <v-chip color="red" text-color="white">
                    {{ item.article.designation }}
                  </v-chip>
                </template>
                <template v-else>
                  {{ item.article.designation }}
                </template>
              </td>
              <td>{{ item.article.prix }}</td>
              <!--<td>{{ item.article.prixAchat }}</td>-->
              <td>{{ item.article.categorie.libelle }}</td>
              <td>{{ item.article.fournisseur.raisonSociale }}</td>
              <td>{{ item.depot.libelle }}</td>
              <td>{{ item.quantiteEntree }}</td>
              <td>{{ item.quantiteSortie }}</td>
              <td>
                <v-icon
                  v-if="isDirector || isDirectorGerent"
                  small
                  class="mr-2"
                  @click="showDetail(item)"
                  >mdi-eye</v-icon
                >
                <v-tooltip bottom>
                  <template
                    v-if="isDirector || isDirectorGerent"
                    v-slot:activator="{ on }"
                  >
                    <v-icon small class="mr-2" @click="editItem(item)" v-on="on"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <template v-else v-slot:activator="{ on }">
                    <v-icon small class="mr-2" @click="editItemGerent(item)" v-on="on"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>{{ $t("item.update3") }}</span>
                </v-tooltip>
                <v-icon
                  v-if="isDirector || isDirectorGerent"
                  small
                  class="mr-2"
                  @click="showAddImage(item, 0)"
                  >mdi-image-edit</v-icon
                >
                <v-icon
                  v-if="isDirector || isDirectorGerent"
                  small
                  class="mr-2"
                  @click="deleteItem(item, 0)"
                  >mdi-delete</v-icon
                >
              </td>
            </tr>
            <tr v-else>
              <td>
                <ul class="flex-content">
                  <li class="flex-item">
                    <template v-if="item.stockMin == item.quantiteEntree">
                      <v-chip color="red" text-color="white">
                        {{ $t("item.name") }} : {{ item.article.designation }}
                      </v-chip>
                    </template>
                    <template v-else>
                      {{ $t("item.name") }} : {{ item.article.designation }}
                    </template>
                  </li>
                  <li class="flex-item">
                    {{ $t("datatable-header.article.prix") }}: {{ item.article.prix }}
                  </li>
                  <li class="flex-item">
                    {{ $t("datatable-header.article.categorie") }}:
                    {{ item.article.categorie.libelle }}
                  </li>
                  <li class="flex-item">
                    {{ $t("datatable-header.article.fournisseur") }}:
                    {{ item.article.fournisseur.raisonSociale }}
                  </li>
                  <li class="flex-item">
                    {{ $t("datatable-header.article.depot") }}: {{ item.depot.libelle }}
                  </li>
                  <li class="flex-item">
                    {{ $t("datatable-header.article.quantiteEntree") }}:
                    {{ item.quantiteEntree }}
                  </li>
                  <li class="flex-item">
                    {{ $t("datatable-header.article.quantiteSortie") }}:
                    {{ item.quantiteSortie }}
                  </li>
                  <li class="flex-item">&nbsp;</li>
                  <li class="flex-item">
                    <v-icon
                      v-if="isDirector || isDirectorGerent"
                      small
                      class="mr-2"
                      @click="showDetail(item)"
                      >mdi-eye</v-icon
                    >
                    <v-tooltip bottom>
                      <template
                        v-if="isDirector || isDirectorGerent"
                        v-slot:activator="{ on }"
                      >
                        <v-icon small class="mr-2" @click="editItem(item)" v-on="on"
                          >mdi-pencil</v-icon
                        >
                      </template>
                      <template v-else v-slot:activator="{ on }">
                        <v-icon small class="mr-2" @click="editItemGerent(item)" v-on="on"
                          >mdi-pencil</v-icon
                        >
                      </template>
                      <span>{{ $t("item.update3") }}</span>
                    </v-tooltip>
                    <v-icon
                  v-if="isDirector || isDirectorGerent"
                  small
                  class="mr-2"
                  @click="showAddImage(item, 0)"
                  >mdi-image-edit</v-icon
                >
                    <v-icon
                      v-if="isDirector || isDirectorGerent"
                      small
                      class="mr-2"
                      @click="deleteItem(item, 0)"
                      >mdi-delete</v-icon
                    >
                  </li>
                </ul>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-if="searchArticle"
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosSearch"
            circle
          >
          </v-pagination>
          <v-pagination
            v-else
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosList"
            circle
          >
          </v-pagination>
        </div>
      </v-container>
    </v-layout>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import moment from "moment";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import { mask } from "vue-the-mask";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    alarm: "",
    lights: "",
    isMobile: false,
    storage: window.localStorage,
    searchArticle: "",
    article: {},
    current_pag: 1,
    searchC: "",
    searchF: "",
    searchD: "",
    dialog: false,
    dialogView: false,
    dialogDetaile: false,
    dialogGerent: false,
    dialogImage: false,
    imageUrl: "",
    snackbar: false,
    loading: false,
    image: null,
    search: null,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    modelSelectedCategorie: null,
    modelSelectedFournisseur: null,
    modelSelectedDepot: null,
    delete_item: null,
    valid: true,
    select: null,
    editedIndex: -1,
    showItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },
    editedItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },

    defaultItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("item.name"),
          align: "left",
          sortable: true,
          value: "article.designation",
        },
        { text: this.$t("datatable-header.article.prix"), value: "prix" },
        // { text: this.$t("item.prixAchat"), value: "prixAchat" },
        {
          text: this.$t("datatable-header.article.categorie"),
          value: "article.categorie.libelle",
        },
        {
          text: this.$t("datatable-header.article.fournisseur"),
          value: "article.fournisseur.raisonSociale",
        },
        {
          text: this.$t("datatable-header.article.depot"),
          value: "depot.libelle",
        },
        {
          text: this.$t("datatable-header.article.quantiteEntree"),
          value: "quantiteEntree",
        },
        {
          text: this.$t("datatable-header.article.quantiteSortie"),
          value: "quantiteSortie",
        },
        { text: "Actions", value: "action", sortable: false },
      ];
    },

    total_page() {
      if (this.article && this.article.last_page) {
        return this.article.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.article != null && this.article.data) {
        return this.article.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.article && this.article.last_page) {
        return this.article.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      allArticles: "articles/getAllArticles",
      allCategories: "categorie/getAllCategories",
      allPaginates: "articles/getAllPaginates",
      allFournisseurs: "fournisseur/getAllFournisseurs",
      allDepots: "depot/getAllDepotsBoutique",
      _role: "login/getUrole",
    }),

    formTitle() {
      return this.editedIndex === -1 ? this.$t("item.save") : this.$t("item.update");
    },

    formAction() {
      return this.editedIndex === -1 ? this.$t("item.save1") : this.$t("item.update1");
    },

    itemC() {
      return this.allCategories.map((categorie) => {
        const libelles = categorie.libelle;
        return Object.assign({}, categorie, { libelles });
      });
    },

    itemF() {
      return this.allFournisseurs.map((fournisseur) => {
        const raison = fournisseur.raisonSociale;
        return Object.assign({}, fournisseur, { raison });
      });
    },

    itemD() {
      return this.allDepots.map((depot) => {
        const libelles = depot.libelle;
        return Object.assign({}, depot, { libelles });
      });
    },

    matchedDepot() {
      //if (!this.modelSelectedArticles) return [];
      return this.allDepots;
    },

    isValidStock() {
      if (this.editedItem.quantite === "") return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },

    isDirector() {
      const isObjectPresent = this._role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
    isGerent() {
      const isObjectPresent = this._role.find((o) => o.idRole === 3);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
    isDirectorGerent() {
      if (this.isDirector && this.isGerent) return true;
      else return false;
    },
  },

  watch: {
    createDossierDialog(val) {
      val || this.close();
    },

    searchC() {
      // Items have already been loaded
      if (this.itemC.length > 0) return;
      this.allCategories;
    },

    searchF() {
      // Items have already been loaded
      if (this.itemF.length > 0) return;
      this.allFournisseurs;
    },
  },

  created() {
    this.loading = true;
    this.categories();
    this.fournisseurs();
    this.depots();
    // this.$store.dispatch("articles/FETCH_ALL_ARTICLES_PAGINATE");
    this.$store.dispatch("depot/FETCH_ALL_DEPOTS");
    this.$store
      .dispatch("articles/FETCH_ALL_ARTICLES_STOCK")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  mounted() {
    this.axiosList();
  },

  methods: {
    ...mapActions({
      depots: "depot/FETCH_ALL_DEPOTS_BOUTIQUE",
      categories: "categorie/FETCH_ALL_CATEGORIES",
      fournisseurs: "fournisseur/FETCH_ALL_FOURNISSEURS",
      articles: "articles/FETCH_ALL_ARTICLES_STOCK",
      // articlesPaginate:"articles/FETCH_ALL_ARTICLES_PAGINATE",
      addNewArticle: "articles/ADD_ARTICLE",
      addNewStockArticle: "articles/ADD_ARTICLE_STOCK",
      addNewStockArticle1: "articles/ADD_ARTICLE_STOCK1",
      updateArticle: "articles/UPDATE_ARTICLE",
      imageArticle: "articles/ADD_IMAGE",
      deleteArticle: "articles/DELETE_ARTICLE",
    }),
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/entree/boutique/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.article = response.data;
            //this.article = response.data
            for (let i = 0; i < this.article.data.length; i++) {
              const element = this.article.data[i];
              if (element.stockMin == 0 && element.quantiteEntree == 0) {
                var msg = "Vous avez des Articles en limite de stock";
                this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                  show: true,
                  text: msg,
                });
              }
            }
            if (this.article.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchArticle) {
        await axios
          .get(
            APIENDPOINT +
              "/api/search/entree/boutique/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchArticle,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;

              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    editItem(item) {
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.modelSelectedDepot = this.editedItem.depot;
      this.modelSelectedCategorie = this.editedItem.article.categorie;
      this.modelSelectedFournisseur = this.editedItem.article.fournisseur;
      this.dialog = true;
      //console.log(this.editedIndex)
    },

    editItemGerent(item) {
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.modelSelectedDepot = this.editedItem.depot;
      this.modelSelectedCategorie = this.editedItem.article.categorie;
      this.modelSelectedFournisseur = this.editedItem.article.fournisseur;
      this.dialog = true;
    },

    showDetail(item) {
      this.editedIndex = 0;
      this.showItem = Object.assign({}, item);
      this.dialogDetaile = true;
    },

    showAddImage(item) {
      console.log(item);
      // this.imageUrl = 'https://shopymanager.com:8443/s2a-stock/api/downloadFile/'+item.article.image;
      
      this.imageUrl = 'http://127.0.0.1:8789/api/downloadFile/'+item.article.image;
      console.log(this.imageUrl);
      this.editedIndex = 0;
      this.showItem = Object.assign({}, item);
      this.dialogImage = true;
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t("item.msg1") + this.$t("item.msg2") + this.$t("item.msg3");
        this.delete_dialog = true;
        this.axiosList();
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteArticle(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t("item.warning1");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("item.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.delete_item = null;
        this.delete_dialog = false;
        this.axiosList();
      }
    },

    async imageItem() {
      let itemToSave = {
          id: this.showItem.article.idArticle,
          images: this.image,
        };
      await this.imageArticle(itemToSave)
          .then(() => {
            this.loading = false;
            var msg = this.$t("item.warning1");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            this.closeImage();
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("item.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
            this.closeImage();
          });
        
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.modelSelectedCategorie = null;
        this.modelSelectedFournisseur = null;
        this.modelSelectedDepot = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    closeDetails() {
      this.dialogDetaile = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.modelSelectedCategorie = null;
        this.modelSelectedFournisseur = null;
        this.modelSelectedDepot = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    closeImage(){
      this.dialogImage = false;
      setTimeout(() => {
        this.image = null,
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    closeStock() {
      this.dialogView = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    updateStock(item) {
      this.editedIndex = 0;
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },

    async save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));

        let items = {};
        items = Object.assign(this.editedItem);
        items.categorie = this.modelSelectedCategorie.idCategorie;
        items.fournisseur = this.modelSelectedFournisseur.idFournisseur;
        items.depot = this.modelSelectedDepot.idDepot;
        items.boutique = boutiqueId;
        items.designation = this.editedItem.article.designation;
        items.prix = this.editedItem.article.prix;
        items.prixAchat = this.editedItem.article.prixAchat;
        items.description = this.editedItem.article.description;
        items.stockMin = this.editedItem.stockMin;
        items.quantite = this.editedItem.quantiteEntree;

        await this.updateArticle(items)
          .then(() => {
            this.loading = false;
            var msg = this.$t("item.success2");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("item.error2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.axiosList();
        this.close();
      } else {
        if (this.$refs.formCaisse.validate()) {
          this.loading = true;
          let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
          this.loading = true;
          let items = {};
          //const Article = this.editedItem;
          items = Object.assign(this.editedItem);
          items.categorie = this.modelSelectedCategorie.idCategorie;
          items.fournisseur = this.modelSelectedFournisseur.idFournisseur;
          items.depot = this.modelSelectedDepot.idDepot;
          items.boutique = boutiqueId;
          items.designation = this.editedItem.article.designation;
          items.prix = this.editedItem.article.prix;
          items.prixAchat = this.editedItem.article.prixAchat;
          items.description = this.editedItem.article.description;
          items.stockMin = this.editedItem.stockMin;
          items.quantite = this.editedItem.quantiteEntree;
          await this.addNewArticle(items)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              var msg = this.$t("item.success1");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
            })
            .catch(() => {
              this.loading = false;
              var msg = this.$t("item.error1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
            });
        }
        this.axiosList();
        this.close();
      }
    },
  },
};
</script>
<style scoped>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
.mobile {
  color: #333;
}

.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  /* display: flex;
  flex-wrap: wrap;
  width: 100%; */
}

.flex-item {
  padding: 5px;
  width: 50%;
  font-weight: bold;
}
</style>
