<template>
  <div>
    <v-treeview>
      <template v-if="isAccueil">
        <template v-for="menuItem in menuItemsAccueil">
          <!-- Début menu simple -->
          <v-btn
            v-if="!menuItem.sub_menu"
            :key="menuItem.a"
            :to="menuItem.to"
            text
          >
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <v-menu v-if="menuItem.sub_menu" :key="menuItem.a" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="item in menuItem.sub_menu" :key="item.b">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Fin sous menu dropdown -->
        </template>
      </template>
    </v-treeview>
    <v-treeview hoverable class="mt-4" v-if="ismobile == true">
      <!-- Début menu Secrétaire -->
      <!-- <template v-if="isVendeurCaisseDirecteur">
        <template v-for="(menuItem, i) in menuItemsVendeur"> -->
          <!-- Début menu simple -->
          <!-- <v-btn v-if="!menuItem.sub_menu" :key="i" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn> -->
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <!-- <v-menu class="mt-4" v-if="menuItem.sub_menu" :key="i" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu> -->
          <!-- Fin sous menu dropdown -->
        <!-- </template>
        <template v-for="(menuItem, i) in menuItemsCaissier" class="mt-4"> -->
          <!-- Début menu simple -->
          <!-- <v-btn v-if="!menuItem.sub_menu" :key="i" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn> -->
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <!-- <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu> -->
          <!-- Fin sous menu dropdown -->
        <!-- </template>
      </template> -->

      <!-- <template v-else-if="isVendeurCaisse">
        <template v-for="(menuItem, i) in menuItemsVendeur"> -->
          <!-- Début menu simple -->
          <!-- <v-btn v-if="!menuItem.sub_menu" :key="i" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn> -->
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <!-- <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu> -->
          <!-- Fin sous menu dropdown -->
        <!-- </template>
      </template> -->

      <template v-if="isVendeur">
        <template v-for="(menuItem, i) in menuItemsVendeur">
          <!-- Début menu simple -->
          <v-btn v-if="!menuItem.sub_menu" :key="i" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Fin sous menu dropdown -->
        </template>
      </template>
      <!-- Fin menu Secrétaire -->

      <!-- Début menu Secrétaire -->
      <template v-if="isCaissier">
        <template v-for="(menuItem, i) in menuItemsCaissier">
          <!-- Début menu simple -->
          <v-btn v-if="!menuItem.sub_menu" :key="i" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Fin sous menu dropdown -->
        </template>
      </template>
      <!-- Fin menu Secrétaire -->

      <!-- Début menu Directeur -->

      <template v-if="isAdmin">
        <template v-for="(menuItem, k) in menuItemsAdmin">
          <v-btn
            v-if="!menuItem.sub_menu"
            :key="k + menuItem.to"
            :to="menuItem.to"
            text
          >
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>

          <v-menu v-if="menuItem.sub_menu" :key="k" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </template>
      <template v-if="isGerant">
        <template v-for="(menuItem, l) in menuItemsGerant">
          <v-btn v-if="!menuItem.sub_menu" :key="l" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>

          <v-menu v-if="menuItem.sub_menu" :key="l" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </template>
      <!-- Fin menu Gérant -->
    </v-treeview>
    <v-treeview hoverable class="mt-4">
      <!-- Début menu Magasinier -->
      <template v-if="isMagasinier">
        <template v-for="(menuItem, i) in menuItemsMagasinier">
          <!-- Début menu simple -->
          <v-btn v-if="!menuItem.sub_menu" :key="i" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Fin sous menu dropdown -->
        </template>
      </template>
      <!-- Fin menu Magasinier -->
    </v-treeview>
    <v-treeview hoverable class="mt-4">
      <!-- Début menu Gérant -->
      <template v-if="isGerant">
        <template v-for="(menuItem, l) in menuItemsGerant">
          <!-- Début menu simple -->
          <v-btn v-if="!menuItem.sub_menu" :key="l" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>
          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <v-menu v-if="menuItem.sub_menu" :key="l" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Fin sous menu dropdown -->
        </template>
      </template>
      <!-- Fin menu Gérant -->
    </v-treeview>
    <v-treeview hoverable class="mt-4" v-if="ismobile == true">
      <template v-if="isDirector">
        <template v-for="(menuItem, k) in menuItemsDirector">
          <!-- Début menu simple -->
          <v-btn v-if="!menuItem.sub_menu" :key="k" :to="menuItem.to" text>
            <v-icon left>{{ menuItem.icon }}</v-icon>
            {{ menuItem.title }}
          </v-btn>

          <!-- Fin menu simple -->

          <!-- Début sous menu dropdown -->
          <v-menu v-if="menuItem.sub_menu" :key="k" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text>
                <v-icon left>{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-subheader inset>Menu {{ menuItem.title }}</v-subheader>
                <v-list-item v-for="(item, j) in menuItem.sub_menu" :key="j">
                  <v-list-item-title>
                    <v-btn :to="item.to" text>
                      <v-icon left>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <!-- Fin sous menu dropdown -->
        </template>
      </template>
    </v-treeview>
    <v-treeview hoverable class="mt-4">
      <v-menu open-on-hover offset-y>
        <template v-slot:activator="{ on }">
          <v-btn class="" text v-on="on">
            <v-icon left> mdi-earth </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-title>
            <v-btn text width="100%" @click="changedtofr">
              Français
            </v-btn>
          </v-list-item-title>

          <v-list-item-title>
            <v-btn text width="100%" @click="changedtoen">
              English
            </v-btn>
          </v-list-item-title>
        </v-list>
      </v-menu>
    </v-treeview>
  </div>
</template>
<script>
import i18n from "@/i18n";

export default {
  name: "MenuToolBar",

  props: {
    role: {
      type: Array,
      default: null,
      required: false,
    },
  },
  data: () => ({
    model: "tab-2",
    connected_user: null,
    interval: 0,
    time: 10000,
  }),
  computed: {
    ismobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isAccueil() {
      return true;
    },
    isAdmin() {
      const isObjectPresent = this.role.find((o) => o.idRole === 1);
      return isObjectPresent;
    },
    isDirector() {
      const isObjectPresent = this.role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
    isGerant() {
      const isObjectPresent = this.role.find((o) => o.idRole === 3);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
    isVendeur() {
      const isObjectPresent = this.role.find((o) => o.idRole === 4);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
    isCaissier() {
      const isObjectPresent = this.role.find((o) => o.idRole === 5);
      return isObjectPresent;
      //return this._role <= 4 ? true : false;
    },
    isVendeurCaisse() {
      if (this.isVendeur && this.isCaissier) return true;
      else return false;
    },
    isVendeurCaisseDirecteur() {
      if (this.isVendeur && this.isCaissier && this.isDirector) return true;
      else return false;
    },
    isMagasinier() {
      const isObjectPresent = this.role.find((o) => o.idRole === 6);
      return isObjectPresent;
      //return this._role === 5 ? true : false;
    },
    // year() {
    //   return moment().year();
    // },
    lang() {
      return this.$route.params.lang;
    },

    menuItemsAccueil() {
      return [
        {
          title: this.$t("menu.home"),
          icon: "mdi-home",
          to: "/" + this.lang + "/app/accueil",
        },
      ];
    },

    menuItemsMagasinier() {
      return [
        {
          title: this.$t("menu.magasin.magasin"),
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: this.$t("menu.magasin.fairelivraison"),
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-livraison",
            },
            {
              title: this.$t("menu.magasin.meslivraison"),
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/livraison",
            },
            {
              title: this.$t("menu.magasin.receptionarticle"),
              icon: "mdi-cart-arrow-down",
              to: "/" + this.lang + "/app/reception",
            },
          ],
        },
      ];
    },
    menuItemsVendeur() {
      return [
        {
          title: this.$t("menu.facture.facture"),
          icon: "mdi-file-document-multiple",
          to: "/" + this.lang + "/app/facture",
        },
        {
          title: this.$t("menu.facture.client"),
          icon: "mdi-account-group",
          to: "/" + this.lang + "/app/client",
        },
      ];
    },

    menuItemsCaissier() {
      return [
        {
          title: this.$t("menu.caisse.caisse"),
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: this.$t("menu.caisse.caisse"),
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-non-solde",
            },
            {
              title: this.$t("menu.caisse.ventecaissiere"),
              icon: "mdi-cart-arrow-down",
              to: "/" + this.lang + "/app/facture-caissier",
            },
          ],
        },
      ];
    },
    menuItemsGerant() {
      return [
        {
          title: this.$t("menu.article.article"),
          icon: "mdi-cart",
          sub_menu: [
            {
              title: this.$t("menu.article.listarticle"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/articles",
            },
            {
              title: this.$t("menu.article.listarticleinstock"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-quantite-stock",
            },
            {
              title: this.$t("menu.article.listarticlesoldout"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-quantite-sortie",
            },
            {
              title: this.$t("menu.article.imprimerarticle"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-categorie",
            },
            {
              title: this.$t("menu.article.categorie"),
              icon: "mdi-cart-plus",
              to: "/" + i18n.locale + "/app/categorie",
            },
            {
              title: this.$t("menu.article.fourniseur"),
              icon: "mdi-car-multiple",
              to: "/" + i18n.locale + "/app/fournisseur",
            },
          ],
        },
      ];
    },
    menuItemsDirector() {
      return [
        {
          title: this.$t("menu.boutique.boutique"),
          icon: "mdi-store",
          sub_menu: [
            {
              title: this.$t("menu.boutique.nouvelleboutique"),
              icon: "mdi-storefront",
              to: "/" + i18n.locale + "/app/boutique",
            },
            {
              title: this.$t("menu.boutique.listedepot"),
              icon: "mdi-clipboard-text-outline",
              to: "/" + i18n.locale + "/app/depot",
            },
            {
              title: this.$t("menu.boutique.Mouvementarticle"),
              icon: "mdi-cart-arrow-right",
              to: "/" + i18n.locale + "/app/mouvement-articles",
            },
            {
              title: this.$t("menu.boutique.typedefacture"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/type-facture",
            },
            {
              title: this.$t("menu.boutique.meilleurclient"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/client-meilleur",
            },
            {
              title: this.$t("menu.boutique.utilisateur"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/utilisateur-boutique",
            },
            {
              title: this.$t("menu.boutique.ajoututilisateurdepot"),
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/depot-user",
            },
            {
              title: this.$t("menu.boutique.Rapport"),
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/raport",
            },
          ],
        },
      ];
    },

    menuItemsAdmin() {
      return [
        {
          title: this.$t("menu.parametre.parametre"),
          icon: "mdi-store",
          sub_menu: [
            {
              title: this.$t("menu.parametre.useradmin"),
              icon: "mdi-storefront",
              to: "/" + i18n.locale + "/app/utilisateur-admin",
            },
            {
              title: this.$t("menu.parametre.userdirector"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/utilisateur-directeur",
            },
            {
              title: this.$t("menu.parametre.userdirector"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/utilisateur-activatedirecteur",
            },
          ],
        },
      ];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("login/LOGOUT_AUTH");
      this.$router.push(`/${i18n.locale}/login`).catch(() => {});
    },
    resetTimer() {
      clearTimeout(this.interval);
      this.interval = setTimeout(this.countDown, 3600000); // 1h
    },
    countDown() {
      clearTimeout(this.interval);
      this.logout();
    },
    changedtofr: function() {
      i18n.locale = "fr";

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },

    changedtoen: function() {
      i18n.locale = "en";
      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
  },
};
</script>
<style lang="css" scoped>
#sub {
  background-color: aqua;
}

#content {
  background-color: #f8f9fa;
}
</style>
