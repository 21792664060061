import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  depots: [],
  depotsboutique:[],
  ligneDepots: [],
  userdepots:[],
  boutiqueDepots:[]
};

const getters = {
  getAllDepots: (state) => state.depots,
  getAllLigneDepots: (state) => state.ligneDepots,
  getAllUserDepots: (state) => state.userdepots,
  getAllDepotsBoutique: (state) => state.depotsboutique,
  getAllBoutiqueDepots: (state) => state.boutiqueDepots
};

const actions = {
  FETCH_ALL_DEPOTS: ({ commit }) => {
    // let boutiqueId = JSON.parse(window.localStorage.getItem('boutiqueId'));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/ligne/depot/connect/user", {
          timeout: 3000,
        })
        .then(function(response) {
          const depots = response.data;
          commit("ALL_DEPOTS", depots);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_LIGNE_DEPOTS: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/ligne_depot_current_boutique/" +
            boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const depots = response.data;
          commit("ALL_LIGNE_DEPOTS", depots);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  FETCH_ALL_USER_DEPOTS: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/ligne_depot_current_boutique/" +
            boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          
          const depots = response.data;
          commit("ALL_USER_DEPOTS", depots);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  
  FETCH_ALL_DEPOTS_BOUTIQUE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/ligne_depot_current_user/" +
            boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const depots = response.data;
          // console.log(depots)
          commit("ALL_DEPOTS_BOUTIQUE", depots);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  ADD_DEPOT: ({ commit }, depot) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/depots", depot, {
          timeout: 3000,
        })
        .then(function(response) {
          const depot = response.data;
          commit("NEW_DEPOT", depot);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  ADD_LIGNE_DEPOT: ({ commit }, depot) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/ligne_depot", depot, {
          timeout: 3000,
        })
        .then(function(response) {
          const depot = response.data;
          commit("NEW_LIGNE_DEPOT", depot);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  UPDATE_DEPOT: ({ commit }, depot) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/depots/" + depot.idDepot, depot)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_ARTICLE", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_DEPOT: ({ commit }, {depot , user},) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/delete/ligne/depot/" + depot + '/' + user)
        .then(function() {
          commit("REFRESH_DELETE", depot);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_DEPOTS: (state, depots) => {
    state.depots = depots;
  },

  ALL_LIGNE_DEPOTS: (state, ligneDepots) => {
    state.ligneDepots = ligneDepots;
  },

  ALL_USER_DEPOTS: (state, userdepots)=>
  {
    state.userdepots = userdepots;
  },
  ALL_DEPOTS_BOUTIQUE:(state, depotsboutique)=>
  {
    state.depotsboutique = depotsboutique
  },

  NEW_DEPOT: (state, depots) => {
    state.depots.push(depots);
  },  

  NEW_DEPOTS_BOUTIQUE: (state, depotsboutique) =>
  {
    state.depotsboutique.push(depotsboutique)
  },

  NEW_LIGNE_DEPOT: (state, ligneDepots) => {
    state.ligneDepots.push(ligneDepots);
  },
  NEW_USER_DEPOT:(state, depots) =>
  {
    state.userdepots.push(depots)
  },

  REFRESH_ARTICLE: (state, c_new_value) => {
    const index = state.depots.findIndex(
      (x) => x.idDepot == c_new_value.idDepot
    );
    Object.assign(state.depots[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.depots.findIndex(
      (x) => x.idDepot == c_del_value.idDepot
    );
    state.depots.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
