import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  articles: [],
  paginates:[],
};

const getters = {
  getAllArticles: (state) => state.articles,
  getAllPaginates:(state) =>state.paginates
};

const actions = {
  FETCH_ALL_ARTICLES_PAGINATE:({commit},page) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"))
    return new Promise((resolve, reject) =>
    {
      
      instance.get(APIENDPOINT+"/api/entree/boutique/stock/page/"+boutiqueId.idBoutique+"/"+ page,
      {
        timeout:30000,
      }
      ).then(function(response)
      {
        const articles = response.data
        commit("ALL_PAGINATES", articles)
       // console.log(articles)
        resolve()
      })
      .catch(function(err)
      {
        reject(err)
      })
    })
  },
  
  SEARCH_ALL_ARTICLES_PAGINATE:({commit},{page, key}) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"))
   
    return new Promise((resolve, reject) =>
    {
      instance.get(APIENDPOINT+"/api/search/entree/boutique/stock/page/"+boutiqueId.idBoutique+"/"+ page +"/"+key,
      {
        timeout:30000,
      }
      ).then(function(response)
      {
        // console.log(response.data)
        const articles = response.data
        commit("ALL_PAGINATES", articles)
        resolve()
      })
      .catch(function(err)
      {
        reject(err)
      })
    })
  },

  FETCH_ALL_ARTICLES_STOCK: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/entree/articles/depot/user/" +
            boutiqueId.idBoutique,
          {
            timeout: 30000,
          }
        )
        .then(function(response) {
          const articles = response.data;
          commit("ALL_ARTICLES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  FETCH_ALL_ARTICLES_IN_STOCK: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/entree/boutique/stock/" + boutiqueId.idBoutique,
          {
            timeout: 30000,
          }
        )
        .then(function(response) {
          const articles = response.data;
          commit("ALL_ARTICLES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  FETCH_ALL_ARTICLES_SOLD_OUT: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/entree/boutique/rupture/" + boutiqueId.idBoutique,
          {
            timeout: 30000,
          }
        )
        .then(function(response) {
          const articles = response.data;
          commit("ALL_ARTICLES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  FETCH_ALL_DEPOT_STOCK: ({ commit }, { idD, idC }) => {
    if (idD === undefined && idC === undefined) {
      idD = 0;
      idC = 0;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      return new Promise((resolve, reject) => {
        instance
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              timeout: 30000,
            }
          )
          .then(function(response) {
            const articles = response.data;
            commit("ALL_ARTICLE_DEPOTS", articles);
            resolve();
          })
          .catch(function(err) {
            reject(err);
          });
      });
    } else if (idD === undefined && idC !== undefined) {
      idD = 0;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      return new Promise((resolve, reject) => {
        instance
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              timeout: 30000,
            }
          )
          .then(function(response) {
            const articles = response.data;
            commit("ALL_ARTICLE_DEPOTS", articles);
            resolve();
          })
          .catch(function(err) {
            reject(err);
          });
      });
    } else if (idD !== undefined && idC === undefined) {
      idC = 0;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      return new Promise((resolve, reject) => {
        instance
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              timeout: 30000,
            }
          )
          .then(function(response) {
            const articles = response.data;
            commit("ALL_ARTICLE_DEPOTS", articles);
            resolve();
          })
          .catch(function(err) {
            reject(err);
          });
      });
    } else {
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      return new Promise((resolve, reject) => {
        instance
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              timeout: 30000,
            }
          )
          .then(function(response) {
            const articles = response.data;
            commit("ALL_ARTICLE_DEPOTS", articles);
            resolve();
          })
          .catch(function(err) {
            reject(err);
          });
      });
    }
  },

  ADD_ARTICLE: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/articles_all", article, {
          timeout: 3000,
        })
        .then(function(response) {
          const article = response.data;
          commit("NEW_ARTICLE", article);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  ADD_ARTICLE_STOCK: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/ajouter", article, {
          timeout: 3000,
        })
        .then(function(response) {
          const article = response.data;
          commit("NEW_ARTICLE_STOCK", article);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_ARTICLE_STOCK1: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/ajouter1", article, {
          timeout: 3000,
        })
        .then(function(response) {
          const article = response.data;
          commit("REFRESH_ARTICLE_STOCK", article);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  UPDATE_ARTICLE: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .put(
          APIENDPOINT + "/api/articles/" + article.article.idArticle,
          article
        )
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_ARTICLE", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  IMPRIMER: ({ commit }, { idD, idC }) => {
    if (idD === undefined && idC === undefined) {
      idD = 0;
      idC = 0;
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    } else if (idD === undefined && idC !== undefined) {
      idD = 0;
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    } else if (idD !== undefined && idC === undefined) {
      idC = 0;
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    }
  },
  IMPRIMER_INSTOCK:({ commit }) =>
  {
    return new Promise((resolve, reject) => {
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      instance
        .get(
          APIENDPOINT +
            "/api/imp/entree/boutique/stock/" +
            boutiqueId.idBoutique,
          {
            responseType: "arraybuffer",
            timeout: 300000000,
          }
        )
        .then(function(response) {
          const f = "nothing";
          commit("NEW_ARTICLE", f);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          resolve();
        })
        .catch(function(err) {
          //console.log("Error:"+err)  article_boutique_imprimer
          reject(err);
        });
    });
  },
  IMPRIMER_OUTSTOCK:({ commit }) =>
  {
    return new Promise((resolve, reject) => {
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      instance
        .get(
          APIENDPOINT +
            "/api/imp/entree/boutique/rupture/" +
            boutiqueId.idBoutique,
          {
            responseType: "arraybuffer",
            timeout: 300000000,
          }
        )
        .then(function(response) {
          const f = "nothing";
          commit("NEW_ARTICLE", f);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          resolve();
        })
        .catch(function(err) {
          //console.log("Error:"+err)  article_boutique_imprimer
          reject(err);
        });
    });
  },
  IMPRIMER_ALL: ({ commit }, { idD, idC }) => {
    
    if (idD === undefined && idC === undefined) {
      idD = 0;
      idC = 0;
      console.log(idD);
    console.log(idC);
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            console.log("Error:"+err)  // article_boutique_imprimer
            reject(err);
          });
      });
    } else if (idD === undefined && idC !== undefined) {
      idD = 0;
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    } else if (idD !== undefined && idC === undefined) {
      idC = 0;
      console.log(idD);
    console.log(idC);
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        instance
          .get(
            APIENDPOINT +
              "/api/article/boutique/imprimer/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC,
            {
              responseType: "arraybuffer",
              timeout: 300000000,
            }
          )
          .then(function(response) {
            const f = "nothing";
            commit("NEW_ARTICLE", f);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          })
          .catch(function(err) {
            //console.log("Error:"+err)  article_boutique_imprimer
            reject(err);
          });
      });
    }
  },

  ADD_IMAGE: ({ commit }, dat) => {
		let image = dat.images;
		var bodyFormData = new FormData();
    bodyFormData.append('image', image, image.name);
    
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/article/image/add/" + dat.id, bodyFormData)
        .then(function() {
          // const article = response.data;
          commit("REFRESH_IMAGE");
          resolve();
        })
        .catch(function(err) {
          console.log(err)
          reject(err);
        });
    });
	},

  DELETE_ARTICLE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/articles/" + c.article.idArticle)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_ARTICLES: (state, articles) => {
    state.articles = articles;
  },
  ALL_PAGINATES: (state, paginates) =>
  {
    state.paginates =paginates
  },
  ALL_ARTICLE_DEPOTS: (state, articles) => {
    state.articles = articles;
  },
  NEW_ARTICLE: (state, articles) => {
    state.articles.push(articles);
  },
  ADD_IMAGE:(state, articles) => {
    state.articles.push(articles);
  },
  NEW_PAGINATES: (state, paginates) =>
  {
    state.paginates =paginates
  },
  NEW_ARTICLE_STOCK: (state, articles) => {
    state.articles.push(articles);
  },
  SET_CURRENT_PAGE: (state, page) =>
  {
    state.paginates.current_page = page;
  },
  REFRESH_IMAGE: () => {
    
  },
  REFRESH_ARTICLE: (state, c_new_value) => {
    const index = state.articles.findIndex((x) => x.id == c_new_value.id);
    Object.assign(state.articles[index], c_new_value);
  },

  REFRESH_ARTICLE_STOCK: (state, c_new_value) => {
    const index = state.articles.findIndex((x) => x.id == c_new_value.id);
    Object.assign(state.articles[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.articles.findIndex((x) => x.id == c_del_value.id);
    state.articles.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
