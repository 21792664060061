<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <LoadingDialog :value="isLoading" />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog
                  v-model="dialog"
                  scrollable
                  persistent
                  max-width="650"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                    >
                      <v-icon left>mdi-plus-circle-outline</v-icon
                      >{{ $t("users.new") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 400px">
                      <v-container grid-list-md>
                        <v-form ref="formUser">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.username"
                                :rules="[(v) => !!v || $t('users.required3')]"
                                :label="$t('user')"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.nom"
                                :label="$t('users.name1')"
                                :rules="[(v) => !!v || $t('users.required1')]"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.prenom"
                                :rules="[(v) => !!v || $t('users.required2')]"
                                :label="$t('users.name2')"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.email"
                                :rules="[(v) => !!v || $t('users.required2')]"
                                :label="$t('users.email')"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.password"
                                :rules="[(v) => !!v || $t('users.required4')]"
                                :label="$t('pass')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{
                      $t("furnisher.deleting")
                    }}</v-card-title>
                    <v-card-text class="black--text pt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="red darken-1"
                        @click="delete_dialog = false"
                        text
                        >{{ $t("no") }}</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                        >{{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchUser"
                append-icon="mdi-magnify"
                :label="$t('users.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="allUtilisateurs"
        :search="searchUser"
        sort-by="username"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("users.users") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.username }}</td>
            <td>{{ item.nom }}</td>
            <td>{{ item.prenom }}</td>
            <td>
              <span v-for="rol in item.role" :key="rol.id"
                >{{ rol.libelle }} |
              </span>
            </td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItem(item, 0)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../components/snackbar/ErrorSnackBar";
import LoadingDialog from "../components/dialog/LoadingDialog";
import { mask } from "vue-the-mask";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    LoadingDialog,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchUser: "",
    searchFournisseur: "",
    dialog: false,
    snackbar: false,
    loading: false,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    //patients: [],
    editedIndex: -1,
    editedItem: {
      idUser: 0,
      username: "",
      password: "",
      nom: "",
      prenom: "",
      email: "",
      role: [
        {
          idRole: 0,
        },
      ],
    },
    defaultItem: {
      idUser: 0,
      username: "",
      password: "",
      nom: "",
      prenom: "",
      email: "",
      role: [
        {
          idRole: 0,
        },
      ],
    },
    value: [],
    options: [],
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("user"),
          align: "left",
          sortable: true,
          value: "username",
        },
        { text: this.$t("users.name1"), value: "nom", sortable: true },
        { text: this.$t("users.name2"), value: "prenom", sortable: true },
        { text: this.$t("users.role"), value: "role", sortable: true },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
    ...mapGetters({
      allUtilisateurs: "utilisateur/allUsersAdmin",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("users.save")
        : this.$t("users.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("item.save1")
        : this.$t("item.update1");
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    createDossierDialog(val) {
      val || this.close();
    },
  },

  created() {
    this.loading = true;
    this.utilisateur();
    this.$store
      .dispatch("utilisateur/FETCH_USERS_ADMIN")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions({
      utilisateur: "utilisateur/FETCH_USERS_ADMIN",
      addNewUtilisateurAdmin: "utilisateur/ADD_USER_ADMIN",
      updateUtilisateur: "utilisateur/UPDATE_USER_ADMIN",
      deleteUtilisateur: "utilisateur/DELETE_USER",
    }),

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    editItem(item) {
      this.editedIndex = this.allUtilisateurs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.value = this.editedItem.role;
      this.editedItem.password = "";
      this.dialog = true;
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t("furnisher.msg1") +
          item.raisonSociale.toUpperCase() +
          this.$t("furnisher.msg2") +
          this.$t("furnisher.msg3");
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteUtilisateur(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t("furnisher.warning1");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("furnisher.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.value = [];
        this.modelSelectedBoutique = null;
        this.editedIndex = -1;
      }, 300);
    },

    createDossierDialog() {
      if (this.formAction !== "Modifier") {
        if (this.$refs.formUser.validate()) {
          this.dialog_save = true;
        }
      } else {
        this.save();
      }
    },

    openCodeDossierDialog() {
      this.dialog_save = false;
    },

    saveWithDossier() {
      this.save();
    },

    async save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        let users = {};
        users = Object.assign(this.editedItem);
        await this.updateUtilisateur(users)
          .then(() => {
            this.loading = false;
            const msg =
              "La modification pour le fournisseur a bien été pris en compte.";
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            const msg =
              "Une erreur est survenue lors de la modification du fournisseur, veuillez réessayer svp.";
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.close();
      } else {
        if (this.$refs.formUser.validate()) {
          this.loading = true;
          //let boutiqueId = JSON.parse(window.localStorage.getItem('boutiqueId'));
          let users = {};
          users = Object.assign(this.editedItem);
          await this.addNewUtilisateurAdmin(users)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              const msg = "Le nouveau fournisseur a bien été ajouté.";
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
            })
            .catch(() => {
              this.loading = false;
              const msg =
                "Une erreur est survenue lors de l'enrégistrement du fournisseur, veuillez réessayer svp.";
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
            });
        }
        this.close();
      }
    },
  },
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>