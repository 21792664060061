<template>
  <div id="app">
    <v-app color="primary">
      <template
        v-if="
          !(['Login', 'Default', 'index', 'Register', 'ForgetPass'].indexOf($route.name) > -1)
        "
      >
        <v-container>
          <v-app-bar app>
            <v-toolbar-items v-if="ismobile == false">
              <!-- Début menu Secrétaire -->

              <!-- <template v-if="isVendeurCaisseDirecteur"> -->
                <!-- <template v-for="(menuItem, i) in menuItemsVendeur"> -->
                  <!-- Début menu simple -->
                  <!-- <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="i"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn> -->
                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <!-- <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu> -->
                  <!-- Fin sous menu dropdown -->
                <!-- </template> -->
                <!-- <template v-for="(menuItem, i) in menuItemsCaissier"> -->
                  <!-- Début menu simple -->
                  <!-- <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="i"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn> -->
                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <!-- <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu> -->
                  <!-- Fin sous menu dropdown -->
                <!-- </template> -->
              <!-- </template> -->

              <!-- <template v-else-if="isVendeurCaisse">
                <template v-for="(menuItem, i) in menuItemsVendeur"> -->
                  <!-- Début menu simple -->
                  <!-- <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="i"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn> -->
                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <!-- <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu> -->
                  <!-- Fin sous menu dropdown -->
                <!-- </template>
              </template> -->

              <template v-if="isVendeur">
                <template v-for="(menuItem, it) in menuItemsVendeur">
                  <!-- Début menu simple -->
                  <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="it"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn>
                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <v-menu v-if="menuItem.sub_menu" :key="it" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <!-- Fin sous menu dropdown -->
                </template>
              </template>
              <!-- Fin menu Secrétaire -->
              <!-- Début menu Secrétaire -->
              <template v-if="isCaissier">
                <template v-for="(menuItem, i) in menuItemsCaissier">
                  <!-- Début menu simple -->
                  <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="i"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn>
                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <v-menu v-if="menuItem.sub_menu" :key="i" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <!-- Fin sous menu dropdown -->
                </template>
              </template>
              <!-- Fin menu Secrétaire -->
              <!-- Début menu Directeur -->
              <template v-if="isDirector">
                <template v-for="(menuItem, kk) in menuItemsDirector">
                  <!-- Début menu simple -->
                  <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="k + menuItem.to"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn>

                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <v-menu v-else :key="kk + menuItem.to" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <!-- Fin sous menu dropdown -->
                </template>
              </template>

              <template v-if="isAdmin">
                <template v-for="(menuItem, kj) in menuItemsAdmin">
                  <!-- Début menu simple -->
                  <v-btn
                    v-if="!menuItem.sub_menu"
                    :key="k + menuItem.to"
                    :to="menuItem.to"
                    text
                  >
                    <v-icon left>{{ menuItem.icon }}</v-icon>
                    {{ menuItem.title }}
                  </v-btn>

                  <!-- Fin menu simple -->

                  <!-- Début sous menu dropdown -->
                  <v-menu v-else :key="kj + menuItem.to" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text>
                        <v-icon left>{{ menuItem.icon }}</v-icon>
                        {{ menuItem.title }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list>
                        <v-subheader inset
                          >Menu {{ menuItem.title }}</v-subheader
                        >
                        <v-list-item
                          v-for="(item, j) in menuItem.sub_menu"
                          :key="j"
                        >
                          <v-list-item-title>
                            <v-btn :to="item.to" text>
                              <v-icon left>{{ item.icon }}</v-icon>
                              {{ item.title }}
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <!-- Fin sous menu dropdown -->
                </template>
              </template>

              <!-- Fin menu Directeur -->
            </v-toolbar-items>

            <Navbar v-if="!(['Login', 'Default'].indexOf($route.name) > -1)" />
          </v-app-bar>
        </v-container>
      </template>
      <Default />
      <!-- <SideBar v-if="!(['Login', 'Default'].indexOf($route.name) > -1)" /> -->
      <FooterBar v-if="['Login'].indexOf($route.name)" />
    </v-app>
  </div>
</template>

<script>
import moment from "moment";
import Navbar from "./Navbar.vue";
import FooterBar from "../menu/FooterBar.vue";
import Default from "@/views/Default";
import i18n from "@/i18n";
export default {
  name: "MenuToolBar",

  props: {
    role: {
      type: Array,
      default: null,
      required: false,
    },
  },

  components: {
    FooterBar,
    Default,
    Navbar,
  },
  data: () => ({
    model: "tab-2",
    connected_user: null,
    interval: 0,
    time: 10000,
  }),
  computed: {
    ismobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    isAccueil() {
      if (this.$vuetify.breakpoint.mobile == false) {
        return true;
      }
    },
    isAdmin() {
      const isObjectPresent = this.role.find((o) => o.idRole === 1);
      return isObjectPresent;
    },
    isDirector() {
      const isObjectPresent = this.role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
    isGerant() {
      const isObjectPresent = this.role.find((o) => o.idRole === 3);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
    isVendeur() {
      const isObjectPresent = this.role.find((o) => o.idRole === 4);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
    isCaissier() {
      // console.log(this.role);
      const isObjectPresent = this.role.find((o) => o.idRole === 5);
      // console.log(isObjectPresent);
      return isObjectPresent;
      //return this._role <= 4 ? true : false;
    },
    isVendeurCaisse() {
      if (this.isVendeur && this.isCaissier) return true;
      else return false;
    },
    isVendeurCaisseDirecteur() {
      if (this.isVendeur && this.isCaissier && this.isDirector) return true;
      else return false;
    },
    isMagasinier() {
      const isObjectPresent = this.role.find((o) => o.idRole === 6);
      return isObjectPresent;
      //return this._role === 5 ? true : false;
    },
    year() {
      return moment().year();
    },
    lang() {
      return this.$route.params.lang;
    },

    menuItemsAccueil() {
      return [
        {
          title: this.$t("menu.home"),
          icon: "mdi-home",
          to: "/" + this.lang + "/app/accueil",
        },
      ];
    },

    menuItemsMagasinier() {
      return [
        {
          title: this.$t("menu.magasin.magasin"),
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: this.$t("menu.magasin.fairelivraison"),
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-livraison",
            },
            {
              title: this.$t("menu.magasin.meslivraison"),
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/livraison",
            },
            {
              title: this.$t("menu.magasin.receptionarticle"),
              icon: "mdi-cart-arrow-down",
              to: "/" + this.lang + "/app/reception",
            },
          ],
        },
      ];
    },
    menuItemsVendeur() {
      return [
        {
          title: this.$t("menu.facture.facture"),
          icon: "mdi-file-document-multiple",
          to: "/" + this.lang + "/app/facture",
        },
        {
          title: this.$t("menu.facture.client"),
          icon: "mdi-account-group",
          to: "/" + this.lang + "/app/client",
        },
      ];
    },

    menuItemsCaissier() {
      return [
        {
          title: this.$t("menu.caisse.caisse"),
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: this.$t("menu.caisse.caisse"),
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-non-solde",
            },
            // {
            //   title: this.$t('menu.caisse.encaisser'),
            //   icon: "mdi-cart-arrow-down",
            //   to: "/" + this.lang + "/app/facture-solde",
            // },
            {
              title: this.$t("menu.caisse.ventecaissiere"),
              icon: "mdi-cart-arrow-down",
              to: "/" + this.lang + "/app/facture-caissier",
            },
          ],
        },
      ];
    },
    menuItemsGerant() {
      return [
        {
          title: this.$t("menu.article.article"),
          icon: "mdi-cart",
          sub_menu: [
            {
              title: this.$t("menu.article.listarticle"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/articles",
            },
            {
              title: this.$t("menu.article.listarticleinstock"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-quantite-stock",
            },
            {
              title: this.$t("menu.article.listarticlesoldout"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-quantite-sortie",
            },
            {
              title: this.$t("menu.article.imprimerarticle"),
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-categorie",
            },
            {
              title: this.$t("menu.article.categorie"),
              icon: "mdi-cart-plus",
              to: "/" + i18n.locale + "/app/categorie",
            },
            {
              title: this.$t("menu.article.fourniseur"),
              icon: "mdi-car-multiple",
              to: "/" + i18n.locale + "/app/fournisseur",
            },
          ],
        },
      ];
    },
    menuItemsDirector() {
      return [
        {
          title: this.$t("menu.boutique.boutique"),
          icon: "mdi-store",
          sub_menu: [
            {
              title: this.$t("menu.boutique.nouvelleboutique"),
              icon: "mdi-storefront",
              to: "/" + i18n.locale + "/app/boutique",
            },
            {
              title: this.$t("menu.boutique.listedepot"),
              icon: "mdi-clipboard-text-outline",
              to: "/" + i18n.locale + "/app/depot",
            },
            {
              title: this.$t("menu.facture.client"),
              icon: "mdi-account-group",
              to: "/" + this.lang + "/app/client",
            },
            {
              title: this.$t("menu.chauffeur.listechauffeur"),
              icon: "mdi-account-tie",
              to: "/" + i18n.locale + "/app/chauffeur",
            },
            {
              title: this.$t("menu.boutique.Mouvementarticle"),
              icon: "mdi-cart-arrow-right",
              to: "/" + i18n.locale + "/app/mouvement-articles",
            },
            {
              title: this.$t("menu.boutique.typedefacture"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/type-facture",
            },
            {
              title: this.$t("menu.boutique.meilleurclient"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/client-meilleur",
            },
            {
              title: this.$t("menu.boutique.utilisateur"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/utilisateur-boutique",
            },
            {
              title: this.$t("menu.boutique.ajoututilisateurdepot"),
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/depot-user",
            },
            {
              title: this.$t("menu.boutique.Rapport"),
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/raport",
            },
          ],
        },
      ];
    },

    menuItemsAdmin() {
      return [
        {
          title: this.$t("menu.parametre.parametre"),
          icon: "mdi-store",
          sub_menu: [
            {
              title: this.$t("menu.parametre.useradmin"),
              icon: "mdi-storefront",
              to: "/" + i18n.locale + "/app/utilisateur-admin",
            },
            {
              title: this.$t("menu.parametre.useractive"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/utilisateur-directeur",
            },
            {
              title: this.$t("menu.parametre.activatedirecteur"),
              icon: "mdi-clipboard-text",
              to: "/" + i18n.locale + "/app/utilisateur-activatedirecteur",
            },
          ],
        },
      ];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("login/LOGOUT_AUTH");
      this.$router.push(`/${i18n.locale}/login`).catch(() => {});
    },
    resetTimer() {
      clearTimeout(this.interval);
      this.interval = setTimeout(this.countDown, 3600000); // 1h
    },
    countDown() {
      clearTimeout(this.interval);
      this.logout();
    },
    changedtofr: function() {
      i18n.locale = "fr";

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },

    changedtoen: function() {
      i18n.locale = "en";
      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
  },
};
</script>
<style lang="css" scoped>
#sub {
  background-color: aqua;
}

#content {
  background-color: #f8f9fa;
}
</style>
