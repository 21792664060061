var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"color":"primary"}},[(
        !(['Login', 'Default', 'index', 'Register', 'ForgetPass'].indexOf(_vm.$route.name) > -1)
      )?[_c('v-container',[_c('v-app-bar',{attrs:{"app":""}},[(_vm.ismobile == false)?_c('v-toolbar-items',[(_vm.isVendeur)?[_vm._l((_vm.menuItemsVendeur),function(menuItem,it){return [(!menuItem.sub_menu)?_c('v-btn',{key:it,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:it,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e(),(_vm.isCaissier)?[_vm._l((_vm.menuItemsCaissier),function(menuItem,i){return [(!menuItem.sub_menu)?_c('v-btn',{key:i,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:i,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e(),(_vm.isDirector)?[_vm._l((_vm.menuItemsDirector),function(menuItem,kk){return [(!menuItem.sub_menu)?_c('v-btn',{key:_vm.k + menuItem.to,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_c('v-menu',{key:kk + menuItem.to,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1)]})]:_vm._e(),(_vm.isAdmin)?[_vm._l((_vm.menuItemsAdmin),function(menuItem,kj){return [(!menuItem.sub_menu)?_c('v-btn',{key:_vm.k + menuItem.to,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_c('v-menu',{key:kj + menuItem.to,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1)]})]:_vm._e()],2):_vm._e(),(!(['Login', 'Default'].indexOf(_vm.$route.name) > -1))?_c('Navbar'):_vm._e()],1)],1)]:_vm._e(),_c('Default'),(['Login'].indexOf(_vm.$route.name))?_c('FooterBar'):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }