<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog v-model="dialog" scrollable persistent max-width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="dialog = true" color="primary" dark class="mb-2" v-on="on">
                      <v-icon left>mdi-plus-circle-outline</v-icon>{{$t('type.new')}}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 150px;">
                      <v-container grid-list-md>
                        <v-form ref="formType_facture">
                          <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.libelle"
                                :rules="[v => !!v || $t('type.required1')]"
                                :label="$t('type.libelle')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{$t('close')}}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="550">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{$t('type.deleting')}}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="red darken-1" @click="delete_dialog=false" text>{{$t('no')}}</v-btn>
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                      >{{$t('yes')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
                
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchTypefacture"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('type.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchTypefacture"
        sort-by="nom"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{$t('datatable-title.facture.listetypefacture')}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.idTypeFacture}}</td>
            <td>{{item.libelle}}</td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small @click="deleteItem(item, 0)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{$t('facture.nodata')}}</p>
        </template>
      </v-data-table>
    <div class="mt-4">
       <v-pagination
          v-if="searchTypefacture"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import { APIENDPOINT} from "@/api/app.config";
import axios from "axios";

import { mask } from "vue-the-mask";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
   
  },
  directives: {
    mask
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchTypefacture: "",
    facture:{},
    current_pag:1,
    dialog: false,
    snackbar: false,
    loading: false,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    //patients: [],
    editedIndex: -1,
    editedItem: {
      idTypeFacture: 0,
      libelle: "",
    },
    defaultItem: {
      idTypeFacture: 0,
      libelle: "",
    }
  }),

  computed: {
    headers(){
      return [
      {
        text: "#",
        align: "left",
        sortable: true,
        value: "idTypeFacture"
      },
      { text: this.$t('datatable-header.facture.typefacture'), value: "libelle" },
      { text: "Actions", value: "action", sortable: false }
    ]
    },

     total_page() {
        
      if (this.facture && this.facture.last_page) {
        return this.facture.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.facture != null && this.facture.data) {
        return this.facture.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.facture && this.facture.last_page) {
        return this.facture.last_page;
      } else {
        return 0;
      }
    },  

    ...mapGetters({
      gettoken: "login/gettoken",
      allType_factures: "typefacture/getAllType_factures"
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t('type.save')
        : this.$t('type.update');
    },

    formAction() {
      return this.editedIndex === -1 ? this.$t('item.save1') : this.$t('item.update1');
    },
    
    isLoading() {
      return this.loading;
    }
  },

  watch: {
   
    createDossierDialog(val) {
      val || this.close();
    }
  },

  created() {
    this.loading = true;
    this.typefactures();
    this.$store
      .dispatch("typefacture/FETCH_ALL_TYPE_FACTURES")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
     mounted() {
    this.axiosList();
  },
  methods: {
    ...mapActions({
      typefactures: "typefacture/FETCH_ALL_TYPE_FACTURES",
      addNewType_facture: "typefacture/ADD_TYPE_FACTURES",
      updateType_facture: "typefacture/UPDATE_TYPE_FACTURES",
      deleteType_facture: "typefacture/DELETE_TYPE_FACTURES"
    }),

    editItem(item) {
      this.editedIndex = 0
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
      async axiosList() {
      this.loading = true;
      //let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      await axios
        .get(
          APIENDPOINT +
            "/api/type/facture/page/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.facture = response.data;
            if (this.facture.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      if (this.searchTypefacture) {
        await axios
          .get(
            APIENDPOINT +
              "/api/type/facture/page/" +
              this.current_pag +
              "/" +
              this.searchTypefacture,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.facture = response.data;
              if (this.facture.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
          console.log(error);
        });
      } else {
        this.axiosList();
      }
    },
    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t('type.msg1')+
          item.nom +
          " " +
          item.prenom +
          ". " +
          this.$t('type.msg2') +
          this.$t('type.msg3');
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteType_facture(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t('type.warning1');
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t('type.warning2');
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
         this.axiosList();
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

 
    async save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        await this.updateType_facture(this.editedItem)
          .then(() => {
            this.loading = false;
            var msg = this.$t('type.warning3');
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t('type.warning4');
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
        this.axiosList();
        this.close();
      } else {
        if (this.$refs.formType_facture.validate()) {
          this.loading = true;
          const Type_facture = this.editedItem;
          //Type_facture.type_facture = this.editedItem;
          await this.addNewType_facture(Type_facture)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              var msg = this.$t('type.warning5');
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg
              });
            })
            .catch(() => {
              this.loading = false;
              var msg = this.$t('type.warning6');
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg
              });
            });
        }
         this.axiosList();
        this.close();
      }
    }
  }
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>