<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <ErrorSnackBar />
        <v-col cols="auto">
          <v-dialog v-model="confirm" transition="dialog-top-transition" max-width="600">
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="teal" class="justify-center" dark>{{
                  $t("registerform.dialog.title")
                }}</v-toolbar>
                <v-card-text>
                  <div class="text-h6 pa-4">
                    {{ $t("registerform.dialog.message1") }}
                    <div class="text-h6">
                      {{ $t("registerform.dialog.message2") }}
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
          <v-dialog v-model="preuves" transition="dialog-top-transition" max-width="600">
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="red" class="justify-center" dark>Error</v-toolbar>
                <v-card-text>
                  <div class="text-h6 pa-4">
                    {{ $t("preuve.erro") }}
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <v-col cols="auto">
          <v-dialog
            v-model="personaliser"
            transition="dialog-top-transition"
            max-width="600"
          >
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="teal" class="justify-center" dark>{{
                  $t("registerform.dialog.title")
                }}</v-toolbar>
                <v-card-text>
                  <v-col class="d-flex mt-4">
                    <v-text-field
                      class="required"
                      v-model="nbrboutique"
                      item-text="nombre de boutique"
                      item-value="nombre de boutique"
                      :label="$t('register.nbboutique')"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex mt-4">
                    <v-text-field
                      class="required"
                      v-model="nbrdepot"
                      item-text="nombre de depot"
                      item-value="nombre de depot"
                      :label="$t('register.nbdepot')"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex mt-4">
                    <v-text-field
                      class="required"
                      v-model="nbruser"
                      item-text="nombre d'utilisateur"
                      item-value="nombre d'utilisateur"
                      :label="$t('register.nbruser')"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-6">
              <v-card-text>
                <div class="justify-center">
                  <v-col>
                    <v-alert shaped prominent type="error" v-model="alert">
                      {{ $t("registerform.error.existemail") }}
                    </v-alert>
                  </v-col>
                </div>
                <div class="justify-center">
                  <v-col>
                    <v-alert shaped prominent type="error" v-model="alert2">
                      {{ $t("registerform.error.existusername") }}
                    </v-alert>
                  </v-col>
                </div>
                <v-form @submit.prevent="save" ref="formRegister">
                  <v-container>
                    <fieldset justify="space-between" class="dark">
                      <div class="text-center mt-2">
                        <h2>Identifiants</h2>
                      </div>
                      <v-row class="mt-4">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="nom"
                            prepend-inner-icon="mdi-account"
                            :rules="nomRules"
                            name="nom"
                            label="Nom"
                            type="text"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="prenom"
                            prepend-inner-icon="mdi-account"
                            :rules="prenomRules"
                            name="prenom"
                            label="Prenom"
                            type="text"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="username"
                            prepend-inner-icon="mdi-account"
                            :rules="usernameRules"
                            name="username"
                            label="Nom d'utilisateur"
                            type="text"
                            @input="Existusername"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="email"
                            prepend-inner-icon="mdi-email"
                            :rules="emailRules"
                            name="email"
                            label="Adresse Email"
                            type="email"
                            @input="Existemail"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="telephone"
                            :rules="[rules.required]"
                            prepend-inner-icon="mdi-cellphone"
                            name="telephone"
                            label="Telephone"
                            type="text"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            counter
                            v-model="password"
                            prepend-inner-icon="mdi-lock"
                            :rules="passwordRules"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                            :type="show1 ? 'text' : 'password'"
                            name="password"
                            label="Mot de passe"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model.lazy="confirmpassword"
                            prepend-inner-icon="mdi-lock"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                            :type="show1 ? 'text' : 'password'"
                            name="confirm-password"
                            label="Confirmer le mot de passe"
                            outlined
                            @input="match"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <fieldset class="mt-4 dark">
                      <div class="text-center mt-2">
                        <h2>Abonnement</h2>
                      </div>
                      <v-row class="mt-4">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="nomboutique"
                            :rules="[rules.required]"
                            prepend-inner-icon="mdi-account"
                            name="nomboutique"
                            label="Nom de votre boutique"
                            type="text"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="adresse"
                            prepend-inner-icon="mdi-city"
                            name="adresse"
                            label="Adresse"
                            type="text"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="tel"
                            :rules="[rules.required]"
                            prepend-inner-icon="mdi-cellphone"
                            name="tel"
                            label="Telephone boutique"
                            type="text"
                            outlined
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="modelselectedabonnement"
                            :items="abonnement"
                            label="Abonnement"
                            return-object
                            outlined
                            :rules="[rules.required]"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="modelselectedperiode"
                            :items="periode"
                            label="Periode"
                            outlined
                            return-object
                            :rules="[rules.required]"
                          >
                            <option value="1">1</option>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-file-input
                            chips
                            accept="image/*"
                            prepend-icon=""
                            prepend-inner-icon="mdi-file"
                            v-model="preuve"
                            label="Preuve de transfert"
                            outlined
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <v-btn
                      :disabled="!isValid"
                      class="mt-4"
                      block
                      color="primary"
                      type="submit"
                      value="register"
                      >{{ $t("registerform.register") }}</v-btn
                    >
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import axios from "axios";
import { APIENDPOINT } from "../../api/app.config";

export default {
  name: "Login",
  components: {
    ErrorSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    show1: false,
    alert: false,
    alert2: false,
    confirm: false,
    preuves: false,
    personaliser: false,
    modelselectedperiode: null,
    modelselectedabonnement: null,
    nbrboutique: null,
    nbrdepot: null,
    nbruser: null,
    facture: 0,
    nom: "",
    prenom: "",
    abonnement: [
      { text: "Bronze", value: 2 },
      { text: "Argent", value: 3 },
      { text: "Personaliser", value: 4 },
    ],
    username: "",
    email: "",
    nomboutique: "",
    adresse: "",
    telephone: "",
    password: "",
    confirmpassword: "",
    tel: "",
    preuve: null,
    periode: [
      { text: "1 ans", value: 365 },
      { text: "2 ans", value: 365 * 2 },
      { text: "3 ans", value: 365 * 3 },
    ],
    value: [],
    options: [],
    // confirmPassword:"",

    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Ce champs est requis",
    },
    nomRules: [(v) => !!v || "Ce champs est requis"],
    prenomRules: [(v) => !!v || "Ce champs est requis"],
    emailRules: [
      (v) => !!v || "E-mail est requis ",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "Adresse Email non valide",
    ],
    usernameRules: [
      (v) => !!v || "Nom d'utilisateur requis",
      (v) =>
        /^(?=.*[a-zA-Z].*)(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(v) ||
        "Nom d'utilisateur non valide",
    ],
    passwordRules: [
      (v) => !!v || "Mots de passe requis",
      (v) =>
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) ||
        "Le mot de passe doit contenir au moins une lettre majuscule, une lettre minuscule, un chiffre, un caractère spécial et contenir au moins 6 caractere",
      // 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
    ],
  }),
  computed: {
    ...mapGetters({
      allDirecteurs: "register/setallUserDirecteur",
    }),
  },
  // mounted() {
  //     this.match()
  // },

  watch: {
    modelselectedabonnement() {
      if (this.modelselectedabonnement.value == 4) {
        this.personaliser = true;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      new: "register/ADD_DIRECTEUR",
      other: "register/ADD_DIRECTEUR_CUSTOM",
    }),

    isValid() {
      return true;
    },

    match() {
      if (this.password == this.confirmpassword) {
        this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
          show: false,
          text: msg2,
        });
        return true;
      } else {
        var msg2 = "Les deux mots de passe doivent etre identique";
        this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
          show: true,
          text: msg2,
        });
        return false;
      }
    },
    async Existemail() {
      if (this.email) {
        await axios
          .get(APIENDPOINT + "/api/auth/check/email/" + this.email)
          .then((response) => {
            if (response.data == true) {
              this.alert = true;
            } else {
              this.alert = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async Existusername() {
      if (this.username) {
        await axios
          .get(APIENDPOINT + "/api/auth/check_username/" + this.username)
          .then((response) => {
            if (response.data == true) {
              this.alert2 = true;
            } else {
              this.alert2 = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    custom() {
      // this.preuve={}
      let users = new FormData();
      users.append("username", this.username.toLowerCase());
      users.append("nom", this.nom);
      users.append("prenom", this.prenom);
      users.append("email", this.email);
      users.append("adresse", this.adresse);
      users.append("nomBoutque", this.nomboutique);
      users.append("telephone", this.telephone);
      users.append("tel", this.tel);
      users.append("password", this.password);
      // users.append("preuve",this.preuve, this.preuve.name)
      users.append("abonnement", this.modelselectedabonnement.value);
      users.append("jours", this.modelselectedperiode.value);
      users.append("boutiques", Number(this.nbrboutique));
      users.append("depots", Number(this.nbrdepot));
      users.append("users", Number(this.nbruser));
      users.append("factures", this.facture);
      this.other(users)
        .then(() => {
          this.confirm = true;
          this.username = null;
          this.nom = null;
          this.prenom = null;
          this.email = null;
          this.adresse = null;
          this.nomboutique = null;
          this.telephone = null;
          this.password = null;
          this.tel = null;
          this.preuve = null;
          this.confirmpassword = null;
          this.modelselectedabonnement = null;
          this.modelselectedperiode = null;
          this.nbrboutique = null;
          this.nbrdepot = null;
          this.nbruser = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      if (this.$refs.formRegister.validate()) {
        if (this.modelselectedabonnement.value == 4) {
          this.custom();
        } else if (this.modelselectedabonnement.value != 4) {
          if (this.preuve != null) {
            console.log(this.preuve);
            let users = new FormData();
            users.append("username", this.username.toLowerCase());
            users.append("nom", this.nom);
            users.append("prenom", this.prenom);
            users.append("email", this.email);
            users.append("adresse", this.adresse);
            users.append("nomBoutque", this.nomboutique);
            users.append("telephone", this.telephone);
            users.append("tel", this.tel);
            users.append("password", this.password);
            users.append("preuve", this.preuve, this.preuve.name);
            users.append("abonnement", this.modelselectedabonnement.value);
            users.append("jours", this.modelselectedperiode.value);
            console.log(users);
            this.new(users)
              .then(() => {
                this.confirm = true;
                this.username = null;
                this.nom = null;
                this.prenom = null;
                this.email = null;
                this.adresse = null;
                this.nomboutique = null;
                this.telephone = null;
                this.password = null;
                this.tel = null;
                this.preuve = null;
                this.confirmpassword = null;
                this.modelselectedabonnement = null;
                this.modelselectedperiode = null;
                this.nbrboutique = null;
                this.nbrdepot = null;
                this.nbruser = null;
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.preuves = true;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.625em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  color: black;
}
.dark h2 {
  color: black !important;
}
.theme--light .v-icon {
  color: black !important;
}
</style>
