<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <LoadingDialog :value="isLoading" />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog v-model="dialog" scrollable persistent max-width="500">
                  
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 300px;">
                      <v-container grid-list-md>
                        <v-form ref="formClient">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.nom"
                                :rules="[v => !!v || $t('client.required1')]"
                                :label="$t('client.name1')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.prenom"
                                :rules="[v => !!v || $t('client.required2')]"
                                :label="$t('client.name2')"
                                required
                              ></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.telephone"
                                :label="$t('client.contact')"
                                :rules="[v => !!v || 'Le numéro de téléphone est obligatoire']"
                                type="number"
                                required
                              ></v-text-field>
                            </v-flex>
                            
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.adresse"
                                :rules="[v => !!v || 'Renseignez l\'adresse du client']"
                                :label="$t('client.address')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{$t('close')}}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{$t('client.deleting')}}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="red darken-1" @click="delete_dialog=false" text>{{$t('no')}}</v-btn>
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                      >{{$t('yes')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
                
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchClient"
                append-icon="mdi-magnify"
                :label="$t('client.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="allClients"
        :search="searchClient"
        sort-by="nom"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{$t('datatable-title.client.listebestclient')}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.clients.nom}}</td>
            <td>{{item.clients.prenom}}</td>
            <td>{{item.clients.telephone}}</td>
            <td>{{item.clients.adresse }}</td>
            <td>{{item.montant }}</td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{$t('facture.nodata')}}</p>
        </template>
      </v-data-table>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import LoadingDialog from "../../components/dialog/LoadingDialog";
import { mask } from "vue-the-mask";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    LoadingDialog,
  },
  directives: {
    mask
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchClient: "",
    dialog: false,
    snackbar: false,
    loading: false,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    //patients: [],
    editedIndex: -1,
    editedItem: {
      idClient: 0,
      boutique: JSON.parse(window.localStorage.getItem('boutiqueId')),
      nom: "",
      prenom: "",
      telephone: "",
      adresse: "",
    },
    defaultItem: {
      idClient: 0,
      nom: "",
      prenom: "",
      telephone: "",
      adresse: ""
    }
  }),

  computed: {
    headers(){
      return [
      {
        text: this.$t('datatable-header.client.nom'),
        align: "left",
        sortable: true,
        value: "clients.nom"
      },
      { text: this.$t('datatable-header.client.prenom'), value: "clients.prenom", sortable: true },
      { text: this.$t('datatable-header.client.contact'), value: "clients.telephone", sortable: true },
      { text: this.$t('datatable-header.client.addresse'), value: "clients.adresse", sortable: true },
      { text: this.$t('datatable-header.client.montant'), value: "nontant", sortable: false }
    ]
    },
    ...mapGetters({
      allClients: "client/getAllClients"
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t('client.save')
        : this.$t('client.update');
    },

    formAction() {
      return this.editedIndex === -1 ? this.$t('client.save1') : this.$t('client.update1');
    },
    
    isLoading() {
      return this.loading;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    createDossierDialog(val) {
      val || this.close();
    }
  },

  created() {
    this.loading = true;
    this.clients();
    this.$store
      .dispatch("client/FETCH_ALL_CLIENTS_M")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {
    ...mapActions({
      clients: "client/FETCH_ALL_CLIENTS_M",
      addNewClient: "client/ADD_CLIENT",
      updateClient: "client/UPDATE_CLIENT",
      deleteClient: "client/DELETE_CLIENT"
    }),

    editItem(item) {
      this.editedIndex = this.allClients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t('client.warning') +
          item.nom +
          " " +
          item.prenom +
          ". " +
          this.$t('client.warning1') +
          this.$t('client.warning2');
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteClient(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t('client.msg');
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
          })
          .catch(() => {
            this.loading = false;
            var msg =
              this.$t('client.msg1');
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    createDossierDialog() {
      if (this.formAction !== this.$t('client.update1')) {
        if (this.$refs.formClient.validate()) {
          this.dialog_save = true;
        }
      } else {
        this.save();
      }
    },

    openCodeDossierDialog() {
      this.dialog_save = false;
    },

    saveWithDossier() {
      this.save();
    },

    async save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        await this.updateClient(this.editedItem)
          .then(() => {
            this.loading = false;
            var msg =
              this.$t('client.success1');
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
          })
          .catch(() => {
            this.loading = false;
            var msg =
              this.$t('client.error1');
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
        this.close();
      } else {
        if (this.$refs.formClient.validate()) {
          this.loading = true;
          const Client = this.editedItem;
          //Client.client = this.editedItem;
          await this.addNewClient(Client)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              var msg = this.$t('client.success');
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg
              });
            })
            .catch(() => {
              this.loading = false;
              var msg =
                this.$t('client.error');
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg
              });
            });
        }
        this.close();
      }
    }
  }
};
</script>
<style>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>