import axios from "axios";
import { BehaviorSubject } from "rxjs";

export const APIENDPOINT = "https://shopymanager.com:8443/s2a-stock";
// "https://shopymanager.com:8443/s2a-stock"
// "http://192.168.1.65:8080/s2a-stock"

const CONNECTED_USER = new BehaviorSubject(
  JSON.parse(window.localStorage.getItem("user"))
);

export const authService = {
  currentUser: CONNECTED_USER.asObservable(),
  get currentUserValue() {
    return CONNECTED_USER.value;
  },
};

export const getHeader = function() {
  const tokenData = JSON.parse(window.localStorage.getItem("conUser"));
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Headers":
      "Origin, Content-Type, Accept, Authorization, X-Request-With",
    Accept: "application/json",
    Authorization: "Bearer" + tokenData.token,
  };
  return headers;
};

export const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    let tokenData = JSON.parse(window.localStorage.getItem("conUser"));

    if (tokenData) {
      config.headers["Authorization"] = `Bearer ${tokenData.token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
