import { APIENDPOINT, instance } from "@/api/app.config";


const state = {
  users: [],
  usersAdmin: [],
  usersDirecteur: [],
  roles: [],
};

const getters = {
  getAllUsers: (state) => state.users,
  getAllRoles: (state) => state.roles,
  allUsersAdmin: (state) => state.usersAdmin,
  allUsersDirecteur: (state) => state.usersDirecteur,
};

const actions = {
  FETCH_ALL_USERS: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/ligne_boutique_boutique_users/" +
            boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const users = response.data;
          commit("ALL_USER", users);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },

  FETCH_USERS_ADMIN: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/users_admin/", {
          timeout: 3000,
        })
        .then(function(response) {
          const users = response.data;
          console.log(users)
          commit("ALL_USERS_ADMIN", users);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },

  FETCH_USERS_DIRECTEUR: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/users_directeur/", {
          timeout: 3000,
        })
        .then(function(response) {
          const users = response.data;
          commit("ALL_USERS_DIRECETEUR", users);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },

  FETCH_ALL_USERS_LIGNE_BOUTIQUES: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/ligne_boutique_current_user/" +
            boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const users = response.data;
          commit("ALL_USER", users);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },
  FETCH_ALL_USERS_DIRECTEUR: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/ligne_boutique_boutique_users/" +
            boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const users = response.data;
          commit("ALL_USER", users);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },

  FETCH_ALL_ROLES: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/roles_directeur", {
          timeout: 3000,
        })
        .then(function(response) {
          const roles = response.data;
          commit("ALL_ROLE", roles);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },

  FETCH_ALL_USER_CURRENT_BOUTIQUE: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/boutique_current_user", {
          timeout: 3000,
        })
        .then(function(response) {
          const users = response.data;
          commit("ALL_USER_BOUTIQUE", users);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },

  ADD_USER_SIMPLE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/users_caisse_a", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const user = response.data;
          commit("NEW_USER", user);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  ADD_USER: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/users_gerant", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const user = response.data;
          commit("NEW_USER", user);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  ADD_USER_DIRECTEUR: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/users_directeur", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const user = response.data;
          commit("NEW_USER_DIRECTEUR", user);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  ADD_USER_ADMIN: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/users_admin", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const user = response.data;
          commit("NEW_USER_ADMIN", user);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  UPDATE_USER: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/users/gerant/" + c.idUser, c)
        .then(function(response) {
          const c_new_value = response.data;
          
          commit("REFRESH_USER", c_new_value);
          resolve();
        })
        .catch(function(err) {
          console.log(err)
          reject(err);
        });
    });
  },
  UPDATE_SHOP: (b) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/users/boutique/" +b.idBoutique +"/"+ b.idUser )
        .then( {
          
        })
        .catch(function(err) {
          console.log(err)
          reject(err);
        });
    });
  },
  UPDATE_PASS: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/update/pass/" + c.idUser, c)
        .then(function(response) {
          const c_new_value = response.data;
         console.log(c_new_value)
          commit("REFRESH_USER", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //.log(err)
          reject(err);
        });
    });
  },

  UPDATE_USER_ADMIN: ( c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/users_admin/" + c.idUser, c)
        .then(function(response) {
          const c_new_value = response.data;
          console.log(c_new_value)
          //commit("REFRESH_USER_ADMIN", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //.log(err)
          reject(err);
        });
    });
  },

  UPDATE_USER_DIRECTEUR: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/users_directeur/" + c.idUser, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_USER_DIRECTEUR", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },
  // eslint-disable-next-line
  ACTIVATE_USER_DIRECTEUR:({}, c) =>
  {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/users/active/" +c.idUser+ '/'+ c.abonnement, c) 
        // eslint-disable-next-line
        .then(function(response)
        {
          resolve()
        })
        .catch(function(err) {
          reject(err)
        })
    })
  },
  DELETE_USER: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/boutiques/" + c.idUser, c)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_USER: (state, users) => {
    state.users = users;
  },

  ALL_USERS_ADMIN: (state, usersAdmin) => {
    state.usersAdmin = usersAdmin;
  },

  ALL_USERS_DIRECETEUR: (state, usersDirecteur) => {
    state.usersDirecteur = usersDirecteur;
  },

  ALL_ROLE: (state, roles) => {
    state.roles = roles;
  },

  ALL_USER_BOUTIQUE: (state, users) => {
    state.users = users;
  },

  NEW_USER: (state, users) => {
    state.users.push(users);
  },

  NEW_USER_DIRECTEUR: (state, usersDirecteur) => {
    state.usersDirecteur.push(usersDirecteur);
  },

  NEW_USER_ADMIN: (state, usersAdmin) => {
    state.usersAdmin.push(usersAdmin);
  },

  REFRESH_USER: (state, c_new_value) => {
    const index = state.users.findIndex((x) => x.idUser == c_new_value.idUser);
    Object.assign(state.users[index], c_new_value);
  },

  REFRESH_USER_ADMIN: (state, c_new_value) => {
    const index = state.usersAdmin.findIndex(
      (x) => x.idUser == c_new_value.idUser
    );
    Object.assign(state.usersAdmin[index], c_new_value);
  },

  REFRESH_USER_DIRECTEUR: (state, c_new_value) => {
    const index = state.usersDirecteur.findIndex(
      (x) => x.idUser == c_new_value.idUser
    );
    Object.assign(state.usersDirecteur[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.users.findIndex((x) => x.idUser == c_del_value.idUser);
    state.users.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
