<template>
  <div id="app">
    <v-app color="primary">
    <MenuToolBar :role="_role" />
     
    </v-app>
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { authService } from "@/api/app.config";
import i18n from "./i18n";
import MenuToolBar from "./components/menu/MenuToolBar.vue";

export default {
  name: "App",
  components: {
    MenuToolBar,
   
  },
  data: () => ({
    model: "tab-2",
    connected_user: null,
    interval: 0,
    time: 10000,
  }),
  computed: {
    
    ...mapGetters(
      {
        _role: "login/getUrole",
      }),

    menuItemsAccueilFn() {
      if (i18n.locale === "en") {
        return this.menuItemsAccueilEN;
      } else {
        return this.menuItemsAccueil;
      }
    },
    menuItemsCaissierFn() {
      if (i18n.locale === "en") {
        return this.menuItemsCaissierEN;
      } else {
        return this.menuItemsCaissier;
      }
    },
    menuItemsVendeurFn() {
      if (i18n.locale === "en") {
        return this.menuItemsVendeurEN;
      } else {
        return this.menuItemsVendeur;
      }
    },
    menuItemsDirectorFn() {
      if (i18n.locale === "en") {
        return this.menuItemsDirectorEN;
      } else {
        return this.menuItemsDirector;
      }
    },
    menuItemsGerantFn() {
      if (i18n.locale === "en") {
        return this.menuItemsGerantEN;
      } else {
        return this.menuItemsGerant;
      }
    },
    menuItemsMagasinierFn() {
      if (i18n.locale === "en") {
        return this.menuItemsMagasinierEN;
      } else {
        return this.menuItemsMagasinier;
      }
    },

    menuItemsAdminFn() {
      if (i18n.locale === "en") {
        return this.menuItemsAdminEN;
      } else {
        return this.menuItemsAdmin;
      }
    },
    isAccueil() {
      return true;
    },
    isAdmin() {
      const isObjectPresent = this._role.find((o) => o.idRole === 1);
      return isObjectPresent;
    },
    isDirector() {
      const isObjectPresent = this._role.find((o) => o.idRole === 2);
      return isObjectPresent;
    },
    isGerant() {
      const isObjectPresent = this._role.find((o) => o.idRole === 3);
      return isObjectPresent;
    },
    isVendeur() {
      const isObjectPresent = this._role.find((o) => o.idRole === 4);
      return isObjectPresent;
    },
    isCaissier() {
      const isObjectPresent = this._role.find((o) => o.idRole === 5);
      return isObjectPresent;
    },
    isVendeurCaisse() {
      if (this.isVendeur && this.isCaissier) return true;
      else return false;
    },
    isVendeurCaisseDirecteur() {
      if (this.isVendeur && this.isCaissier && this.isDirector) return true;
      else return false;
    },
    isMagasinier() {
      const isObjectPresent = this._role.find((o) => o.idRole === 6);
      return isObjectPresent;
    },
    // islanding()
    // {
    // return console.log(window.location.href)  
    // },
    year() {
      return moment().year();
    },
    lang() {
      return this.$route.params.lang;
    },

    menuItemsAccueil() {
      return [
        {
          title: "Accueil",
          icon: "mdi-home",
          to: "/" + this.lang + "/app/accueil",
        },
      ];
    },

    menuItemsAccueilEN() {
      return [
        {
          title: "Home",
          icon: "mdi-home",
          to: "/" + this.lang + "/app/accueil",
        },
      ];
    },

    menuItemsMagasinier() {
      return [
        {
          title: "MAGASINS",
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: "FAIRE UNE LIVRAISONS",
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-livtaison",
            },
            {
              title: "MES LIVRAISONS",
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/livtaison",
            },
            {
              title: "RECEPTIONNER UN ARTICLE",
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/reception",
            },
          ],
        },
      ];
    },
    menuItemsMagasinierEN() {
      return [
        {
          title: "STORES",
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: "DO DELIVERIES",
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-livtaison",
            },
            {
              title: "MY DELIVERIES",
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/livtaison",
            },
            {
              title: "RECEIVE AN ITEM",
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/reception",
            },
          ],
        },
      ];
    },

    menuItemsVendeur() {
      return [
        {
          title: "Facture",
          icon: "mdi-file-document-multiple",
          to: "/" + this.lang + "/app/facture",
        },
        {
          title: "Clients",
          icon: "mdi-account-group",
          to: "/" + this.lang + "/app/client",
        },
      ];
    },

    menuItemsVendeurEN() {
      return [
        {
          title: "Bill",
          icon: "mdi-file-document-multiple",
          to: "/" + this.lang + "/app/facture",
        },
        {
          title: "Customers",
          icon: "mdi-account-group",
          to: "/" + this.lang + "/app/client",
        },
      ];
    },

    menuItemsCaissier() {
      return [
        {
          title: "Caisse",
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: "Caisse",
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-non-solde",
            },
            {
              title: "VENTE CAISSIERE",
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/facture-caissier",
            },
          ],
        },
      ];
    },

    menuItemsCaissierEN() {
      return [
        {
          title: "Cash register",
          icon: "mdi-cash-register",
          sub_menu: [
            {
              title: "CASH REGISTER",
              icon: "mdi-cart-minus",
              to: "/" + this.lang + "/app/facture-non-solde",
            },
            {
              title: "SELLNG CASH REGISTER",
              icon: "mdi-cart-arrow-down",
              to: "/" + i18n.locale + "/app/facture-caissier",
            },
          ],
        },
      ];
    },
    menuItemsGerant() {
      return [
        {
          title: "Articles",
          icon: "mdi-cart",
          sub_menu: [
            {
              title: "Listes des articles",
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article",
            },
            {
              title: "Imprimer les articles",
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-categorie",
            },
            {
              title: "categories",
              icon: "mdi-cart-plus",
              to: "/" + i18n.locale + "/app/categorie",
            },
            {
              title: "Fournisseurs",
              icon: "mdi-car-multiple",
              to: "/" + i18n.locale + "/app/fournisseur",
            },
          ],
        },
      ];
    },
    menuItemsGerantEN() {
      return [
        {
          title: "Items",
          icon: "mdi-cart",
          sub_menu: [
            {
              title: "Items List",
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article",
            },
            {
              title: "Print items",
              icon: "mdi-cart-minus",
              to: "/" + i18n.locale + "/app/article-categorie",
            },
            {
              title: "category",
              icon: "mdi-cart-plus",
              to: "/" + i18n.locale + "/app/categorie",
            },
            {
              title: "Furnishers",
              icon: "mdi-car-multiple",
              to: "/" + i18n.locale + "/app/fournisseur",
            },
          ],
        },
      ];
    },
    menuItemsDirector: () => [
      {
        title: "Boutique",
        icon: "mdi-store",
        sub_menu: [
          {
            title: "Nouvelle boutique",
            icon: "mdi-storefront",
            to: "/" + i18n.locale + "/app/boutique",
          },
          {
            title: "Liste des depots",
            icon: "mdi-clipboard-text-outline",
            to: "/" + i18n.locale + "/app/depot",
          },
          {
            title: "Mouvement des articles",
            icon: "mdi-cart-arrow-right",
            to: "/" + i18n.locale + "/app/mouvement-articles",
          },
          {
            title: "Type de facture",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/type-facture",
          },
          {
            title: "Meilleur Client",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/client-meilleur",
          },
          {
            title: "Utilisateurs",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/utilisateur-boutique",
          },
          {
            title: "Ajout utilisateur depot",
            icon: "mdi-cart-arrow-down",
            to: "/" + i18n.locale + "/app/depot-user",
          },
          {
            title: "Raport",
            icon: "mdi-cart-arrow-down",
            to: "/" + i18n.locale + "/app/raport",
          },
        ],
      },
    ],
    menuItemsDirectorEN: () => [
      {
        title: "SHOP",
        icon: "mdi-store",
        sub_menu: [
          {
            title: "New  shop",
            icon: "mdi-storefront",
            to: "/" + i18n.locale + "/app/boutique",
          },
          {
            title: "Store list",
            icon: "mdi-clipboard-text-outline",
            to: "/" + i18n.locale + "/app/depot",
          },
          {
            title: "Item movement",
            icon: "mdi-cart-arrow-right",
            to: "/" + i18n.locale + "/app/mouvement-articles",
          },
          {
            title: "Type of bill",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/type-facture",
          },
          {
            title: "Best Customer",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/client-meilleur",
          },
          {
            title: "Users",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/utilisateur-boutique",
          },
          {
            title: "Add user to store",
            icon: "mdi-cart-arrow-down",
            to: "/" + i18n.locale + "/app/depot-user",
          },
          {
            title: "Raport",
            icon: "mdi-cart-arrow-down",
            to: "/" + i18n.locale + "/app/raport",
          },
        ],
      },
    ],

    menuItemsAdmin: () => [
      {
        title: "Parametre",
        icon: "mdi-store",
        sub_menu: [
          {
            title: "Users admin",
            icon: "mdi-storefront",
            to: "/" + i18n.locale + "/app/utilisateur-admin",
          },
          {
            title: "Users director",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/utilisateur-directeur",
          },
          {
            title: "Activate Users director",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/utilisateur-directeurs",
          },
        ],
      },
    ],
    menuItemsAdminEN: () => [
      {
        title: "Setting",
        icon: "mdi-store",
        sub_menu: [
          {
            title: "Users admin",
            icon: "mdi-storefront",
            to: "/" + i18n.locale + "/app/utilisateur-admin",
          },
          {
            title: "Users directeur",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/utilisateur-directeur",
          },
          {
            title: "Activate Users director",
            icon: "mdi-clipboard-text",
            to: "/" + i18n.locale + "/app/utilisateur-directeurs",
          },
        ],
      },
    ],
  },
  created() {
    this.inactivityTime();
    this.initUser();
    // this.boutique();
    authService.currentUser.subscribe((x) => (this.connected_user = x));
  },
  mounted() {
    
    this.inactivityTime();
  },
  methods: {
    ...mapActions({
      initUser: "login/LOGIN_AUTH_ROLE",
      // boutique: JSON.parse(window.localStorage.getItem("boutiqueId")),
    }),
    inactivityTime() {
      document.onload = this.resetTimer;
      document.onmousemove = this.resetTimer;
      document.onscroll = this.resetTimer;
      document.onkeypress = this.resetTimer;
      document.onmousedown = this.resetTimer;
      document.onmouseenter = this.resetTimer;
      document.onclick = this.resetTimer;
      document.ontouchstart = this.resetTimer;
    },
    logout() {
      this.$store.dispatch("login/LOGOUT_AUTH");
      this.$router.push(`/${i18n.locale}/login`).catch(() => {});
    },
    resetTimer() {
      clearTimeout(this.interval);
      this.interval = setTimeout(this.countDown, 3600000); // 1h
    },
    countDown() {
      clearTimeout(this.interval);
      this.logout();
    },
    changedtofr: function() {
      i18n.locale = "fr";

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },

    changedtoen: function() {
      i18n.locale = "en";
      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
  },
};
</script>
<style lang="css" scoped>
#sub {
  background-color: aqua;
}

#content {
  background-color: #f8f9fa;
}
</style>
