import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  articles: [],
  entrees: [],
  entree: {},
};

const getters = {
  getAllArticles: (state) => state.articles,
  getAllStockByBoutique: (state) => state.entrees,
  getAllQuantiteSortieByBoutique: (state) => state.articles,
  getOneEntree: (state) => state.entree,
};

const actions = {
  FETCH_ALL_ARTICLES: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/entree/articles/depot/user/" +
            boutiqueId.idBoutique,
          {
            timeout: 30000,
          }
        )
        .then(function(response) {
          const articles = response.data;
          commit("ALL_ARTICLES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_ARTICLES_STOCK: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/ligne_article_boutique1/" + boutiqueId.idBoutique,
          {
            timeout: 30000,
          }
        )
        .then(function(response) {
          const articles = response.data;
          commit("ALL_ARTICLES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_ARTICLES_BY_STOCK: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    //console.log(boutiqueId);
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/entrees_boutique/" + boutiqueId.idBoutique, {
          timeout: 30000,
        })
        .then(function(response) {
          const articles = response.data;

          commit("ALL_ARTICLES_ENTREES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_ARTICLES_SORTIE_BY_BOUTIQUE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    console.log(boutiqueId);
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/sorties_boutique/" + boutiqueId.idBoutique, {
          timeout: 30000,
        })
        .then(function(response) {
          const articles = response.data;
          commit("ALL_ARTICLES", articles);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ONE_ENTREE_LIGNE: ({ commit }, {idArticle, idDepot}) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    console.log(boutiqueId);
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/entrees_article/" +
            boutiqueId.idBoutique +
            "/" +
            idArticle+"/" +idDepot,
          {
            timeout: 30000,
          }
        )
        .then(function(response) {
          const entree = response.data;
          commit("ONE_ENTREE", entree);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_ARTICLES: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/articles_all", article, {
          timeout: 3000,
        })
        .then(function(response) {
          const article = response.data;
          commit("NEW_ARTICLE", article);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_ARTICLE_STOCK: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/ajouter", article, {
          timeout: 3000,
        })
        .then(function(response) {
          const article = response.data;
          commit("NEW_ARTICLE_STOCK", article);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_ARTICLE_STOCK2: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/ajouter2", article, {
          timeout: 3000,
        })
        .then(function(response) {
          const article = response.data;
          commit("REFRESH_ARTICLE_STOCK2", article);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  UPDATE_ARTICLES: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/articles/" + article.idArticle, article, {
          timeout: 3000,
        })
        .then(function(response) {
          const an_new_value = response.data;
          commit("REFRESH_ARTICLE", an_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_ARTICLES: ({ commit }, article) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/articles/" + article.idArticle, {
          timeout: 3000,
        })
        .then(function() {
          commit("REFRESH_DELETE", article);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_ARTICLES: (state, articles) => {
    state.articles = articles;
  },

  ALL_ARTICLES_ENTREES: (state, entree) => {
    state.entrees = entree;
  },

  ONE_ENTREE: (state, entree) => {
    state.entree = entree;
  },

  NEW_ARTICLE: (state, article) => {
    state.articles.push(article);
  },

  NEW_ARTICLE_STOCK: (state, an_new_value) => {
    const index = state.articles.findIndex(
      (x) => x.idEntree == an_new_value.idEntree
    );
    Object.assign(state.articles[index], an_new_value);
  },

  REFRESH_ARTICLE: (state, an_new_value) => {
    const index = state.articles.findIndex(
      (x) => x.idArticle == an_new_value.idArticle
    );
    Object.assign(state.articles[index], an_new_value);
  },

  REFRESH_ARTICLE_STOCK2: (state, c_new_value) => {
    const index = state.entrees.findIndex(
      (x) => x.idEntree == c_new_value.idEntree
    );
    Object.assign(state.entrees[index], c_new_value);
  },

  REFRESH_DELETE: (state, an_del_value) => {
    const index = state.articles.findIndex(
      (x) => x.idArticle == an_del_value.idArticle
    );
    state.articles.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
