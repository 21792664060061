import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  caisses: [],
};

const getters = {
  getAllCaisses: (state) => state.caisses,
};

const actions = {
  FETCH_ALL_CAISSES: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/caisses_boutique/" + boutiqueId.idBoutique, {
          timeout: 3000,
        })
        .then(function(response) {
          const caisses = response.data;
          commit("ALL_CAISSE", caisses);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_CAISSE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/caisses", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const caisse = response.data;
          commit("NEW_CAISSE", caisse);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  UPDATE_CAISSE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/caisses/" + c.idCaisse, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_CAISSE", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_CAISSE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/caisses/" + c.idCaisse)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_CAISSE: (state, caisses) => {
    state.caisses = caisses;
  },

  NEW_CAISSE: (state, caisse) => {
    state.caisses.push(caisse);
  },

  REFRESH_CAISSE: (state, c_new_value) => {
    const index = state.caisses.findIndex(
      (x) => x.idCaisse == c_new_value.idCaisse
    );
    Object.assign(state.caisses[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.caisses.findIndex(
      (x) => x.idCaisse == c_del_value.idCaisse
    );
    state.caisses.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
