import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  receptions: [],
};

const getters = {
  getAllReceptions: (state) => state.receptions,
};

const actions = {
  FETCH_ALL_RECEPTIONS: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/reception_not_deleted", {
          timeout: 3000,
        })
        .then(function(response) {
          const receptions = response.data;
          commit("ALL_RECEPTIONS", receptions);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  FETCH_ALL_RECEPTIONS_BOUTIQUE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/reception_boutique/" + boutiqueId.idBoutique, {
          timeout: 3000,
        })
        .then(function(response) {
          const receptions = response.data;
          commit("ALL_RECEPTIONS", receptions);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  FETCH_ALL_RECEPTIONS_BOUTIQUE_USER: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/reception_boutique_user/" + boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const receptions = response.data;
          commit("ALL_RECEPTIONS", receptions);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  ADD_RECEPTION: ({ commit }, items) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/reception", items, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          const f = "nothing";
          commit("NEW_RECEPTION", f);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          resolve();
        })
        .catch(function(err) {
          //console.log("Error:"+err)
          reject(err);
        });
    });
  },

  ADD_RECEPTION1: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/reception", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const reception = response.data;
          commit("NEW_RECEPTION", reception);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  UPDATE_RECEPTION: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/reception/" + c.idReception, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_RECEPTION", c_new_value);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  DELETE_RECEPTION: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/reception/" + c.idReception)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_RECEPTIONS: (state, receptions) => {
    state.receptions = receptions;
  },

  NEW_RECEPTION: (state, reception) => {
    state.receptions.push(reception);
  },

  REFRESH_RECEPTION: (state, c_new_value) => {
    const index = state.receptions.findIndex(
      (x) => x.idReception == c_new_value.idReception
    );
    Object.assign(state.receptions[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.receptions.findIndex(
      (x) => x.idReception == c_del_value.idReception
    );
    state.receptions.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
