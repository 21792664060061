<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog
                  v-model="dialog"
                  scrollable
                  persistent
                  max-width="550"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 290px;">
                      <v-container grid-list-md>
                        <v-form ref="formArticle">
                          <v-layout wrap>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.name") }}:
                                <h3 class="red--text">
                                  {{
                                    showItem.ligneArticle.articlesPK.article
                                      .designation
                                  }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.prix") }}:
                                <h3 class="red--text">
                                  {{
                                    showItem.ligneArticle.articlesPK.article
                                      .prix
                                  }}
                                  FCFA
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.cat") }}:
                                <h3 class="red--text" id="total" name="total">
                                  {{
                                    showItem.ligneArticle.articlesPK.article
                                      .categorie.libelle
                                  }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.date") }}:
                                <h3 class="red--text" id="total" name="total">
                                  {{ convertDate(showItem.createdAt) }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.quantity") }}:
                                <h3 class="red--text" id="total" name="total">
                                  {{ showItem.quantite }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col v-if="showItem.user" class="col-12 mb-2">
                              <span>
                                {{ $t("login.name") }}:
                                <h3 class="red--text" id="total" name="total">
                                  {{ showItem.user.nom }}
                                  {{ showItem.user.prenom }}
                                </h3>
                              </span>
                            </v-col>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchInventaire"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('item.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchInventaire"
        sort-by="designation"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("item.released") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.ligneArticle.articlesPK.article.designation }}</td>
            <td>{{ item.ligneArticle.articlesPK.article.prix }} FCFA</td>
            <td>
              {{ item.ligneArticle.articlesPK.article.categorie.libelle }}
            </td>
            <td>{{ item.libelle }}</td>
            <td>{{ item.quantite }}</td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-eye</v-icon
              >
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <div class="mt-4">
        <v-pagination
          v-if="searchInventaire"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import { APIENDPOINT } from "@/api/app.config";
import moment from "moment";
import i18n from "../../i18n";
import axios from "axios";
import { mask } from "vue-the-mask";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchInventaire: "",
    inventaire: {},
    current_pag: 1,
    search: null,
    dialog: false,
    snackbar: false,
    loading: false,
    dialog_save: false,
    delete_dialog: false,
    modelSelectedInventaire: null,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    showItem: {
      idInventaire: 0,
      ligneArticle: {
        articlesPK: {
          article: {
            designation: "",
            prix: "",
            categorie: {
              libelle: "",
            },
            description: "",
          },
        },
      },
      quantite: 0,
    },
    editedIndex: -1,
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("item.name"),
          align: "left",
          sortable: true,
          value: "ligneArticle.articlesPK.article.designation",
        },
        { text: this.$t("item.prix"), value: "prix" },
        { text: this.$t("item.cat"), value: "libelle" },
        { text: this.$t("item.desc"), value: "description" },
        { text: this.$t("item.quantity"), value: "quantite" },
        { text: "Actions", value: "action", sortable: false },
      ];
    },

    total_page() {
      if (this.inventaire && this.inventaire.last_page) {
        return this.inventaire.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.inventaire != null && this.inventaire.data) {
        return this.inventaire.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.inventaire && this.inventaire.last_page) {
        return this.inventaire.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      allMouvementArticles: "inventaire/getAllListeMouvementArticle",
    }),

    formTitle() {
      return this.$t("item.movement");
    },

    item() {
      return this.allMouvementArticles.map((boutique) => {
        const noms = boutique.nom;
        return Object.assign({}, boutique, { noms });
      });
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("item.save1")
        : this.$t("item.update1");
    },
    isLoading() {
      return this.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    createDossierDialog(val) {
      val || this.close();
    },
    search() {
      // Items have already been loaded
      if (this.item.length > 0) return;
      this.allMouvementArticles;
    },
  },

  created() {
    this.loading = true;
    this.inventaires();
    this.$store
      .dispatch("inventaire/FETCH_ALL_MOUVEMENT_ARTICLE")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  mounted() {
    this.axiosList();
  },
  methods: {
    ...mapActions({
      inventaires: "inventaire/FETCH_ALL_MOUVEMENT_ARTICLE",
    }),

    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/inventaire/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.inventaire = response.data;
            console.log(this.inventaire.data);
            if (this.inventaire.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchInventaire) {
        await axios
          .get(
            APIENDPOINT +
              "/api/inventaire/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchInventaire,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.inventaire = response.data;

              if (this.inventaire.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        moment.locale("en");
        convert_date = moment(String(date)).format("YYYY-MM-DD hh:mm:ss");
        // convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        moment.locale("fr");
        convert_date = moment(String(date)).format("YYYY-MM-DD à HH:mm:ss");
        // convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    editItem(item) {
      this.editedIndex = this.allMouvementArticles.indexOf(item);
      this.showItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.modelSelectedInventaire = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
