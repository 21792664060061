import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  inventaires: [],
};

const getters = {
  getAllInventaires: (state) => state.inventaires,
  getAllListeMouvementArticle: (state) => state.inventaires,
};

const actions = {
  FETCH_ALL_INVENTAIRES: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/inventaires_not_deleted", {
          timeout: 3000,
        })
        .then(function(response) {
          const inventaires = response.data;
          commit("ALL_INVENTAIRE", inventaires);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_MOUVEMENT_ARTICLE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/inventaires_boutique/" + boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const inventaires = response.data;
          commit("ALL_INVENTAIRE", inventaires);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_INVENTAIRE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/inventaires", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const inventaire = response.data;
          commit("NEW_INVENTAIRE", inventaire);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  UPDATE_INVENTAIRE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/inventaires/" + c.idInventaire, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_INVENTAIRE", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_INVENTAIRE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/inventaires/" + c.idInventaire)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_INVENTAIRE: (state, inventaires) => {
    state.inventaires = inventaires;
  },

  ALL_USER_INVENTAIRE: (state, inventaires) => {
    state.inventaires = inventaires;
  },

  NEW_INVENTAIRE: (state, inventaire) => {
    state.inventaires.push(inventaire);
  },

  REFRESH_INVENTAIRE: (state, c_new_value) => {
    const index = state.inventaires.findIndex(
      (x) => x.idInventaire == c_new_value.idInventaire
    );
    Object.assign(state.inventaires[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.inventaires.findIndex(
      (x) => x.idInventaire == c_del_value.idInventaire
    );
    state.inventaires.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
