<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- <LoadingDialog :value="Loading" /> -->
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog
                  v-model="dialog"
                  scrollable
                  persistent
                  max-width="700"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                    >
                      <v-icon left>mdi-plus-circle-outline</v-icon
                      >{{ $t("item.new") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 510px">
                      <v-container grid-list-md>
                        <v-form ref="formCaisse">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.article.designation"
                                :rules="[(v) => !!v || $t('item.err1')]"
                                :label="$t('item.name')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.article.prix"
                                :rules="[(v) => !!v || $t('item.err2')]"
                                type="number"
                                :label="$t('item.prix')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.article.prixAchat"
                                :rules="[(v) => !!v || $t('item.err2')]"
                                type="number"
                                :label="$t('item.prixAchat')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.stockMin"
                                :rules="[(v) => !!v || $t('item.err3')]"
                                type="number"
                                :label="$t('item.stockMin')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.quantiteEntree"
                                :rules="[(v) => !!v || $t('item.err4')]"
                                type="number"
                                :label="$t('item.quantite')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedCategorie"
                                :items="itemC"
                                :search-input.sync="searchC"
                                item-text="libelle"
                                item-value="idCategorie"
                                :label="$t('item.cat')"
                                :placeholder="$t('item.choose1')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedFournisseur"
                                :items="itemF"
                                :search-input.sync="searchF"
                                item-text="raisonSociale"
                                item-value="idFournisseur"
                                :label="$t('item.furnisher')"
                                :placeholder="$t('item.choose2')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="itemD"
                                :search-input.sync="searchD"
                                item-text="libelle"
                                item-value="idDepot"
                                :label="$t('item.depot')"
                                :placeholder="$t('item.choose2')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.article.description"
                                :label="$t('item.desc')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialogGerent"
                  scrollable
                  persistent
                  max-width="700"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 510px">
                      <v-container grid-list-md>
                        <v-form ref="formCaisse">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.article.designation"
                                :rules="[(v) => !!v || $t('item.err1')]"
                                :label="$t('item.name')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.article.prix"
                                :rules="[(v) => !!v || $t('item.err2')]"
                                type="number"
                                :label="$t('item.prix')"
                                disabled
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.article.prixAchat"
                                :rules="[(v) => !!v || $t('item.err2')]"
                                type="number"
                                :label="$t('item.prixAchat')"
                                disabled
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.stockMin"
                                :rules="[(v) => !!v || $t('item.err3')]"
                                type="number"
                                :label="$t('item.stockMin')"
                                disabled
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.quantiteEntree"
                                :rules="[(v) => !!v || $t('item.err4')]"
                                type="number"
                                :label="$t('item.quantite')"
                                disabled
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedCategorie"
                                :items="itemC"
                                :search-input.sync="searchC"
                                item-text="libelle"
                                item-value="idCategorie"
                                :label="$t('item.cat')"
                                :placeholder="$t('item.choose1')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedFournisseur"
                                :items="itemF"
                                :search-input.sync="searchF"
                                item-text="raisonSociale"
                                item-value="idFournisseur"
                                :label="$t('item.furnisher')"
                                :placeholder="$t('item.choose2')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="itemD"
                                :search-input.sync="searchD"
                                item-text="libelle"
                                item-value="idDepot"
                                :label="$t('item.depot')"
                                :placeholder="$t('item.choose2')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.article.description"
                                :label="$t('item.desc')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="closeGerent"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début dialog de payement d'une facture -->
                <v-dialog
                  v-model="dialogView"
                  scrollable
                  persistent
                  max-width="500"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("item.update2")
                      }}</span>
                    </v-card-title>

                    <v-card-text style="height: 300px">
                      <v-container grid-list-md>
                        <v-form ref="formPayer">
                          <v-layout wrap>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.name") }}:
                                <h2 class="red--text" id="total" name="total">
                                  {{ showItem.article.designation }}
                                </h2>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("item.quantite") }}:
                                <h2 class="red--text" id="total" name="total">
                                  {{ showItem.quantiteEntree }}
                                </h2>
                              </span>
                            </v-col>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="matchedDepot"
                                item-text="libelle"
                                item-value="idDepot"
                                :label="$t('item.depot')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.quantite"
                                :rules="[(v) => !!v || $t('bill.remitted')]"
                                type="number"
                                :label="$t('item.quantite')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="closeStock"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="saveA"
                        :disabled="isValidStock"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ $t("item.save1") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog de payement d'une facture -->

                <!-- Debut dialog d'affichage des details de l'article -->
                <v-dialog
                  v-model="dialogDetaile"
                  scrollable
                  persistent
                  max-width="600"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("item.detail")
                      }}</span>
                    </v-card-title>
                    <v-card-title>
                      <div class="text-h4 mb-2">
                        {{ showItem.article.designation }}
                      </div>
                    </v-card-title>
                    <v-card-title>
                      <div class="text-h6 font-weight-regular grey--text">
                        {{ showItem.article.description }}
                      </div>
                    </v-card-title>
                    <v-card-title>
                      <div class="text-h6 font-weight-regular grey--text">
                        Prix achat:
                        <v-chip class="mr-2" @click="lights">
                          <v-icon left>
                            mdi-cash-100
                          </v-icon>
                          {{ showItem.article.prixAchat }} FCFA
                        </v-chip>
                        Prix vente:
                        <v-chip class="mr-2" @click="lights">
                          <v-icon left>
                            mdi-cash-100
                          </v-icon>
                          {{ showItem.article.prix }} FCFA
                        </v-chip>
                      </div>
                    </v-card-title>

                    <v-divider class="mt-6 mx-4"></v-divider>

                    <v-card-text>
                      <v-chip class="mr-2 mt-2" @click="lights">
                        <v-icon left>
                          mdi-alarm-plus
                        </v-icon>
                        {{
                          $t("item.create") +
                            convertDate(showItem.article.createdAt)
                        }}
                      </v-chip>
                      <v-chip class="mr-2 mt-2" @click="alarm">
                        <v-icon left>
                          mdi-alarm-check
                        </v-icon>
                        {{
                          $t("item.updated") +
                            convertDate(showItem.article.updatedAt)
                        }}
                      </v-chip>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="closeDetails"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog d'affichage des details de l'article -->

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{
                      $t("deleting")
                    }}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="red darken-1"
                        @click="delete_dialog = false"
                        text
                        >{{ $t("no") }}</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                        >{{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model.lazy="searchArticle"
                append-icon="mdi-magnify"
                :label="$t('item.search')"
                @input="axiosSearch"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
    >
      <v-data-table
        disable-filtering
        disable-pagination
        disable-sort
        mobile-breakpoint=600
        item-key="id"
        :loading="loading"
        :headers="headers"
        :items="itemList"
        :search="searchArticle"
        sort-by="nom"
         hide-default-footer
        class="elevation-1"
        :items-per-page="-1"
        :footer-props="{
          itemsPerPageOptions: [],
          showCurrentPage: true,
          showFirstLastPage: true,
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("datatable-title.article.listarticles") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.article.designation }}</td>
            <td>{{ item.article.prix }}</td>
            <!--<td>{{ item.article.prixAchat }}</td>-->
            <td>{{ item.article.categorie.libelle }}</td>
            <td>{{ item.article.fournisseur.raisonSociale }}</td>
            <td>{{ item.depot.libelle }}</td>
            <td>{{ item.quantiteEntree }}</td>
            <td>{{ item.quantiteSortie }}</td>
            <td>
              <v-icon
                v-if="isDirector || isDirectorGerent"
                small
                class="mr-2"
                @click="showDetail(item)"
                >mdi-eye</v-icon
              >
              <v-tooltip bottom>
                <template
                  v-if="isDirector || isDirectorGerent"
                  v-slot:activator="{ on }"
                >
                  <v-icon small class="mr-2" @click="editItem(item)" v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <template v-else v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItemGerent(item)"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>{{ $t("item.update3") }}</span>
              </v-tooltip>
              <v-icon
                v-if="isDirector || isDirectorGerent"
                small
                class="mr-2"
                @click="deleteItem(item, 0)"
                >mdi-delete</v-icon
              >
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <!-- <div class="text-center mt-4">
        <Pagination
            v-if="searchArticle"
            :byId="searchArticle"
            store="articles"
            collection="paginates"
            action="SEARCH_ALL_ARTICLES_PAGINATE"
            :disabled="Loading"
            class="mb-2 mt-2"
           
          />
        <Pagination
            v-else
            store="articles"
            collection="paginates"
            action="FETCH_ALL_ARTICLES_PAGINATE"
            :disabled="Loading"
            class="mb-2 mt-2"
          />
      </div> -->
       <div class="text-center mt-4">

         <v-pagination
      v-if="searchArticle"
       v-model="current_pag"
                    :total-visible="5"
                    :length="total_page"
                    @input="axiosSearch"
                    circle>
      </v-pagination>
      <v-pagination 
      v-else
      v-model="current_pag"
                    :total-visible="5"
                    :length="total_page"
                    @input="axiosList"
                    circle>
      </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
// import LoadingDialog from "../../components/dialog/LoadingDialog";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
// import Pagination from "../../components/Pagination.vue"
import moment from "moment";
import { mask } from "vue-the-mask";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    // Pagination,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    alarm:"",
    lights:"",
    storage: window.localStorage,
    searchArticle: "",
    searchC: "",
    searchF: "",
    searchD: "",
    article:{},
    current_pag:1,
    dialog: false,
    dialogView: false,
    dialogDetaile: false,
    dialogGerent: false,
    snackbar: false,
    loading: false,
    search: null,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    modelSelectedCategorie: null,
    modelSelectedFournisseur: null,
    modelSelectedDepot: null,
    delete_item: null,
    valid: true,
    select: null,
    editedIndex: -1,
    showItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },
    editedItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },

    defaultItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },
  }),


  computed: {
    headers() {
      return [
        {
          text: this.$t("item.name"),
          align: "left",
          sortable: true,
          value: "article.designation",
        },
        { text: this.$t("datatable-header.article.prix"), value: "prix" },
        // { text: this.$t("item.prixAchat"), value: "prixAchat" },
        { text: this.$t("datatable-header.article.categorie"), value: "article.categorie.libelle" },
        { text: this.$t("datatable-header.article.fournisseur"), value: "article.fournisseur.raisonSociale" },
        { text: this.$t("datatable-header.article.depot"), value: "depot.libelle" },
        { text: this.$t("datatable-header.article.quantiteEntree"), value: "quantiteEntree" },
        { text: this.$t("datatable-header.article.quantiteSortie"), value: "quantiteSortie" },
       
      ];
    },
    
    total_page()
    {
      if(this.article && this.article.last_page){

      return this.article.last_page
             } else
       { return 0
       }
    },
    itemList()
    {
      if (this.article != null && this.article.data) 
      {
        return this.article.data  
      }
      else
      {
        return []
      }
    },
    lastpage(){

      if(this.article && this.article.last_page){
        return this.article.last_page;
       } else
       { return 0
       }
    },
  
    ...mapGetters({
      gettoken:"login/gettoken",
      allArticles: "articles/getAllArticles",
      allCategories: "categorie/getAllCategories",
      allPaginates:"articles/getAllPaginates",
      allFournisseurs: "fournisseur/getAllFournisseurs",
      allDepots: "depot/getAllDepots",
    }),

  },
  mounted()
  {
     this.axiosList()
  },
  methods: {
    ...mapActions({
      depots: "depot/FETCH_ALL_DEPOTS",
      categories: "categorie/FETCH_ALL_CATEGORIES",
      fournisseurs: "fournisseur/FETCH_ALL_FOURNISSEURS",
      articles: "articles/FETCH_ALL_ARTICLES_STOCK",
    // articlesPaginate:"articles/FETCH_ALL_ARTICLES_PAGINATE",
      addNewArticle: "articles/ADD_ARTICLE",
      addNewStockArticle: "articles/ADD_ARTICLE_STOCK",
      addNewStockArticle1: "articles/ADD_ARTICLE_STOCK1",
      updateArticle: "articles/UPDATE_ARTICLE",
      deleteArticle: "articles/DELETE_ARTICLE",
    }),
   async axiosList()
    {
      this.loading=true
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"))
      let token= JSON.parse(this.gettoken)
      await  axios.get(APIENDPOINT+"/api/entree/boutique/stock/page/"+boutiqueId.idBoutique+"/"+this.current_pag,
      { headers: {
                Authorization: `Bearer ${token.token} `,
            }})
      .then((response) =>{
        if(response.data){
          
        this.article=response.data
        if(this.article.current_page){
        this.current_pag = response.data.current_page
       } else
       { this.current_pag= 1
       }
        } else{
          this.current_pag= 1
        }

        
      
      })
       .catch((error =>
        {
          console.log(error)
        }))
      this.loading=false
    },
   async axiosSearch()
    {        
      this.loading=false
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"))

      let token= JSON.parse(this.gettoken)
      if(this.searchArticle){

        await axios.get(APIENDPOINT+"/api/search/entree/boutique/stock/page/"+boutiqueId.idBoutique+"/"+this.current_pag+"/"+this.searchArticle,
        { headers: {
                  Authorization: `Bearer ${token.token} `,
              }})
        .then((response) =>{
          if(response.data){
          
        this.article=response.data
        
        if(this.article.current_page){
        this.current_pag = response.data.current_page
       } 
       else
       { 
         this.current_pag= 1
       }
        }
         else{
          this.current_pag= 1
         }
        })
         .catch((error =>
          {
            console.log(error)
          }))
      } else {
          this.axiosList()

      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    
  },
};
</script>
<style>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>