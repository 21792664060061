<template>
  <v-container>
    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <v-layout>
            <v-row class="">
              <template class="mt-3">
                <div class=" text-center col-md-4 col-lg-4 col-sm-6 col-xl-4"
                  v-for=" item in evaluation" :key="item.id"
                >
                  <CardNum
                  :libelle="item.depot.libelle"
                    :name="$t('dashboard.evaluation')"
                    icon="mdi-cash-multiple"
                    color="blue dark-1"
                    :nbr="item.montantStock"
                    quantification="Fcfa"
                  />
                </div>
                 <div class=" text-center col-md-4 col-lg-4 col-sm-6 col-xl-4">
                  <CardNum
                  libelle=""
                    :name="$t('dashboard.nbrCmd')"
                    icon="mdi-clipboard-edit-outline"
                    color="blue dark-1"
                    :nbr="cmdBoutJour"
                  />
                </div>
                <div class=" text-center col-md-4 col-lg-4 col-sm-6 col-xl-4">
                  <CardNum
                  libelle=""
                    :name="$t('dashboard.payments')"
                    icon="mdi-cash-register"
                    color="blue dark-1"
                    :nbr="nbPaiement"
                  />
                </div>
                <div class="text-center col-md-4 col-lg-4 col-sm-6 col-xl-4">
                  <CardNum
                  libelle=""
                    :name="$t('dashboard.total')"
                    icon="mdi-cash-multiple"
                    color="blue dark-1"
                    :nbr="mntPayBoutJour"
                    quantification="Fcfa"
                  />
                </div>
                <div class="text-center col-md-4 col-lg-4 col-sm-6 col-xl-4">
                  <CardNum
                  libelle=""
                    :name="$t('dashboard.garbage')"
                    icon="mdi-basket"
                    color="blue dark-1"
                    :nbr="tailleCorbeille"
                  />
                </div>
                <div class="text-center col-md-4 col-lg-4 col-sm-6 col-xl-4">
                  <CardNum
                  libelle=""
                    :name="$t('dashboard.balance')"
                    icon="mdi-cash-multiple"
                    color="blue dark-1"
                    :nbr="totalPaye"
                    quantification="Fcfa"
                  />
                </div>
                <div class="text-center col-md-4 col-lg-4 col-sm-6 col-xl-4">
                  <CardNum
                  libelle=""
                    :name="$t('dashboard.bill')"
                    icon="mdi-file-document-multiple"
                    color="blue dark-1"
                    :nbr="nbrFacBout"
                  />
                </div> 
              </template>
            </v-row>
          </v-layout>

          <v-layout class="mt-4">
          <v-row class="">
            <div class="col-md-6 col-lg-6 col-sm-6">
              <v-toolbar>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datedebut"
                      label="date debut"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datedebut"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-toolbar>
            </div>
            <v-spacer></v-spacer>
            <div class="col-md-6 col-lg-6 col-sm-6">
              <v-toolbar>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datefin"
                      prepend-icon="mdi-calendar"
                      readonly
                      label="date fin"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datefin"
                    @input="action"
                  ></v-date-picker>
                </v-menu>
              </v-toolbar>
            </div>
          </v-row>
          </v-layout>
        </v-col>
    <v-layout v-resize="onResize"
    column style="padding-top:56px">
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :class="{mobile: isMobile}"
        class="elevation-1"
        :hide-default-header="isMobile"
        disable-filtering
        disable-pagination
        disable-sort
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("rapport") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr v-if="!isMobile">
            <td class="text-xs-right">{{ item.totalArticle }}</td>
            <td class="text-xs-right">{{ item.montantStock }}</td>
            <td class="text-xs-right">{{ item.montantVendue }}</td>
            <td class="text-xs-right">{{ item.montantSortieStock }}</td>
            <td class="text-xs-right">{{ item.benefice }}</td>
          </tr>
          <tr v-else>
          <td>
            <ul class="flex-content">
              <li class="flex-item" data-label="Article">Article:  {{ item.totalArticle }}</li>
              <li class="flex-item" data-label="Montant">Montant: {{ item.montantStock }}</li>
              <li class="flex-item" data-label="Vendue">Vendue: {{ item.montantVendue }}</li>
              <li class="flex-item" data-label="Sortie">Sortie: {{ item.montantSortieStock }}</li>
              <li class="flex-item" data-label="Benef">Benefice: {{ item.benefice }}</li>
            </ul>
          </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
    </v-container>
    </v-layout>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import CardNum from "../components/cards/CardNumber";

export default {
  props: {
    full_name: String,
  },
  components: {
    CardNum,
  },
  data: () => ({
    loading: false,
    isMobile:false,
    //  datedebut: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //  datefin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    datedebut: null,
    datefin: null,
    menu1: false,
    rapport: [],
    boutiqueId:"",
    modal: false,
    menu2: false,
  }),
  created() {
    this.$store.dispatch("dashboard/SUM_COMMANDE");
    this.$store.dispatch("dashboard/SUM_PAIEMENT");
    this.$store.dispatch("dashboard/SUM_TOTAL_PAYE");
    this.$store.dispatch("dashboard/SUM_TAILLE_CORBEILLE");
    //this.$store.dispatch("dashboard/SUM_FACTURE");
    this.$store.dispatch("dashboard/MNT_PAY_BOUT_JOUR");
    //this.$store.dispatch("dashboard/PAY_BOUT");
    //this.$store.dispatch("dashboard/CMD_BOUT");
    this.$store.dispatch("dashboard/CMD_BOUT_JOUR");
    this.$store.dispatch("dashboard/NBR_FAC_BOUT_JOUR");
    //this.$store.dispatch("dashboard/FAC_BOUT");
  },

  computed: {
    headers() {
      return [
        {
          text: "TOTAL ARTICLE",
          align: "left",
          value: "totalArticle",
        },
        { text: "montantStock", value: "montantStock" },
        { text: "montantVendue", value: "montantVendue" },
        { text: "montantSortieStock", value: "montantSortieStock" },
        { text: "benefice", value: "benefice" },
      ];
    },
    itemList() {
      if (this.rapport) {
        console.log(this.rapport);
        return this.rapport;
      } else {
        return [];
      }
    },
    ...mapGetters({
      gettoken: "login/gettoken",
      allBoutiqueForUser: "boutique/getAllBoutiquesCurrentUser",
      //NbCommande: "dashboard/getNbCommande",
      cmdBoutJour: "dashboard/getCmdBoutiqueJour",
      nbPaiement: "dashboard/getNbPaiement",
      mntPayBoutJour: "dashboard/getMntPayBoutJour",
      tailleCorbeille: "dashboard/getTailleCorbeille",
      totalPaye: "dashboard/getTotalPaye",
      nbrFacBout: "dashboard/getNbrFacBoutJour",
      evaluation: "dashboard/getEvaluation",
      //NbFacture: "dashboard/getNbFacture",

      //MntPayBout: "dashboard/getMntPayBout",
      //CmdBoutique: "dashboard/getCmdBoutique",

      //NbrFacBoutJour: "dashboard/getNbrFacBoutJour",
      computedDateFormatted() {
        return this.formatDate(this.date);
      },
    }),

    ...mapActions({}),
  },
  watch: {},

  methods: {
    onResize()
    {
      if(window.innerWidth < 769)
      this.isMobile = true
      else
      this.isMobile= false
    },
    async action() {
      this.menu2 = false;
      let controle1 = this.datedebut.toString();
      let controle2 = this.datefin.toString();
      console.log(controle1 == controle2);
      console.log(controle1 < controle2);
      console.log(controle1 > controle2);
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/rapport/" +
            boutiqueId.idBoutique +
            "/" +
            this.datedebut +
            "/" +
            this.datefin,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.rapport = response.data;
            console.log(this.rapport);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  mounted()
  {
    this.boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    this.$store.dispatch("dashboard/SUMME_EVALUATION");
  }
};
</script>
<style >
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
  .mobile {
      color: #333;
    }

    @media screen and (max-width: 860px) {
      .mobile table.v-table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile table.v-table tr:nth-child(odd) {
        border-left: 6px solid deeppink;
      }

      .mobile table.v-table tr:nth-child(even) {
        border-left: 6px solid cyan;
      }

      .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
      }

      .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;

      }
      .v-datatable__actions__select
      {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
      }
      .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
      }

    }
    .flex-content {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .flex-item {
      padding: 5px;
      width: 50%;
     
      font-weight: bold;
    }
</style>
