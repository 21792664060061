<template>
<v-app v-if="islaoding=true">
  <router-view></router-view>
</v-app>
<v-app v-else>  
  <index/>
</v-app>
<!-- 
  <v-main v-else=" islanding =false" class="default">
  </v-main> -->
</template>
<script>
import i18n from "../i18n";
import index from "../views/index.vue"
export default {
  name: "Default",
  components: {index,},
  created() {
    this.redirect();
  },
  methods: {
    redirect() {
      if (["Login", "Default, Register"].indexOf(this.$route.name) > -1)
        this.$router.push(`/${i18n.locale}/login`);
    },
    islanding()
    {
      if(window.location.pathname == "/fr/" || window.location.pathname== '/en/')
      {
        return true
      }
    }
  },

   mounted: function() {
      console.log(window.location.pathname == "/fr/" || window.location.pathname == "/en/")  
  },
};
</script>
<style scoped>
  .default
  {
    padding-top: 28px !important;
    padding-right: 20px !important;
  }
</style>