import Vue from 'vue'
import Vuetify from 'vuetify'
import VueI18n from 'vue-i18n'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)
Vue.use(VueI18n)


const opts = {
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
}

const messages = {
    en: {
        acceuil:{
          allcat: "Allllll categories"
        },
        $vuetify: {
          close: 'Close',
          noDataText: 'No data',
          dataTable: {
            itemsPerPageText: 'Items per page:',
            ariaLabel: {
              sortNone: "",
              activateAscending: ""
            }
          },
          dataFooter: {
            itemsPerPageAll: 'All',
            pageText: '{0}-{1} of {2}',
            prevPage: 'Previous',
            nextPage: 'Next'
          },
        },
    },
    fr: {
        acceuil:{
          allcat: "Toutes les catégories"
        },
        $vuetify: {
          close: 'Fermer',
          noDataText: 'Aucune donnée',
          dataTable: {
            itemsPerPageText: 'Elements par pages:',
            ariaLabel: {
              sortNone: "",
              activateAscending: ""
            }
          },
          dataFooter: {
            itemsPerPageAll: 'Tout',
            pageText: '{0}-{1} de {2}',
            prevPage: 'Précédent',
            nextPage: 'Suivant'
          },
        },
      },
  }
  
  // Create VueI18n instance with options
  const i18n = new VueI18n({
    locale: 'en', // set locale
    messages: messages, // set locale messages
  })

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
      },
    opts}
    )