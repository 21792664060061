const state = {
  snackSuccess: {},
  snackError: {},
};

const getters = {};

const actions = {
  ADD_SNACK_SUCCESS: async ({ commit }, snack) => {
    commit("SET_SNACK_SUCCESS", snack);
  },
  ADD_SNACK_ERROR: async ({ commit }, snack) => {
    commit("SET_SNACK_ERROR", snack);
  },
};

const mutations = {
  SET_SNACK_SUCCESS: (state, snack) => {
    state.snackSuccess = snack;
  },
  SET_SNACK_ERROR: (state, snack) => {
    state.snackError = snack;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
