import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  clients: [],
};

const getters = {
  getAllClients: (state) => state.clients,
};

const actions = {
  FETCH_ALL_CLIENTS: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/clients_boutique/" + boutiqueId.idBoutique, {
          timeout: 3000,
        })
        .then(function(response) {
          const clients = response.data;
          commit("ALL_CLIENT", clients);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_CLIENTS_M: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/commandes_client_year/" + boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const clients = response.data;
          commit("ALL_CLIENT", clients);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_CLIENT: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      console.log(APIENDPOINT+ "/api/clients",c)
      instance
        .post(APIENDPOINT + "/api/clients", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const client = response.data;
          commit("NEW_CLIENT", client);
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  },

  ADD_CLIENT_AUTH: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      console.log(APIENDPOINT+ "/api/auth/clients/s2asport",c)
      instance
        .post(APIENDPOINT + "/api/auth/clients/s2asport", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const client = response.data;
          commit("NEW_CLIENT", client);
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  },

  ADD_CLIENT_AUTH2: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      console.log(APIENDPOINT+ "/api/auth/clients/win",c)
      instance
        .post(APIENDPOINT + "/api/auth/clients/win", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const client = response.data;
          commit("NEW_CLIENT", client);
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  },

  UPDATE_CLIENT: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/clients/" + c.idClient, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_CLIENT", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_CLIENT: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/clients/" + c.idClient)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_CLIENT: (state, clients) => {
    state.clients = clients;
  },

  NEW_CLIENT: (state, client) => {
    state.clients.push(client);
  },

  REFRESH_CLIENT: (state, c_new_value) => {
    const index = state.clients.findIndex(
      (x) => x.idClient == c_new_value.idClient
    );
    Object.assign(state.clients[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.clients.findIndex(
      (x) => x.idClient == c_del_value.idClient
    );
    state.clients.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
