import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  fournisseurs: [],
};

const getters = {
  getAllFournisseurs: (state) => state.fournisseurs,
};

const actions = {
  FETCH_ALL_FOURNISSEURS: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/fournisseurs_boutique/" + boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const fournisseurs = response.data;
          commit("ALL_FOURNISSEUR", fournisseurs);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_FOURNISSEUR: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/fournisseurs", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const fournisseur = response.data;
          commit("NEW_FOURNISSEUR", fournisseur);
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  },

  UPDATE_FOURNISSEUR: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/fournisseurs/" + c.idFournisseur, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_FOURNISSEUR", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_FOURNISSEUR: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/fournisseurs/" + c.idFournisseur)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_FOURNISSEUR: (state, fournisseurs) => {
    state.fournisseurs = fournisseurs;
  },

  NEW_FOURNISSEUR: (state, fournisseur) => {
    state.fournisseurs.push(fournisseur);
  },

  REFRESH_FOURNISSEUR: (state, c_new_value) => {
    const index = state.fournisseurs.findIndex(
      (x) => x.idFournisseur == c_new_value.idFournisseur
    );
    Object.assign(state.fournisseurs[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.fournisseurs.findIndex(
      (x) => x.idFournisseur == c_del_value.idFournisseur
    );
    state.fournisseurs.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
