var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('SuccessSnackBar'),_c('ErrorSnackBar'),_c('v-layout',[_c('v-row',[_c('v-col',{staticClass:"default",attrs:{"cols":"12","md":"12"}},[_c('div',[_c('v-app-bar',{staticClass:"elevation-20",attrs:{"color":"grey lighten-4"}},[_c('v-toolbar-title',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.newchauff = true}}},[_vm._v(_vm._s(_vm.$t("datatable-header.chauffeur.add")))]),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-form',{ref:"formadd",on:{"submit":function($event){$event.preventDefault();return _vm.add.apply(null, arguments)}}},[_c('v-card',[_c('v-card-text',{staticStyle:{"height":"200px"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{ref:"clear",attrs:{"label":_vm.$t('livraison.driver'),"clearable":"","type":"text","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-account"},model:{value:(_vm.nomChauffeur),callback:function ($$v) {_vm.nomChauffeur=$$v},expression:"nomChauffeur"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{ref:"clear1",attrs:{"label":_vm.$t(
                                    'datatable-header.livraison.contactchauffeur'
                                  ),"type":"number","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-phone","clearable":""},model:{value:(_vm.contactChauffeur),callback:function ($$v) {_vm.contactChauffeur=$$v},expression:"contactChauffeur"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","color":"red accent-4","dark":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle-outline")]),_vm._v(_vm._s(_vm.$t("close"))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","outlined":"","color":"blue accent-4"},on:{"click":function($event){return _vm.savechauffeur()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)]}}]),model:{value:(_vm.newchauff),callback:function ($$v) {_vm.newchauff=$$v},expression:"newchauff"}}),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-form',{ref:"formupdate",on:{"submit":function($event){$event.preventDefault();return _vm.add.apply(null, arguments)}}},[_c('v-card',[_c('v-card-text',{staticStyle:{"height":"200px"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{ref:"clear",attrs:{"label":_vm.$t('livraison.driver'),"clearable":"","type":"text","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-account"},model:{value:(_vm.nomChauffeur),callback:function ($$v) {_vm.nomChauffeur=$$v},expression:"nomChauffeur"}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-text-field',{ref:"clear1",attrs:{"label":_vm.$t(
                                    'datatable-header.livraison.contactchauffeur'
                                  ),"type":"number","rules":[_vm.rules.required],"prepend-inner-icon":"mdi-phone","clearable":""},model:{value:(_vm.contactChauffeur),callback:function ($$v) {_vm.contactChauffeur=$$v},expression:"contactChauffeur"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","color":"red accent-4","dark":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle-outline")]),_vm._v(_vm._s(_vm.$t("close"))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"tile":"","outlined":"","color":"blue accent-4"},on:{"click":function($event){return _vm.updatechauffeur()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1)]}}]),model:{value:(_vm.updatechauff),callback:function ($$v) {_vm.updatechauff=$$v},expression:"updatechauff"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('datatable-header.chauffeur.search'),"single-line":"","hide-details":""},on:{"input":_vm.searchresult},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])],1)],1),_c('v-container',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100%","max-width":"100%"},attrs:{"id":"scroll-target"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemList,"loading":_vm.loading,"sort-by":"libelle","hide-default-footer":"","disable-pagination":"","disable-filtering":"","disable-sort":"","mobile-breakpoint":"800"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("datatable-header.chauffeur.list")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.action",fn:function(ref){
                                  var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
                                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#f7ca18"},on:{"click":function($event){return _vm.openupdatechauffeur(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("chauffeur.update")))])])],1),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                  var on = ref.on;
                                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#cf000f"},on:{"click":function($event){return _vm.opendeleted(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("chauffeur.delete")))])])],1)]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("facture.nodata")))])]},proxy:true}])}),_c('div',{staticClass:"text-center mt-4"},[_c('v-pagination',{attrs:{"total-visible":5,"total-rows":_vm.rows,"length":_vm.total_page,"circle":""},model:{value:(_vm.current_pag),callback:function ($$v) {_vm.current_pag=$$v},expression:"current_pag"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline red darken-1 white--text"},[_vm._v(_vm._s(_vm.$t("caisse.deleting")))]),_c('v-card-text',{staticClass:"black--text mt-2"},[_c('p',[_vm._v(_vm._s(_vm.deleteMessage))])]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.$t("no")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleted}},[_vm._v(_vm._s(_vm.$t("yes")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }