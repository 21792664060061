import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  categories: [],
  pays: [],
};

const getters = {
  getAllCategories: (state) => state.categories,
  getAllPays: (state) => state.pays,
};

const actions = {
  FETCH_ALL_CATEGORIES: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/categories_boutique/" + boutiqueId.idBoutique,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const categories = response.data;
          commit("ALL_CATEGORIE", categories);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALl_PAYS: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/auth/pays", {
          timeout: 3000,
        })
        .then(function(response) {
          const pays = response.data;
          commit("ALL_PAYS", pays);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_CATEGORIE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/categories", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const categorie = response.data;
          commit("NEW_CATEGORIE", categorie);
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  },

  UPDATE_CATEGORIE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/categories/" + c.idCategorie, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_CATEGORIE", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_CATEGORIE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/categories/" + c.idCategorie)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_CATEGORIE: (state, categories) => {
    state.categories = categories;
  },
  ALL_PAYS: (state, pays) => {
    state.pays = pays;
  },
  NEW_CATEGORIE: (state, categorie) => {
    state.categories.push(categorie);
  },

  REFRESH_CATEGORIE: (state, c_new_value) => {
    const index = state.categories.findIndex(
      (x) => x.idCategorie == c_new_value.idCategorie
    );
    Object.assign(state.categories[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.categories.findIndex(
      (x) => x.idCategorie == c_del_value.idCategorie
    );
    state.categories.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
