import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  NbrCommande: 0,
  NbrPaiement: 0,
  TotalPaye: 0,
  TailleCorbeille: 0,
  NbrFacture: 0,
  MntPayBoutJour: 0,
  MntPayBout: 0,
  CmdBoutique: 0,
  CmdBoutiqueJour: 0,
  NbrFacBoutJour: 0,
  NbrFacBout: 0,
  EVALUATION: null,
  evaluationadmin:{}
};

const getters = {
  getNbCommande: (state) => state.NbrCommande,
  getNbPaiement: (state) => state.NbrPaiement,
  getTotalPaye: (state) => state.TotalPaye,
  getTailleCorbeille: (state) => state.TailleCorbeille,
  getNbFacture: (state) => state.NbrFacture,

  getMntPayBoutJour: (state) => state.MntPayBoutJour,
  getMntPayBout: (state) => state.MntPayBout,
  getCmdBoutique: (state) => state.CmdBoutique,
  getCmdBoutiqueJour: (state) => state.CmdBoutiqueJour,
  getNbrFacBoutJour: (state) => state.NbrFacBoutJour,
  getNbrFacBout: (state) => state.NbrFacBout,
  getEvaluation: (state) => state.EVALUATION,
  allevlution:(state) => state.evaluationadmin
};
const boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
const actions = {
  SUM_COMMANDE: ({ commit }) => {
    instance
      .get(
        APIENDPOINT + "/api/ligne_commande_count_date/" + boutique.idBoutique,
        {
          timeout: 6000,
        }
      )
      .then(function(response) {
        commit("NBR_COMMANDE", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  SUM_PAIEMENT: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/paiements_count_date/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("NBR_PAIEMENT", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  SUM_TOTAL_PAYE: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/montant_date/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("TOT_PAYE", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  SUM_TAILLE_CORBEILLE: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/corbeilles_count_date/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("NB_CORBEILLE", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  SUM_FACTURE: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/factures_count_date/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("NB_FACTURE", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  MNT_PAY_BOUT_JOUR: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/montant_date/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("PAY_BOUT_JOUR", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  MNT_PAY_BOUT: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/montant/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("PAY_BOUT", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  CMD_BOUTIQUE: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/ligne_commande_count/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("CMD_BOUT", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  CMD_BOUT_JOUR: ({ commit }) => {
    instance
      .get(
        APIENDPOINT + "/api/ligne_commande_count_date/" + boutique.idBoutique,
        {
          timeout: 6000,
        }
      )
      .then(function(response) {
        commit("BOUT_JOUR_CMD", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  NBR_FAC_BOUT_JOUR: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/factures_count_date/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("FAC_BOUT_JOUR", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  NBR_FAC_BOUT: ({ commit }) => {
    instance
      .get(APIENDPOINT + "/api/factures_count/" + boutique.idBoutique, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("FAC_BOUT", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },

  SUMME_EVALUATION: ({ commit }) => {
    const boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
    let id= boutique.idBoutique
    instance
      .get(APIENDPOINT + "/api/entrees/evaluation/depot/" + id, {
        timeout: 6000,
      })
      .then(function(response) {
        commit("S_EVALUATION", response.data);
      })
      .catch(function() {
        //console.log("Error:" + err);
      });
  },
};

const mutations = {
  NBR_COMMANDE: (state, nbr) => {
    state.NbrCommande = nbr;
  },

  NBR_PAIEMENT: (state, nbr) => {
    state.NbrPaiement = nbr;
  },

  TOT_PAYE: (state, amount) => {
    state.TotalPaye = amount;
  },

  NB_CORBEILLE: (state, nbr) => {
    state.TailleCorbeille = nbr;
  },

  NB_FACTURE: (state, nbr) => {
    state.NbrFacture = nbr;
  },

  PAY_BOUT_JOUR: (state, mnt) => {
    state.MntPayBoutJour = mnt;
  },

  PAY_BOUT: (state, mnt) => {
    state.MntPayBout = mnt;
  },

  CMD_BOUT: (state, cmd) => {
    state.CmdBoutique = cmd;
  },

  BOUT_JOUR_CMD: (state, cmd) => {
    state.CmdBoutiqueJour = cmd;
  },

  FAC_BOUT_JOUR: (state, fac) => {
    state.NbrFacBoutJour = fac;
  },

  FAC_BOUT: (state, nbr) => {
    state.NbrFacBout = nbr;
  },

  S_EVALUATION: (state, evaluation) => {
    state.EVALUATION = evaluation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
