<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Factures Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />

    <!-- Fin Components -->

    <v-divider></v-divider>

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <!-- Début dialog de payement d'une facture -->

                <!--Fin dialog de payement d'une facture -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field v-if="isDirector"
                v-model.lazy="searchFacDir"
                append-icon="mdi-magnify"
                :label="$t('facture.search')"
                single-line
                @input="axiosSearchDir"
                hide-details
              ></v-text-field>
              <v-text-field v-else
                v-model.lazy="searchFac"
                append-icon="mdi-magnify"
                :label="$t('facture.search')"
                single-line
                @input="axiosSearch"
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <template v-if="isDirector">
        <v-data-table
          :headers="headersAdmin"
          :items="itemListDir"
          :search="searchFacDir"
          :loading="loading"
          class="elevation-1"
           hide-default-footer
           disable-filtering
           disable-pagination
           disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ $t("facture.bill1") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.facture.numeroFacture }}</td>
              <td>
                {{ item.facture.ligneBoutique.boutiquesPK.user.username }}
              </td>
              <td v-if="item.facture.commande.client">{{ item.facture.commande.client.nom }}</td>
              <td v-else></td>
              <td>{{ item.facture.montantFacture + " FCFA" }}</td>
              <td>
                <template v-if="item.facture.solde">
                  <v-chip color="blue" text-color="white">
                    {{ $t("yes") }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip color="red" text-color="white">
                    {{ $t("no") }}
                  </v-chip>
                </template>
              </td>
              <td>{{ item.reste + " FCFA" }}</td>
              <td>{{ convertDate(item.facture.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="showFacture(item)" v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
          <div class="mt-4">
       <v-pagination
          v-if="searchFacDir"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearchDir"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosListDir"
          circle
        >
        </v-pagination>
      </div>
      </template>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="itemList"
          :loading="loading"
          :search="searchFac"
          sort-by="commande.client.nom"
          class="elevation-1"
          hide-default-footer
          disable-filtering
          disable-sort
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ $t("datatable-title.facture.listefacturecaissier") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-icon>mdi-file-document-box-multiple-outline</v-icon>
              </td>
              <td>{{ item.facture.numeroFacture }}</td>
              <td>{{ item.facture.commande.client.nom }}</td>
              <td>{{ item.facture.montantFacture - item.remise + " FCFA" }}</td>
              <td>
                <template v-if="item.facture.solde">
                  <v-chip color="blue" text-color="white">
                    {{ $t("yes") }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip color="red" text-color="white">
                    {{ $t("no") }}
                  </v-chip>
                </template>
              </td>
              <td>{{ item.facture.reste + " FCFA" }}</td>
              <td>{{ convertDate(item.facture.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="showFacture(item)" v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
           <div class="mt-4">
       <v-pagination
          v-if="searchFac"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
      </template>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
//import $ from "jquery";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
//import PageTitle from "../components/titles/PageTitle";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";

import i18n from "../../i18n";
import axios from "axios";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    //PageTitle,
  },
  data: () => ({
    mask: "########",
    searchFac: "",
    searchFacDir:"",
    factures:{},
    factureDir:{},
    current_pag:1,
    storage: window.localStorage,
    facture: null,
    dialog: false,
    dialogView: false,
    dialogOneFac: false,
    modelSelectedCaisse: null,
    loading: false,
    search: null,
    inputRemise: false,
    articles: new Array(),
    ajoutArticle: new Array(),
    //ajouter: false,
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    quantite: "",
    quantiteArt: 0,
    reste: 0,
    oldRemise: 0,
    newRemise: 0,
    editedItem: {
      caisse: {},
      facture: 0,
      montant: "",
      montantPaye: "",
    },
    defaultItem: {
      caisse: {},
      facture: 0,
      montant: "",
      montantPaye: "",
    },

    showItem: {
      idPaiement: 0,
      user: {
        dUser: 0,
        username: "",
      },
      facture: {
        idFacture: 0,
        commande: {
          client: {
            idClient: 0,
            nom: "",
          },
        },
        file: "",
        montantFacture: 0,
        remise: 0,
        solde: false,
        typeFacture: {
          idTypeFacture: 0,
          libelle: "",
        },
        createdAt: "",
      },
    },
  }),

  computed: {
    headers() {
      return [
        { text: "", value: "" },
        {
          text: this.$t("datatable-header.facture.idfacture"),
          align: "left",
          sortable: false,
          value: "numeroFacture",
        },
        { text: this.$t("facture.customer3"), value: "commande.client.nom" },
        { text: this.$t("facture.total2"), value: "" },
        { text: this.$t("bill.state"), value: "acompte" },
        { text: this.$t("bill.rest"), value: "reste" },
        { text: this.$t("facture.date"), value: "createdAt" },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
    headersAdmin() {
      return [
        {
          text: this.$t("datatable-header.facture.idfacture"),
          align: "left",
          value: "numeroFacture",
          sortable: false,
        },
        {
          text: this.$t("login.username"),
          value: "ligneBoutique.boutiquesPK.user.nom",
          sortable: false,
        },
        {
          text: this.$t("datatable-header.facture.client"),
          value: "commande.client.nom",
          sortable: false,
        },
        { text: this.$t("datatable-header.facture.totalfacture"), value: "montantFacture" },
        { text: this.$t("datatable-header.facture.cash"), value: "encaisse" },
        { text: this.$t("datatable-header.facture.reste"), value: "reste" },
        { text: this.$t("datatable-header.facture.date"), value: "createdAt" },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
     total_page() {
          if (this.factures && this.factures.last_page) {
            return this.factures.last_page;
          } else {
            return 0;
          }
        },
        itemListDir() {
          if (this.factureDir != null && this.factureDir.data) {
            return this.factureDir.data;
          } else {
            return [];
          }
        },
        itemList() {
          if (this.factures != null && this.factures.data) {
            return this.factures.data;
          } else {
            return [];
          }
        },
        lastpage() {
          if (this.factures && this.factures.last_page) {
            return this.factures.last_page;
          } else {
            return 0;
          }
        },

    ...mapGetters({
       gettoken: "login/gettoken",
      allPaiement: "payement/getAllPayements",
      _role: "login/getUrole",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("bill.customer")
        : this.$t("bill.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("facture.bill3")
        : this.$t("facture.update1");
    },

    /*itemC() {
      return this.allCaisses.map((caisse) => {
        const libelles = caisse.libelle;
        return Object.assign({}, caisse, { libelles });
      });
    },*/

    isValidPayement() {
      if (
        this.modelSelectedCaisse === null ||
        this.editedItem.montantPaye === ""
      )
        return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },

    isDirector() {
      const isObjectPresent = this._role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.loading = true;
    if (this.isDirector) {
      this.$store
        .dispatch("payement/FETCH_ALL_PAYEMENTS_BOUTIQUE")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.$store
        .dispatch("payement/FETCH_ALL_PAYEMENTS_USER")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

    mounted() {
      if(this.isDirector!==undefined)
      {
        this.axiosListDir();
      }
      else
      {
        this.axiosList();
      }
  },

  methods: {
    ...mapActions({
      payerFacture: "payement/ADD_PAYEMENTS",
    }),
    async axiosListDir() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      await axios
        .get(
          
          APIENDPOINT +
            "/api/paiement/page/" +
            boutiqueId.idBoutique+
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
             console.log(response.data)
            this.factureDir = response.data;
            if (this.factureDir.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
     async axiosSearchDir() {
       this.loading = false;
     let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      if (this.searchFacDir) {
        console.log(this.searchFacDir)
        await axios
          .get(
            APIENDPOINT +
            "/api/paiement/page/" +
            boutiqueId.idBoutique+
            "/" +
            this.current_pag+
              "/" +
              this.searchFacDir,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              console.log(response.data)
              this.factureDir = response.data;
            
              if (this.factureDir.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
          console.log(error);
        });
      } else {
        this.axiosListDir();
      }
    },
    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      await axios
        .get(
          APIENDPOINT +
            "/api/paiement/user/page/" +
            boutiqueId.idBoutique+
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.factures = response.data;
            if (this.factures.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
     let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      if (this.searchFacDir) {
        await axios
          .get(
            APIENDPOINT +
            "/api/paiement/user/page/" +
            boutiqueId.idBoutique+
            "/" +
            this.current_pag+
              "/" +
              this.searchFac,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              console.log(this.factures)
              this.factures = response.data;
              if (this.factures.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
          console.log(error);
        });
      } else {
        this.axiosList();
      }
    },
    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY-MM-DD hh:mm:ss");
        //convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("YYYY-MM-DD à HH:mm:ss");
        //convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    calculReste() {
      const rest =
        parseInt(this.editedItem.montant) -
        parseInt(this.editedItem.montantPaye);
      this.reste = rest;
    },

    async showFacture(item) {
      const facture=APIENDPOINT + "/api/downloadFile/"+ item.facture.file
      await instance
        .get(facture, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    payementFacture(item) {
      console.log(item);
      this.editedIndex = this.allFactures.indexOf(item);
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },

    closePayement() {
      this.dialogView = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async downloadFacture(item) {
      await instance
        .get(APIENDPOINT + "/e2v/api/downloadFile/" + item.codeFac, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    getTotalOneFacture(facture) {
      let somme = 0;
      facture.analyseList.forEach((element) => {
        somme += !element.netPayBeneficiaire
          ? element.price
          : element.netPayBeneficiaire;
      });
      return somme - facture.remise;
    },

    close() {
      this.dialogView = false;
      setTimeout(() => {
        this.editedItem.montantPaye = "";
        this.modelSelectedCaisse = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.$refs.formPayer.validate()) {
        this.loading = true;
        let items = {};

        items.caisse = this.modelSelectedCaisse.idCaisse;
        items.facture = this.showItem.idFacture;
        items.montantPaye = parseInt(this.editedItem.montantPaye);
        items.montant = parseInt(this.editedItem.montant);

        await this.payerFacture(items)
          .then(() => {
            this.loading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            var msg = this.$t("bill.msg");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            this.$store
              .dispatch("facture/FETCH_ALL_FACTURE")
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("bill.msg1");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.close();
      }
    },
  },
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>