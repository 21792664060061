<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Factures Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
   
    <!-- Fin Components -->

    <v-divider></v-divider>

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <!-- Début dialog de payement d'une facture -->
                <v-dialog
                  v-model="dialogView"
                  scrollable
                  persistent
                  max-width="700"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("bill.payBill")
                      }}</span>
                    </v-card-title>

                    <v-card-text style="height: 400px;">
                      <v-container grid-list-md>
                        <v-form ref="formPayer">
                          <v-layout wrap>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.num") }}:
                                <h3 class="black--text">
                                  {{ showItem.numeroFacture }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span class="ml-2">
                                {{ $t("facture.customer3") }}:
                                <h2 class="black--text ml-2">
                                  {{ showItem.commande.client.nom }}
                                </h2>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.type") }}:
                                <h3 class="red--text">
                                  {{ showItem.typeFacture.libelle }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.amount") }}:
                                <h2 class="red--text" id="total" name="total">
                                  {{
                                    showItem.montantFacture - showItem.remise
                                  }}
                                  FCFA
                                </h2>
                              </span>
                            </v-col>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.montant"
                                :rules="[(v) => !!v || $t('bill.remitted')]"
                                type="number"
                                :label="$t('bill.remitted1')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.montantPaye"
                                :rules="[(v) => !!v || $t('bill.received')]"
                                type="number"
                                :label="$t('bill.paid')"
                                @keyup="calculReste"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6> </v-flex>
                            <v-col cols="12" md="4" offset-md="2">
                              <span>
                                {{ $t("bill.rest") }} :
                                <h3 class="red--text">{{ reste }} FCFA</h3>
                              </span>
                            </v-col>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                        :disabled="isValidPayement"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ $t("bill.remitted") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog de payement d'une facture -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model.lazy="searchFac"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('facture.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :search="searchFac"
        :loading="laoding"
        sort-by="commande.client.nom"
        class="elevation-1"
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("datatable-title.facture.listefacturepayer") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-icon>mdi-file-document-box-multiple-outline</v-icon>
            </td>
            <td>{{ item.numeroFacture }}</td>
            <td v-if="item.commande.client">{{ item.commande.client.nom }}</td>
            <td v-else></td>
            <td>{{ item.montantFacture - item.remise + " FCFA" }}</td>
            <td>
              <template v-if="item.solde">
                <v-chip color="blue" text-color="white">
                  {{ $t("yes") }}
                </v-chip>
              </template>
              <template v-else>
                <v-chip color="red" text-color="white">
                  {{ $t("no") }}
                </v-chip>
              </template>
            </td>
            <td>{{ item.reste + " FCFA" }}</td>
            <td>{{ convertDate(item.createdAt) }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="payementFacture(item)"
                    v-on="on"
                    >mdi-cash-plus</v-icon
                  >
                </template>
                <span>{{ $t("bill.paid2") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small @click="showFacture(item)" v-on="on"
                    >mdi-eye</v-icon
                  >
                </template>
                <span>{{ $t("facture.see") }}</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
       <div class="mt-4">
       <v-pagination
          v-if="searchFac"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
//import $ from "jquery";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
//import PageTitle from "../components/titles/PageTitle";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import axios from "axios";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  data: () => ({
    mask: "########",
    searchFac: "",
    factures:{},
    current_pag:1,
    storage: window.localStorage,
    facture: null,
    dialog: false,
    dialogView: false,
    dialogOneFac: false,
    modelSelectedCaisse: null,
    loading: false,
    search: null,
    inputRemise: false,
    articles: new Array(),
    ajoutArticle: new Array(),
    //ajouter: false,
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    quantite: "",
    quantiteArt: 0,
    reste: 0,
    oldRemise: 0,
    newRemise: 0,
    editedItem: {
      caisse: {},
      facture: 0,
      montant: "",
      montantPaye: "",
    },
    defaultItem: {
      caisse: {},
      facture: 0,
      montant: "",
      montantPaye: "",
    },
    showItem: {
      idFacture: 0,
      commande: {
        client: {
          idClient: 0,
          nom: "",
        },
      },
      file: "",
      montantFacture: 0,
      remise: 0,
      solde: false,
      typeFacture: {
        idTypeFacture: 0,
        libelle: "",
      },
      createdAt: "",
    },
  }),

  computed: {
    headers() {
      return [
        { text: "", value: "" },
        {
          text: this.$t("datatable-header.facture.idfacture"),
          align: "left",
          sortable: false,
          value: "numeroFacture",
        },
        { text: this.$t("facture.customer3"), value: "commande.client.nom" },
        { text: this.$t("facture.total2"), value: "" },
        { text: this.$t("bill.state"), value: "acompte" },
        { text: this.$t("bill.rest"), value: "reste" },
        { text: this.$t("facture.date"), value: "createdAt" },
        { text: "Actions", value: "action", sortable: false },
      ];
    },

    
    total_page() {
      if (this.factures && this.factures.last_page) {
        return this.factures.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.factures != null && this.factures.data) {
        return this.factures.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.factures && this.factures.last_page) {
        return this.factures.last_page;
      } else {
        return 0;
      }
    },
    ...mapGetters({
      gettoken: "login/gettoken",
      allFactures: "facture/getAllFactures",
      allFacturesNonSolde: "facture/getAllFacturesNonSolde",
      allCaisses: "caisse/getAllCaisses",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("bill.customer")
        : this.$t("bill.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("facture.bill3")
        : this.$t("facture.update1");
    },

    itemC() {
      return this.allCaisses.map((caisse) => {
        const libelles = caisse.libelle;
        return Object.assign({}, caisse, { libelles });
      });
    },

    isValidPayement() {
      if (this.editedItem.montant === "" || this.editedItem.montantPaye === "")
        return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.loading = true;
    this.caisses;
    this.$store.dispatch("caisse/FETCH_ALL_CAISSES");
    this.$store
      .dispatch("facture/FETCH_ALL_FACTURE_NON_SOLDE")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

mounted() {
    this.axiosList();
  },
  methods: {
    ...mapActions({
      caisses: "caisse/FETCH_ALL_CAISSES",
      payerFacture: "payement/ADD_PAYEMENTS",
    }),
      async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      await axios
        .get(
          APIENDPOINT +
            "/api/factures/boutique/solde/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.factures = response.data;
            if (this.factures.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let token = JSON.parse(this.gettoken);
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      if (this.searchFac) {
        await axios
          .get(
            APIENDPOINT +
              "/api/facture/boutique/solde/search/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchFac,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.factures = response.data;

              if (this.factures.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY-MM-DD hh:mm:ss");
        // convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("YYYY-MM-DD à HH:mm:ss");
        //convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    calculReste() {
      const rest =
        parseInt(this.editedItem.montant) -
        parseInt(this.editedItem.montantPaye);
      this.reste = rest;
    },

    async showFacture(item) {
      await instance
        .get(item.file, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    payementFacture(item) {
     
      this.editedIndex = 0
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },

    closePayement() {
      this.dialogView = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async downloadFacture(item) {
      await instance
        .get(APIENDPOINT + "/e2v/api/downloadFile/" + item.codeFac, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    getTotalOneFacture(facture) {
      let somme = 0;
      facture.analyseList.forEach((element) => {
        somme += !element.netPayBeneficiaire
          ? element.price
          : element.netPayBeneficiaire;
      });
      return somme - facture.remise;
    },

    close() {
      this.dialogView = false;
      setTimeout(() => {
        this.editedItem.montantPaye = "";
        //this.modelSelectedCaisse = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.$refs.formPayer.validate()) {
        this.loading = true;
        let items = {};

        //items.caisse = this.modelSelectedCaisse.idCaisse;
        items.facture = this.showItem.idFacture;
        items.montantPaye = parseInt(this.editedItem.montantPaye);
        items.montant = parseInt(this.editedItem.montant);
        items.livrer = true;

        await this.payerFacture(items)
          .then(() => {
            this.loading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            var msg = this.$t("bill.msg");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            this.$store
              .dispatch("facture/FETCH_ALL_FACTURE")
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("bill.msg1");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
          this.loading = false;
        this.axiosList()
        this.close();
      }
    },
  },
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>