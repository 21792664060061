<template>
  <v-dialog :value="value" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <LoadingDialog :value="isLoading" />
    <!-- Fin Components -->
    <!-- Début Fullscreen dialog  -->
    <v-card color="grey lighten-3" tile>
      <v-toolbar max-height="60" dark dense color="teal">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$t('sidebar.param')}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fill-height column justify-space-between>
          <v-flex>
            <v-layout>
              <v-row>
                <v-col cols="12" md="12">
                  <!-- fonctionnalité future -->
                  <!-- <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Profil
                      <template v-slot:actions>
                        <v-icon color="primary">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  </v-expansion-panels>-->

                  <v-card class="mx-auto" max-width="800">
                    <v-img class="white--text" height="200px" :src="image_src">
                      <v-card-title class="align-center fill-height">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-avatar size="100" class="teal darken-1">
                              <span class="text-uppercase">{{usersInitials}}</span>
                            </v-avatar>
                          </v-col>
                          <v-col cols="12" md="12">
                            <span
                              class="black--text title"
                            >{{ editedItem.firstnameU +" "+ editedItem.lastnameU}}</span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                    </v-img>

                    <v-card-text>
                      <v-form ref="formUserWeb">
                        <v-layout wrap>
                          <v-flex xs12 sm12 md12>
                            <v-text-field v-model="editedItem.lastnameU" :label="$t('client.name1')" disabled></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              v-model="editedItem.firstnameU"
                              :label="$t('client.name2')"
                              disabled
                              required
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12>
                            <v-text-field
                              v-model="editedItem.username"
                              :label="$t('user')"
                              required
                            ></v-text-field>
                          </v-flex>
                          <v-row>
                            <v-col cols="12" md="8">
                              <v-text-field
                                v-model="editedItem.password"
                                :label="$t('pass')"
                                :disabled="!checkChgPwd"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-checkbox v-model="checkChgPwd" :label="$t('change')"></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-layout>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="close" color="orange">{{$t('close')}}</v-btn>
                      <v-btn :loading="isLoading" @click="changeProfile" color="green" dark>{{$t('update')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-layout>
          </v-flex>
        </v-container>
      </v-card-text>

      <div style="flex: 1 1 auto;"></div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import SuccessSnackBar from "../snackbar/SuccessSnackBar";
import ErrorSnackBar from "../snackbar/ErrorSnackBar";
import LoadingDialog from "../dialog/LoadingDialog";

export default {
  props: {
    value: Boolean
  },
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    LoadingDialog
  },
  data: () => ({
    image_src: require("../../assets/logo.png"),
    checkChgPwd: false,
    storage: window.localStorage,
    loading: false,
    editedItem: {
      idU: 0,
      username: "",
      firstnameU: "",
      lastnameU: "",
      emailU: "",
      password: "",
      roleuserweb: 0
    }
  }),
  computed: {
    usersInitials() {
      const connected_user = JSON.parse(this.storage.user);
      return (
        connected_user.firstnameU.substring(0, 1) +
        "" +
        connected_user.lastnameU.substring(0, 1)
      );
    },
    isLoading() {
      return this.loading;
    }
  },
  created() {},
  mounted() {
    this.editedItem = Object.assign({}, JSON.parse(this.storage.user));
  },
  methods: {
    ...mapActions({
      profile: "login/UPDATE_PROFIL"
    }),
    close() {
      this.value = false;
      this.$parent.dialog_parameter = false;
    },
    changeProfile() {
      this.loading = true;
      this.profile(this.editedItem)
        .then(() => {
          var msg = this.$t('text1');
          this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
            show: true,
            text: msg
          });
          this.loading = false;
        })
        .catch(() => {
          var msg = this.$t('text2');
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg
          });
          this.loading = false;
        });
    }
  }
};
</script>