<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
  
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog
                  v-model="dialog"
                  scrollable
                  persistent
                  max-width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                    >
                      <v-icon left>mdi-plus-circle-outline</v-icon
                      >{{ $t("depot.new") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 250px;">
                      <v-container grid-list-md>
                        <v-form ref="formDepot">
                          <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.libelle"
                                :rules="[(v) => !!v || $t('depot.name1')]"
                                :label="$t('depot.name1')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedBoutique"
                                :items="item"
                                :search-input.sync="search"
                                item-text="nom"
                                item-value="idBoutique"
                                :rules="[(v) => !!v || $t('depot.name3')]"
                                :label="$t('depot.name3')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{
                      $t("depot.deleting")
                    }}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="red darken-1"
                        @click="delete_dialog = false"
                        text
                        >{{ $t("no") }}</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                        >{{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchDepot"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('depot.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchDepot"
        sort-by="libelle"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("depot.shp") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.libelle }}</td>
            <td>{{ item.boutique.nom }}</td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItem(item, 0)">mdi-delete</v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
        <div class="text-center mt-4">
        <v-pagination
          v-if="searchDepot"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import moment from "moment";
import { mask } from "vue-the-mask";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
   
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchDepot: "",
    depot:{},
    current_pag:1,
    dialog: false,
    snackbar: false,
    loading: false,
    search: null,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    modelSelectedBoutique: null,
    delete_item: null,
    valid: true,
    select: null,
    editedIndex: -1,
    editedItem: {
      idDepot: 0,
      libelle: "",
      boutique: {
        idBoutique: 0,
      },
    },
    defaultItem: {
      idDepot: 0,
      libelle: "",
      boutique: {
        idBoutique: 0,
      },
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("client.name1"),
          align: "left",
          sortable: true,
          value: "libelle",
        },
        { text: this.$t("boutique"), value: "boutique.idBoutique" },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
     total_page() {
        
      if (this.depot && this.depot.last_page) {
        return this.depot.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.depot != null && this.depot.data) {
        return this.depot.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.depot && this.depot.last_page) {
        return this.depot.last_page;
      } else {
        return 0;
      }
    },
    ...mapGetters({
      gettoken: "login/gettoken",
      allDepots: "depot/getAllDepots",
      allBoutiques: "boutique/getAllBoutiquesCurrentUser",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("depot.save")
        : this.$t("depot.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("item.save1")
        : this.$t("item.update1");
    },

    item() {
      return this.allBoutiques.map((boutique) => {
        const noms = boutique.nom;
        return Object.assign({}, boutique, { noms });
      });
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
   
    createDossierDialog(val) {
      val || this.close();
    },
    search() {
      // Items have already been loaded
      if (this.item.length > 0) return;
      this.allBoutiques;
    },
  },

  created() {
    this.loading = true;
    this.boutiques();
    this.$store
      .dispatch("depot/FETCH_ALL_DEPOTS")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

     mounted() {
    this.axiosList();
     },

  methods: {
    ...mapActions({
      boutiques: "boutique/FETCH_ALL_BOUTIQUES_CURRENT_USER",
      depots: "depot/FETCH_ALL_DEPOTS",
      addNewDepot: "depot/ADD_DEPOT",
      updateDepot: "depot/UPDATE_DEPOT",
      deleteDepot: "depot/DELETE_DEPOT",
    }),

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },
    async axiosList() {
      this.loading = true;
      let token = JSON.parse(window.localStorage.getItem("conUser"))
     let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));

      await axios
        .get(
          APIENDPOINT +
            "/api/depot/user/page/" +
            boutiqueId.idBoutique+ 
            "/"+
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.depot = response.data;
            console.log(this.depot)
            if (this.depot.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let token = JSON.parse(window.localStorage.getItem("conUser"))
     let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      if (this.searchDepot) {
        await axios
          .get(
            APIENDPOINT +
              "/api/depot/user/page/" +
              boutiqueId.idBoutique+
              "/"+
              this.current_pag +
              "/" +
              this.searchDepot,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.depot = response.data;

              if (this.depot.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
          console.log(error);
        });
      } else {
        this.axiosList();
      }
    },
    editItem(item) {
      this.editedIndex = 0
      this.editedItem = Object.assign({}, item);
      this.modelSelectedBoutique = this.editedItem.boutique;
      this.dialog = true;
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t("depot.msg1") +
          item.libelle +
          this.$t("depot.msg2") +
          this.$t("depot.msg3");
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteDepot(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t("depot.warning1");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("depot.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.axiosList() 
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.modelSelectedBoutique = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        await this.updateDepot(this.editedItem)
          .then(() => {
            this.loading = false;
            var msg = this.$t("depot.warning3");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("depot.warning4");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
         this.axiosList()
        this.close();
      } else {
        if (this.$refs.formDepot.validate()) {
          this.loading = true;
          let items = {};
          //const Caisse = this.editedItem;
          items = Object.assign(this.editedItem);
          items.boutique = this.modelSelectedBoutique.idBoutique;

          await this.addNewDepot(items)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              var msg = this.$t("depot.warning5");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
            })
            .catch(() => {
              this.loading = false;
              var msg = this.$t("depot.warning6");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
            });
        }
         this.axiosList()
        this.close();
      }
    },
  },
};
</script>
<style>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>