import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  type_factures: [],
};

const getters = {
  getAllType_factures: (state) => state.type_factures,
};

const actions = {
  FETCH_ALL_TYPE_FACTURES: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/type_factures", {
          timeout: 30000,
        })
        .then(function(response) {
          const type_factures = response.data;
          commit("ALL_TYPE_FACTURES", type_factures);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_TYPE_FACTURES: ({ commit }, type_facture) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/type_factures", type_facture, {
          timeout: 3000,
        })
        .then(function(response) {
          const type_facture = response.data;
          commit("NEW_TYPE_FACTURE", type_facture);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  UPDATE_TYPE_FACTURES: ({ commit }, type_facture) => {
    return new Promise((resolve, reject) => {
      instance
        .put(
          APIENDPOINT + "/api/type_factures/" + type_facture.idTypeFacture,
          type_facture,
          {
            timeout: 3000,
          }
        )
        .then(function(response) {
          const an_new_value = response.data;
          commit("REFRESH_TYPE_FACTURE", an_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_TYPE_FACTURES: ({ commit }, type_facture) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(
          APIENDPOINT + "/api/type_factures/" + type_facture.idTypeFacture,
          {
            timeout: 3000,
          }
        )
        .then(function() {
          commit("REFRESH_DELETE", type_facture);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_TYPE_FACTURES: (state, type_facture) => {
    state.type_factures = type_facture;
  },

  NEW_TYPE_FACTURE: (state, type_facture) => {
    state.type_factures.push(type_facture);
  },

  REFRESH_TYPE_FACTURE: (state, an_new_value) => {
    const index = state.type_factures.findIndex(
      (x) => x.codeAn == an_new_value.idTypeFacture
    );
    Object.assign(state.type_factures[index], an_new_value);
  },

  REFRESH_DELETE: (state, an_del_value) => {
    const index = state.type_factures.findIndex(
      (x) => x.codeAn == an_del_value.idTypeFacture
    );
    state.type_factures.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
