<template>
  <v-snackbar v-model="snack.show" color="success" multi-line right :timeout="timeout" top>
    <span v-html="snack.text"></span>
    <v-btn dark text @click="snack.show = false">
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SuccessSnackBar",
  data: () => ({
    content: "",
    show: false,
    timeout: 3000
  }),
  created() {},
  computed: {
    ...mapState({
      snack: state => state.snackbar.snackSuccess
    })
  }
};
</script>