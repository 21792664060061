<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Factures Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-divider></v-divider>

    <v-dialog v-model="newchauff" scrollable persistent max-width="500">
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text style="height: 200px;">
            <v-container grid-list-md>
              <v-form ref="formChauffeur">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="nomChaufeur"
                      :label="$t('reception.name')"
                      clearable
                      prepend-inner-icon="mdi-account"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="contactChauffeur"
                      :label="$t('reception.contact')"
                      type="number"
                      required
                      prepend-inner-icon="mdi-phone"
                      clearable
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              color="red accent-4"
              @click="dialog.value = false"
              dark
            >
              <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="blue accent-4"
              @click="savechauffeur()"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <!-- Début dialog de payement d'une facture -->
                <v-dialog
                  v-model="dialogView"
                  scrollable
                  persistent
                  max-width="700"
                >
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{
                        $t("livraison.make")
                      }}</span>
                    </v-card-title>

                    <v-card-text style="height: 400px;">
                      <v-container grid-list-md>
                        <v-form ref="formPayer">
                          <v-layout wrap>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.num") }}:
                                <h3 class="black--text">
                                  {{ showItem.numeroFacture }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span
                                v-if="showItem.commande.client"
                                class="ml-2"
                              >
                                {{ $t("facture.customer3") }}:
                                <h2 class="black--text ml-2">
                                  {{ showItem.commande.client.nom }}
                                </h2>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.type") }}:
                                <h3 class="red--text">
                                  {{ showItem.typeFacture.libelle }}
                                </h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-2">
                              <span>
                                {{ $t("bill.amount") }}:
                                <h2 class="red--text" id="total" name="total">
                                  {{
                                    showItem.montantFacture - showItem.remise
                                  }}
                                  FCFA
                                </h2>
                              </span>
                            </v-col>
                            <v-flex xs6 sm10 md10>
                              <v-autocomplete
                                v-model="modelSelectedChaufeur"
                                :items="matchedChauffeurs"
                                item-text="nom"
                                item-value="nom"
                                :label="$t('reception.name')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex>
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="indigo"
                                @click="newchauff = true"
                              >
                                <v-icon dark>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </v-flex>
                            <!-- <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="matchedDepot"
                                item-text="libelle"
                                @blur="calculReste"
                                item-value="idDepot"
                                :label="$t('livraison.store')"
                                return-object
                                required
                              ></v-autocomplete>
                            </v-flex> -->
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                        :disabled="isValidPayement"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ $t("livraison.save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog de payement d'une facture -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchFacture"
                append-icon="mdi-magnify"
                :label="$t('facture.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <template>
        <v-data-table
          :headers="headers"
          :items="itemList"
          :loading="loading"
          :search="searchFacture"
          sort-by="commande.client.nom"
          class="elevation-1"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{
                $t("datatable-title.livraison.listelivraisonfacture")
              }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-icon>mdi-file-document-box-multiple-outline</v-icon>
              </td>
              <td>{{ item.numeroFacture }}</td>
              <td v-if="item.commande.client">
                {{ item.commande.client.nom }}
              </td>
              <td v-else></td>
              <td>{{ item.montantFacture - item.remise + " FCFA" }}</td>
              <td>
                <template v-if="item.solde">
                  <v-chip color="blue" text-color="white">
                    {{ $t("yes") }}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip color="red" text-color="white">
                    {{ $t("no") }}
                  </v-chip>
                </template>
              </td>
              <td>{{ item.reste + " FCFA" }}</td>
              <td>{{ convertDate(item.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="checkCommande(item)"
                      v-on="on"
                      >mdi-truck-delivery</v-icon
                    >
                  </template>
                  <span>{{ $t("livraison.make") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small @click="showFacture(item)" v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("facture.see") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <div>
          <v-pagination
            v-if="searchFacture"
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosSearch"
            circle
          >
          </v-pagination>
          <v-pagination
            v-else
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosList"
            circle
          >
          </v-pagination>
        </div>
      </template>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
//import $ from "jquery";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
//import PageTitle from "../components/titles/PageTitle";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import axios from "axios";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    //PageTitle,
  },
  data: () => ({
    mask: "########",
    searchFacture: "",
    storage: window.localStorage,
    facture: null,
    dialog: false,
    current_pag: 1,
    Factures: {},
    dialogView: false,
    dialogOneFac: false,
    modelSelectedDepot: null,
    modelSelectedChaufeur: null,
    newchauff: false,
    nomChaufeur: "",
    contactChauffeur: "",
    telephone: "",
    loading: false,
    search: null,
    inputRemise: false,

    articles: new Array(),
    ajoutArticle: new Array(),
    //ajouter: false,
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    quantite: "",
    quantiteArt: 0,
    reste: 0,
    oldRemise: 0,
    newRemise: 0,
    editedItem: {
      facture: 0,
      nomChaufeur: "",
      contactChauffeur: "",
    },
    defaultItem: {
      facture: 0,
      nomChaufeur: "",
      contactChauffeur: "",
    },
    showItem: {
      idFacture: 0,
      commande: {
        client: {
          idClient: 0,
          nom: "",
        },
      },
      file: "",
      montantFacture: 0,
      remise: 0,
      solde: false,
      typeFacture: {
        idTypeFacture: 0,
        libelle: "",
      },
      createdAt: "",
    },
  }),

  computed: {
    headers() {
      return [
        { text: "", value: "" },
        {
          text: this.$t("datatable-header.facture.idfacture"),
          align: "left",
          sortable: false,
          value: "numeroFacture",
        },
        {
          text: this.$t("datatable-header.facture.client"),
          value: "commande.client.nom",
        },
        { text: this.$t("datatable-header.facture.totalfacture"), value: "" },
        { text: this.$t("datatable-header.facture.etat"), value: "acompte" },
        { text: this.$t("datatable-header.facture.reste"), value: "reste" },
        { text: this.$t("datatable-header.facture.date"), value: "createdAt" },
        { text: "Actions", value: "action", sortable: true },
      ];
    },
    total_page() {
      if (this.Factures && this.Factures.last_page) {
        return this.Factures.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.Factures != null && this.Factures.data) {
        return this.Factures.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.Factures && this.Factures.last_page) {
        return this.Factures.last_page;
      } else {
        return 0;
      }
    },
    matchedChauffeurs() {
      if (this.allChauffeur != null) {
        return this.allChauffeur;
      } else {
        return [];
      }
    },
    ...mapGetters({
      gettoken: "login/gettoken",
      _role: "login/getUrole",
      allFacturesSolde: "facture/getAllFacturesSolde",
      allChauffeur: "chauffeur/getAllChauffeur",
      allDepots: "depot/getAllDepotsBoutique",
    }),

    matchedDepot() {
      //if (!this.modelSelectedArticles) return [];
      return this.allDepots;
    },

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("bill.customer")
        : this.$t("bill.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("facture.bill3")
        : this.$t("facture.update1");
    },

    itemC() {
      return this.allDepots.map((depot) => {
        const libelles = depot.libelle;
        return Object.assign({}, depot, { libelles });
      });
    },

    isValidPayement() {
      if (this.modelSelectedChaufeur == null) return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");
    this.$store.dispatch("depot/FETCH_ALL_DEPOTS_BOUTIQUE");
    this.$store
      .dispatch("facture/FETCH_ALL_FACTURE_NOT_PROFORMAT")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  mounted() {
    this.axiosList();
  },

  methods: {
    ...mapActions({
      depots: "depot/FETCH_ALL_DEPOTS_BOUTIQUE",
      livrerFacture: "facture/LIVRER_FACTURE",
      chauffeurs: "chauffeur/FETCH_ALL_CHAUFFEUR",
      addnewchauffeur: "chauffeur/ADD_CHAUFFEUR",
    }),
    savechauffeur() {
      const chauff = {};
      chauff.nom = this.nomChaufeur;
      chauff.telephone = this.contactChauffeur;
      chauff.boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
      this.addnewchauffeur(chauff).then(() => {
        this.newchauff = false;
        this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");
      });
    },
    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/facture/livre/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.Factures = response.data;
            if (this.Factures.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchFacture) {
        await axios
          .get(
            APIENDPOINT +
              "/api/facture/livre/search/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchFactures,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.Factures = response.data;

              if (this.Factures.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY-MM-DD hh:mm:ss");
      } else {
        convert_date = moment(String(date)).format("YYYY-MM-DD à HH:mm:ss");
        // convert_date = moment(String(date)).format("DD-MM-YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    calculReste() {
      const rest =
        parseInt(this.editedItem.montant) -
        parseInt(this.editedItem.montantPaye);
      this.reste = rest;
    },

    async showFacture(item) {
      await instance
        .get(item.file, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    payementFacture(item) {
      this.editedIndex = 0;
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },

    closePayement() {
      this.dialogView = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async downloadFacture(item) {
      await instance
        .get(APIENDPOINT + "/e2v/api/downloadFile/" + item.codeFac, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    getTotalOneFacture(facture) {
      let somme = 0;
      facture.analyseList.forEach((element) => {
        somme += !element.netPayBeneficiaire
          ? element.price
          : element.netPayBeneficiaire;
      });
      return somme - facture.remise;
    },

    close() {
      this.dialogView = false;
      setTimeout(() => {
        this.editedItem.montantPaye = "";
        this.modelSelectedDepot = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async checkCommande(item) {
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(APIENDPOINT + "/api/check/commande/" + item.idFacture, {
          headers: {
            Authorization: `Bearer ${token.token} `,
          },
        })
        .then((response) => {
          if (response.data) {
            this.payementFacture(item);
          }
        })
        .catch((error) => {
          // console.log(error.response.data);
          var msg = error.response.data;
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg,
          });
        });
    },

    async save() {
      if (this.$refs.formPayer.validate()) {
        this.loading = true;
        let items = {};

        items.facture = this.showItem.idFacture;
        items.chauffeure = this.modelSelectedChaufeur.id;
        // items.depot = this.modelSelectedDepot.idDepot;

        console.log(items);

        await this.livrerFacture(items)
          .then(() => {
            this.loading = false;
            this.close();
            this.editedItem = Object.assign({}, this.defaultItem);
            var msg = this.$t("livraison.success");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
            this.$store
              .dispatch("facture/FETCH_ALL_FACTURE_NOT_PROFORMAT")
              .then(() => {
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.loading = false;
            this.close();
            var msg = this.$t("livraison.error");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });

        this.axiosList();
        this.close();
      }
    },
  },
};
</script>
<style>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
