<template>
  <v-card outlined >
    <!-- <v-card-title>Item</v-card-title> -->
    <v-list-item three-line>
      <v-list-item-avatar title size="50">
        <v-icon size="40" :color="color">{{ icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="overline mb-4 text-truncate" style="max-width:410px"> {{libelle +"-"+ name }}</div>
        <v-list-item-title class="headline mb-1">
          {{ nbr }}
          <small>{{ quantification }}</small>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    libelle:{type:String, required:false},
    color: { type: String, required: true },
    icon: { type: String, required: true },
    name: { type: String, required: true },
    nbr: { type: Number, required: true },
    quantification: { type: String, default: "" }
  },
  data: () => ({})
};
</script>