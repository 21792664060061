var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-treeview',[(_vm.isAccueil)?[_vm._l((_vm.menuItemsAccueil),function(menuItem){return [(!menuItem.sub_menu)?_c('v-btn',{key:menuItem.a,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:menuItem.a,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item){return _c('v-list-item',{key:item.b},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e()],2),(_vm.ismobile == true)?_c('v-treeview',{staticClass:"mt-4",attrs:{"hoverable":""}},[(_vm.isVendeur)?[_vm._l((_vm.menuItemsVendeur),function(menuItem,i){return [(!menuItem.sub_menu)?_c('v-btn',{key:i,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:i,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e(),(_vm.isCaissier)?[_vm._l((_vm.menuItemsCaissier),function(menuItem,i){return [(!menuItem.sub_menu)?_c('v-btn',{key:i,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:i,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e(),(_vm.isAdmin)?[_vm._l((_vm.menuItemsAdmin),function(menuItem,k){return [(!menuItem.sub_menu)?_c('v-btn',{key:k + menuItem.to,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:k,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e(),(_vm.isGerant)?[_vm._l((_vm.menuItemsGerant),function(menuItem,l){return [(!menuItem.sub_menu)?_c('v-btn',{key:l,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:l,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e()],2):_vm._e(),_c('v-treeview',{staticClass:"mt-4",attrs:{"hoverable":""}},[(_vm.isMagasinier)?[_vm._l((_vm.menuItemsMagasinier),function(menuItem,i){return [(!menuItem.sub_menu)?_c('v-btn',{key:i,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:i,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e()],2),_c('v-treeview',{staticClass:"mt-4",attrs:{"hoverable":""}},[(_vm.isGerant)?[_vm._l((_vm.menuItemsGerant),function(menuItem,l){return [(!menuItem.sub_menu)?_c('v-btn',{key:l,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:l,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e()],2),(_vm.ismobile == true)?_c('v-treeview',{staticClass:"mt-4",attrs:{"hoverable":""}},[(_vm.isDirector)?[_vm._l((_vm.menuItemsDirector),function(menuItem,k){return [(!menuItem.sub_menu)?_c('v-btn',{key:k,attrs:{"to":menuItem.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1):_vm._e(),(menuItem.sub_menu)?_c('v-menu',{key:k,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.title)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',[_c('v-subheader',{attrs:{"inset":""}},[_vm._v("Menu "+_vm._s(menuItem.title))]),_vm._l((menuItem.sub_menu),function(item,j){return _c('v-list-item',{key:j},[_c('v-list-item-title',[_c('v-btn',{attrs:{"to":item.to,"text":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)],1)})],2)],1)],1):_vm._e()]})]:_vm._e()],2):_vm._e(),_c('v-treeview',{staticClass:"mt-4",attrs:{"hoverable":""}},[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-earth ")])],1)]}}])},[_c('v-list',[_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","width":"100%"},on:{"click":_vm.changedtofr}},[_vm._v(" Français ")])],1),_c('v-list-item-title',[_c('v-btn',{attrs:{"text":"","width":"100%"},on:{"click":_vm.changedtoen}},[_vm._v(" English ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }