<template>
  <v-snackbar v-model="snack.show" color="error" multi-line right :timeout="timeout" top>
    <span v-html="snack.text"></span>
    <v-btn dark text @click="snack.show = false">
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ErrorSnackBar",
  data: () => ({
    content: "",
    show: false,
    timeout: 6000
  }),
  created() {
    /*this.$store.watch(
      state => state.snackbar.snackError,
      () => {
        const msg = this.msg_snack;
        if (msg !== "") {
          this.show = true;
          this.content = this.msg_snack;
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {"show":false, "text": ""});
        }
      }
    );*/
  },
  computed: {
    ...mapState({
      msg_snack: state => state.snackbar.snackError,
      snack: state => state.snackbar.snackError
    })
  }
};
</script>