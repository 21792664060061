import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  payements: [],
};

const getters = {
  getAllPayements: (state) => state.payements,
};

const actions = {
  FETCH_ALL_PAYEMENTS: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/paiements_not_deleted", {
          timeout: 30000,
        })
        .then(function(response) {
          const payements = response.data;
          commit("ALL_PAYEMENTS", payements);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_PAYEMENTS_BOUTIQUE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/paiements_boutique/" + boutiqueId.idBoutique, {
          timeout: 30000,
        })
        .then(function(response) {
          const payements = response.data;
          commit("ALL_PAYEMENTS", payements);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_PAYEMENTS_USER: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/paiements_caisse_connect", {
          timeout: 30000,
        })
        .then(function(response) {
          const payements = response.data;
          commit("ALL_PAYEMENTS", payements);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_PAYEMENTS: ({ commit }, payement) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/paiements/livre", payement, {
          timeout: 3000,
        })
        .then(function(response) {
          const payement = response.data;
          commit("NEW_PAYEMENT", payement);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  UPDATE_PAYEMENTS: ({ commit }, payement) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/payements/" + payement.idPayement, payement, {
          timeout: 3000,
        })
        .then(function(response) {
          const an_new_value = response.data;
          commit("REFRESH_PAYEMENT", an_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_PAYEMENTS: ({ commit }, payement) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/payements/" + payement.idPayement, {
          timeout: 3000,
        })
        .then(function() {
          commit("REFRESH_DELETE", payement);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_PAYEMENTS: (state, payement) => {
    state.payements = payement;
  },

  NEW_PAYEMENT: (state, payement) => {
    state.payements.push(payement);
  },

  REFRESH_PAYEMENT: (state, an_new_value) => {
    const index = state.payements.findIndex(
      (x) => x.idPayement == an_new_value.idPayement
    );
    Object.assign(state.payements[index], an_new_value);
  },

  REFRESH_DELETE: (state, an_del_value) => {
    const index = state.payements.findIndex(
      (x) => x.idPayement == an_del_value.idPayement
    );
    state.payements.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
