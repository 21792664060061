import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import 'jquery/dist/jquery.js'
import reactiveStorage from 'vue-reactive-storage';
import VueTheMask from 'vue-the-mask'
import i18n from './i18n'
import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 30000000000, // 3 seconds
  startAtIdle: false
});

Vue.use(VueTheMask)
Vue.use(reactiveStorage, {
  user: {}
});


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
