import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  factures: [],
  livraison: [],
  facture: {},
};

const getters = {
  getAllFactures: (state) => state.factures,
  getAllFacturesNonSolde: (state) => state.factures,
  getAllFacturesSolde: (state) => state.factures,
  getAllLivraison: (state) => state.livraison,
  getOneFacture: (state) => state.facture,
};

const actions = {
  FETCH_ALL_FACTURE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/factures_boutique/" + boutiqueId.idBoutique)
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  }, //

  FETCH_ONE_FACTURE: ({ commit }, iDFacture) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/factures/commande/detail/" + iDFacture)
        .then(function(response) {
          const facture = response.data;
          commit("ONE_FACTURE", facture);
          // console.log(facture.articles[0].articles)
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  }, //

  FETCH_ALL_FACTURE_NON_SOLDE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/factures_boutique_non_solde/" +
            boutiqueId.idBoutique
        )
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_FACTURE_SOLDE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/factures_boutique_solde/" + boutiqueId.idBoutique
        )
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_FACTURE_SOLDE_USER: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/factures_boutique_solde_user/" +
            boutiqueId.idBoutique
        )
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_FACTURE_NON_LIVRE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT +
            "/api/factures_boutique_non_livre/" +
            boutiqueId.idBoutique
        )
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_FACTURE_USER: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/factures_boutique_user/" + boutiqueId.idBoutique
        )
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_FACTURE_NOT_PROFORMAT: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(
          APIENDPOINT + "/api/factures_not_proformat/" + boutiqueId.idBoutique
        )
        .then(function(response) {
          const factures = response.data;
          commit("ALL_FACTURE", factures);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_LIVRAISON: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/livraisons_caisse/")
        .then(function(response) {
          const livraison = response.data;
          commit("ALL_LIVRAIISON", livraison);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_LIVRAISON_BOUTIQUE: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));

    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/livraisons_boutique/" + boutiqueId.idBoutique)
        .then(function(response) {
          const livraison = response.data;
          commit("ALL_LIVRAIISON", livraison);

          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_FACTURE: ({ commit }, items) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/factures_generate", items, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          const f = "nothing";
          commit("NEW_FACTURE", f);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          resolve();
        })
        .catch(function(err) {
          //console.log("Error:"+err)
          reject(err);
        });
    });
  },
  // eslint-disable-next-line
  UPDATE_FACTURE: ({}, items) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/factures/update/" + items.id, items, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          resolve();
        })
        .catch(function(err) {
          console.log("Error:"+err)
          reject(err);
        });
    });
  },

  LIVRER_FACTURE: ({ commit }, items) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/livraisons_liv", items)
        .then(function(response) {
          const p_new_value = response.data;
          commit("REFRESH_FACTURE", p_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log("Error:"+err)
          reject(err);
        });
    });
  },

  // UPDATE_FACTURE: ({ commit }, items) => {
  //     return new Promise((resolve, reject) => {
  //         instance.put(APIENDPOINT + '' + items.codeFac, items)
  //             .then(function (response) {
  //                 const p_new_value = response.data
  //                 commit('REFRESH_FACTURE', p_new_value)
  //                 resolve();
  //             })
  //             .catch(function (err) {
  //                 //console.log(err)
  //                 reject(err);
  //             })
  //     })
  // },

  DELETE_FACTURE: ({ commit }, items) => {
    //console.log(items)
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/factures/" + items.idFacture)
        .then(function() {
          commit("REFRESH_DELETE", items);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
  ANNULER_LIV: ({ commit }, items) => {
    //console.log(items)
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/livraisons/annulation/" + items.idLivraison)
        .then(function() {
          commit("NEW_FACTURE");
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_FACTURE: (state, f) => {
    state.factures = f;
  },
  ONE_FACTURE: (state, f) => {
    state.facture = f;
  },
  ALL_LIVRAIISON: (state, f) => {
    state.livraison = f;
  },
  // eslint-disable-next-line
  NEW_FACTURE: (state, f) => {
    //console.log(f);
  },

  REFRESH_FACTURE: (state, an_new_value) => {
    const index = state.factures.findIndex(
      (x) => x.codeAn == an_new_value.codeAn
    );
    Object.assign(state.factures[index], an_new_value);
  },

  REFRESH_DELETE: (state, an_del_value) => {
    const index = state.factures.findIndex(
      (x) => x.idFacture == an_del_value.idFacture
    );
    state.factures.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
