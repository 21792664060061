import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  boutiques: [],
  boutique:[]
};

const getters = {
  getAllBoutiques: (state) => state.boutiques,
  getAllBoutiquesCurrentUser: (state) => state.boutique,
};

const actions = {
  FETCH_ALL_BOUTIQUES: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/boutiques_not_deleted", {
          timeout: 3000,
        })
        .then(function(response) {
          const boutiques = response.data;
          commit("ALL_BOUTIQUE", boutiques);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  FETCH_ALL_BOUTIQUES_CURRENT_USER: ({ commit }) => {
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/boutique_current_user", {
          timeout: 3000,
        })
        .then(function(response) {
          const boutique = response.data;
          commit("BOUTIQUE_USER", boutique);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  ADD_BOUTIQUE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/boutiques", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const boutique = response.data;
          commit("NEW_BOUTIQUE", boutique);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  UPDATE_BOUTIQUE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/boutiques/" + c.idBoutique, c)
        .then(function(response) {
          const c_new_value = response.data;
          commit("REFRESH_BOUTIQUE", c_new_value);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },

  DELETE_BOUTIQUE: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/boutiques/" + c.idBoutique)
        .then(function() {
          commit("REFRESH_DELETE", c);
          resolve();
        })
        .catch(function(err) {
          //console.log(err)
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_BOUTIQUE: (state, boutiques) => {
    state.boutiques = boutiques;
  },
  BOUTIQUE_USER:(state, boutique)=>
  {
    state.boutique= boutique;
  },
  ALL_USER_BOUTIQUE: (state, boutiques) => {
    state.boutiques = boutiques;
  },

  NEW_BOUTIQUE: (state, boutique) => {
    state.boutiques.push(boutique);
  },

  REFRESH_BOUTIQUE: (state, c_new_value) => {
    const index = state.boutiques.findIndex(
      (x) => x.idBoutique == c_new_value.idBoutique
    );
    Object.assign(state.boutiques[index], c_new_value);
  },

  REFRESH_DELETE: (state, c_del_value) => {
    const index = state.boutiques.findIndex(
      (x) => x.idBoutique == c_del_value.idBoutique
    );
    state.boutiques.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
