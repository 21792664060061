<template>
<v-layout>
   <template v-if="dialog_parameter">
      <ParameterDialog :value="dialog_parameter" />
    </template>
  <template>
    <v-dialog v-model="dialog" scrollable persistent max-width="700">
        <v-card>
          <v-card-title class="headline blue justify-center">
            <span class="headline white--text">{{ $t('sidebar.change') }}</span>
          </v-card-title>

          <v-card-text style="height: 150px;">
            <v-container grid-list-md>
              <v-form ref="formBoutique">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-autocomplete
                      v-model="modelSelectedBoutique"
                      :items="allBoutiqueForUser"
                      item-text="nom"
                      item-value="idBoutique"
                      :label="$t('login.choose1')"
                      :placeholder="$t('login.choose2')"
                      prepend-icon="mdi-database-search"
                      return-object
                    ></v-autocomplete>
                  </v-flex>
                            
                </v-layout>
              </v-form>
                  <small>{{ $t('login.desc') }}</small>
            </v-container>
          </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                  <v-icon left>mdi-close-circle-outline</v-icon>{{ $t('cancel') }}
                </v-btn>
                <v-btn
                  class="ma-2"
                  tile
                  outlined
                  color="blue accent-4"
                  @click="open"
                  :disabled="isValidBoutique"
                >
                  <v-icon>mdi-content-save</v-icon>
                  {{ $t('login.open') }}
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <v-spacer></v-spacer>
    <v-btn color="pink" darck icon @click.stop="drawer = !drawer">
    <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </template>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      right
      temporary
      app
    >
      <v-list-item>
        <v-list>
          <v-list-item class="px-2">
            <v-avatar class="teal darken-1">
              <span class="text-uppercase">{{usersInitials}}</span>
            </v-avatar>
          </v-list-item>
        </v-list>
        <v-list-item-content>
          <v-list-item-title>{{useridentifier}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item>
          <InSidebar :role="_role"/>
        </v-list-item>
      </v-list>
          <v-list
          nav
          dense
        >
          <v-list-item v-if="role" @click="changerBoutique">
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-arrow-left-circle-outline</v-icon>
                </template>
                <span>{{ $t('sidebar.change1') }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('sidebar.change1') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-logout</v-icon>
                </template>
                <span>{{ $t('sidebar.outshop') }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('sidebar.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import ParameterDialog from "../dialog/ParameterDialog";
import InSidebar from "./InSidebar.vue"
import i18n from '../../i18n'

export default {
  components: {
    ParameterDialog,
    InSidebar
  },
  data: () => ({
    drawer: null,
    dialog: false,
    modelSelectedBoutique: null,
    storage: window.localStorage,
    connected_user: null,
    dialog_parameter: false,
    role: false,
  }),
  computed: {
    ...mapGetters({
      allBoutiqueForUser: "boutique/getAllBoutiquesCurrentUser",
      _role: "login/getUrole",
    }),
   
 
    useridentifier()
    {
      const connected_user = JSON.parse(this.storage.user);
      return (
        connected_user.nom +
        " " +
        connected_user.prenom
      );
    },
    usersInitials() {
      const connected_user = JSON.parse(this.storage.user);
      
      return (
        connected_user.nom.substring(0, 1) +
        "" +
        connected_user.prenom.substring(0, 1)
      );
    },

    isValidBoutique() {
      if (this.modelSelectedBoutique === null) return true;
      else return false;
    }
  },

  created() {
    this.loading = true;
    let user = JSON.parse(window.localStorage.getItem('user'));
    //let roleid = user.role.idRole;
    const isObjectPresent = user.role.find((o) => o.idRole === 2);
    if(isObjectPresent){
      this.role = true
    }
    this.$store
      .dispatch("boutique/FETCH_ALL_BOUTIQUES_CURRENT_USER")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  methods: {
    logout: function() {
      let response = confirm(this.$t('sidebar.logouttext'));
      if (response) {
        this.$store.dispatch("login/LOGOUT_AUTH");
        this.$router.push(`/${i18n.locale}/login`).catch(()=>{});
      }
    },

    changerBoutique: function() {
      this.dialog = true;
    },

    open() {
      var app = this;
      window.localStorage.removeItem("boutiqueId");
      window.localStorage.setItem("boutiqueId", JSON.stringify(this.modelSelectedBoutique));
      
      if(app.$router.push(`/${i18n.locale}/app/accueil`).catch(()=>{})){
        this.dialog = false;
        const msg = `"${this.$t('sidebar.msg')} "+ this.modelSelectedBoutique.nom +" "`;
        this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
          show: true,
          text: msg
        });
        window.location.reload(true);
      }
      
    },

    close() {
      this.dialog = false;
    }
  }
};
</script>
