<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="imprimerArticles"
                  >
                    <v-icon left>mdi-plus-circle-outline</v-icon
                    >{{ $t("item.print") }}
                  </v-btn>
                  <!-- Fin Dialog de suppression   -->
                </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model.lazy="searchArticle"
                append-icon="mdi-magnify"
                :label="$t('item.search')"
                @input="axiosSearch"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container>
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchArticle"
        sort-by="nom"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{
              $t("datatable-title.article.listarticlesinstock")
            }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.article.designation }}</td>
            <td>{{ item.article.prix }}</td>
            <td>{{ item.article.prixAchat }}</td>
            <td>{{ item.article.categorie.libelle }}</td>
            <td>{{ item.article.fournisseur.raisonSociale }}</td>
            <td>{{ item.depot.libelle }}</td>
            <td>{{ item.quantiteEntree }}</td>
            <td>{{ item.quantiteSortie }}</td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-if="searchArticle"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import moment from "moment";
import { mask } from "vue-the-mask";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchArticle: "",
    searchC: "",
    searchD: "",
    article: {},
    dialog: false,
    current_pag: 1,
    isLoading: false,
    dialogView: false,
    snackbar: false,
    loading: false,
    search: null,
    dialog_save: false,
    valid: true,
    select: null,
    editedIndex: -1,
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("item.name"),
          align: "left",
          sortable: true,
          value: "article.designation",
        },
        { text: this.$t("datatable-header.article.prix"), value: "prix" },
        {
          text: this.$t("datatable-header.article.prixAchat"),
          value: "prixAchat",
        },
        {
          text: this.$t("datatable-header.article.categorie"),
          value: "article.categorie.libelle",
          sortable: true,
        },
        {
          text: this.$t("datatable-header.article.fournisseur"),
          value: "article.fournisseur.raisonSociale",
        },
        {
          text: this.$t("datatable-header.article.depot"),
          value: "depot.libelle",
        },
        {
          text: this.$t("datatable-header.article.quantiteEntree"),
          value: "quantiteEntree",
        },
        {
          text: this.$t("datatable-header.article.quantiteSortie"),
          value: "quantiteSortie",
        },
      ];
    },

    total_page() {
      
      if (this.article && this.article.last_page) {
        return this.article.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.article != null && this.article.data) {
        return this.article.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.article && this.article.last_page) {
        return this.article.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      allPaginates: "articles/getAllPaginates",
      allArticles: "articles/getAllArticles",
      allCategories: "categorie/getAllCategories",
      allFournisseurs: "fournisseur/getAllFournisseurs",
      allDepots: "depot/getAllDepots",
    }),
  },

  mounted() {
    this.axiosList();
  },
  methods: {
    ...mapActions({
      depots: "depot/FETCH_ALL_DEPOTS",
      categories: "categorie/FETCH_ALL_CATEGORIES",
      articles: "articles/FETCH_ALL_ARTICLES_IN_STOCK",
      imprimer: "articles/IMPRIMER",
      imprimerAll: "articles/IMPRIMER_ALL",
      imprimerinstock:"articles/IMPRIMER_INSTOCK"
    }),

    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      await axios
        .get(
          APIENDPOINT +
            "/api/entree/boutique/stock/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.article = response.data;
            if (this.article.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      if (this.searchArticle) {
        await axios
          .get(
            APIENDPOINT +
              "/api/search/entree/boutique/stock/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchArticle,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;

              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
          console.log(error);
        });
      } else {
        this.axiosList();
      }
    },
    imprimerArticles()
    {
      this.imprimerinstock()
    },
    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },
  },
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>