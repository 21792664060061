<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />

    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-spacer></v-spacer>
              <v-flex xs2 sm2 md2>
                <v-toolbar-title>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="imprimerArticles"
                  >
                    <v-icon left>mdi-plus-circle-outline</v-icon
                    >{{ $t("item.print") }}
                  </v-btn>
                  <!-- Fin Dialog de suppression   -->
                </v-toolbar-title>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs4 sm4 md4>
                <v-autocomplete
                  v-model="modelSelectedDepot"
                  :items="itemD"
                  @change="selectDepotchange"
                  item-text="libelle"
                  item-value="idDepot"
                  :label="$t('item.depot')"
                  append-icon="mdi-magnify"
                  single-line
                  @input="axiosSearch"
                  return-object
                ></v-autocomplete>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex xs4 sm4 md4>
                <v-autocomplete
                  v-model="modelSelectedCategorie"
                  :items="itemC"
                  item-text="libelle"
                  item-value="idCategorie"
                  :label="$t('item.cat')"
                  append-icon="mdi-magnify"
                  single-line
                  @change="selectDepotchange"
                  @input="axiosSearch"
                  return-object
                ></v-autocomplete>
              </v-flex>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchArticle"
        sort-by="nom"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{
              $t("datatable-title.article.listarticles")
            }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.article.designation }}</td>
            <td>{{ item.article.prix }}</td>
            <td>{{ item.article.prixAchat }}</td>
            <td>{{ item.article.categorie.libelle }}</td>
            <td>{{ item.article.fournisseur.raisonSociale }}</td>
            <td>{{ item.depot.libelle }}</td>
            <td>{{ item.quantiteEntree }}</td>
            <td>{{ item.quantiteSortie }}</td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-if="searchArticle"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import moment from "moment";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import { mask } from "vue-the-mask";
import i18n from "../../i18n";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchArticle: "",
    searchC: "",
    searchD: "",
    article: {},
    current_pag: 1,
    loading: false,
    dialog: false,
    dialogView: false,
    snackbar: false,
    search: null,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    modelSelectedCategorie: {},
    modelSelectedDepot: {},
    delete_item: null,
    valid: true,
    select: null,
    editedIndex: -1,
    showItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },
    editedItem: {
      id: 0,
      article: {
        idArticle: 0,
        designation: "",
        prix: "",
        prixAchat: "",
        description: "",
      },
      boutique: {
        idBoutique: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      stockMin: 0,
      quantiteEntree: 0,
      quantiteSortie: 0,
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("item.name"),
          align: "left",
          sortable: true,
          value: "article.designation",
        },
        { text: this.$t("datatable-header.article.prix"), value: "prix" },
        {
          text: this.$t("datatable-header.article.prixAchat"),
          value: "prixAchat",
        },
        {
          text: this.$t("datatable-header.article.categorie"),
          value: "article.categorie.libelle",
          sortable: true,
        },
        {
          text: this.$t("datatable-header.article.fournisseur"),
          value: "fournisseur",
        },
        { text: this.$t("datatable-header.article.depot"), value: "depot" },
        {
          text: this.$t("datatable-header.article.quantiteEntree"),
          value: "quantiteEntree",
        },
        {
          text: this.$t("datatable-header.article.quantiteSortie"),
          value: "quantiteSortie",
        },
      ];
    },
    total_page() {

      if (this.article && this.article.last_page) {
        return this.article.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.article != null && this.article.data) {
        return this.article.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.article && this.article.last_page) {
        return this.article.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      allArticles: "articles/getAllArticles",
      allCategories: "categorie/getAllCategories",
      allFournisseurs: "fournisseur/getAllFournisseurs",
      allDepots: "depot/getAllDepots",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("item.save")
        : this.$t("item.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("item.save1")
        : this.$t("item.update1");
    },

    itemC() {
      return this.allCategories.map((categorie) => {
        const libelles = categorie.libelle;
        return Object.assign({}, categorie, { libelles });
      });
    },

    itemD() {
      return this.allDepots.map((depot) => {
        const libelles = depot.libelle;
        console.log(Object.assign(  {libelles}))
        //console.log(depot.libelle)
        return Object.assign({}, depot, { libelles });
      });
    },

    matchedCat() {
      //if (!this.modelSelectedArticles) return [];
      return this.allCategories;
    },

    isValidStock() {
      if (this.editedItem.quantite === "") return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    createDossierDialog(val) {
      val || this.close();
    },

    searchC() {
      // Items have already been loaded
      if (this.itemC.length > 0) return;
      this.allCategories;
    },
  },

  created() {
    this.loading = true;
    this.categories();
    this.$store.dispatch("depot/FETCH_ALL_DEPOTS");
    this.$store
      .dispatch("articles/FETCH_ALL_ARTICLES_STOCK")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },

  mounted() {
    this.axiosList();
  },

  methods: {
    ...mapActions({
      depots: "depot/FETCH_ALL_DEPOTS",
      categories: "categorie/FETCH_ALL_CATEGORIES",
      articles: "articles/FETCH_ALL_ARTICLES_STOCK",
      imprimer: "articles/IMPRIMER",
      imprimerAll: "articles/IMPRIMER_ALL",
    }),

    selectDepotchange() {
      this.$store.dispatch("articles/FETCH_ALL_DEPOT_STOCK", {
        idD: this.modelSelectedDepot.idDepot,
        idC: this.modelSelectedCategorie.idCategorie,
      });
    },

    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      let idD = this.modelSelectedDepot.idDepot;
      let idC = this.modelSelectedCategorie.idCategorie;

      if (idD === undefined && idC === undefined) {
        idD = 0;
        idC = 0;
        await axios
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC +
              "/" +
              this.current_pag,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;
              //console.log(this.article)
              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.loading = false;
      } else if (idD === undefined && idC !== undefined) {
        idD = 0;
        await axios
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC +
              "/" +
              this.current_pag,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;
              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.loading = false;
      } else if (idD !== undefined && idC === undefined) {
        idC = 0;
        await axios
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC +
              "/" +
              this.current_pag,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;
              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.loading = false;
      } else {
        await axios
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              idD +
              "/" +
              idC +
              "/" +
              this.current_pag,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;
              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.loading = false;
      }
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"))
      if (this.searchArticle) {
        await axios
          .get(
            APIENDPOINT +
              "/api/entrees/boutique/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchArticle,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.article = response.data;

              if (this.article.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY");
      }
      if (date != null) return convert_date;
      else return null;
    },

    editItem(item) {
      this.editedIndex = this.allArticles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.modelSelectedDepot = this.editedItem.depot;
      this.modelSelectedCategorie = this.editedItem.article.categorie;
      this.modelSelectedFournisseur = this.editedItem.article.fournisseur;
      this.dialog = true;
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t("item.msg1") +
          item.designation +
          this.$t("item.msg2") +
          this.$t("item.msg3");
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteArticle(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t("item.warning1");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("item.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.delete_item = null;
        this.delete_dialog = false;
      }
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.modelSelectedCategorie = null;
        this.modelSelectedFournisseur = null;
        this.modelSelectedDepot = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    close1() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    closeDetails() {
      this.dialogView = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.modelSelectedCategorie = null;
        this.modelSelectedFournisseur = null;
        this.modelSelectedDepot = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    createDossierDialog() {
      if (this.formAction !== this.$t("item.update1")) {
        if (this.$refs.formCaisse.validate()) {
          this.dialog_save = true;
        }
      } else {
        this.save();
      }
    },

    openCodeDossierDialog() {
      this.dialog_save = false;
    },

    saveWithDossier() {
      this.save();
    },

    updateStock(item) {
      
      this.editedIndex = this.allArticles.indexOf(item);
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },

    closeStock() {
      this.dialogView = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async imprimerArticles() {
      if (this.modelSelectedCategorie.idCategorie !== undefined) {
        this.loading = true;
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        this.loading = true;
        let depotId = this.modelSelectedDepot.idDepot;
        let idCat = this.modelSelectedCategorie.idCategorie;
        let items = {};
        items.idB = boutiqueId.idBoutique;
        items.idD = depotId;
        items.idC = idCat;

        await this.imprimer(items)
          .then(() => {
            this.loading = false;
            // var msg = this.$t("item.success1");
            // this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
            //   show: true,
            //   text: msg,
            // });
          })
          .catch(() => {
            this.loading = false;
            // var msg = this.$t("item.error1");
            // this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            //   show: true,
            //   text: msg,
            // });
          });
      } else {
        this.loading = true;
        let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
        this.loading = true;
        let items = {};
        items.idB = boutiqueId.idBoutique;

        await this.imprimerAll(items)
          .then(() => {
            this.loading = false;
            // var msg = this.$t("item.success1");
            // this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
            //   show: true,
            //   text: msg,
            // });
          })
          .catch(() => {
            this.loading = false;
            // var msg = this.$t("item.error1");
            // this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            //   show: true,
            //   text: msg,
            // });
          });
      }
      this.close1();
    },
  },
};
</script>
<style scoped>
.default
{
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>