<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Factures Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />

    <!-- Fin Components -->

    <v-divider></v-divider>
    <v-dialog v-model="newchauff" scrollable persistent max-width="500">
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text style="height: 200px;">
            <v-container grid-list-md>
              <v-form ref="formChauffeur">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="nomChaufeur"
                      :label="$t('reception.name')"
                      clearable
                      prepend-inner-icon="mdi-account"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="contactChauffeur"
                      :label="$t('reception.contact')"
                      type="number"
                      required
                      prepend-inner-icon="mdi-phone"
                      clearable
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ma-2"
              tile
              color="red accent-4"
              @click="dialog.value = false"
              dark
            >
              <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              tile
              outlined
              color="blue accent-4"
              @click.once="savechauffeur()"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog v-model="dialog" persistent max-width="1000px">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                    >
                      <v-icon left>mdi-file-document</v-icon
                      >{{ $t("reception.new") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue accent-4 justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container grid-list-md>
                        <v-form ref="formFacture">
                          <v-layout wrap>
                            <v-flex xs6 sm10 md10>
                              <v-autocomplete
                                v-model="modelSelectedChaufeur"
                                :items="matchedChauffeurs"
                                item-text="nom"
                                item-value="nom"
                                :label="$t('reception.name')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-spacer></v-spacer>
                            <v-flex>
                              <v-btn
                                class="mx-2"
                                fab
                                dark
                                color="indigo"
                                @click="newchauff = true"
                              >
                                <v-icon dark>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedFournisseur"
                                :items="itemF"
                                item-text="raisonSociale"
                                item-value="idFournisseur"
                                :label="$t('reception.fournisseur')"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedDepot"
                                :items="matchedDepot"
                                item-text="libelle"
                                item-value="idDepot"
                                :label="$t('reception.depot')"
                                :placeholder="$t('reception.choose2')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs6 sm6 md6>
                              <v-autocomplete
                                v-model="modelSelectedArticles"
                                :items="matchedArticles"
                                item-text="article.designation"
                                item-value="idArticle"
                                :label="$t('reception.article')"
                                :placeholder="$t('reception.choose1')"
                                prepend-icon="mdi-database-search"
                                return-object
                              ></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="quantite"
                                :label="$t('facture.quantity')"
                                :rules="[(v) => !!v || $t('facture.quantity1')]"
                                type="number"
                              >
                              </v-text-field>
                            </v-flex>

                            <template>
                              <v-row class="mb-2" justify="center">
                                <v-btn
                                  class="ma-2"
                                  tile
                                  color="blue accent-4"
                                  @click="ajout"
                                  dark
                                >
                                  <v-icon left>mdi-cart-arrow-down</v-icon
                                  >{{ $t("reception.add") }}
                                </v-btn>
                              </v-row>
                            </template>
                            <v-flex xs12 sm12 md12>
                              <template>
                                <v-expand-transition>
                                  <v-simple-table
                                    fixed-header
                                    height="200px"
                                    color="grey darken-1"
                                    dark
                                  >
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          {{ $t("facture.item1") }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t("facture.price") }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t("facture.quantity") }}
                                        </th>
                                        <th>{{ $t("delete") }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in ajoutArticle"
                                        :key="item.designation"
                                      >
                                        <td>{{ item.designation }}</td>
                                        <td>{{ item.prix }}</td>
                                        <td>{{ item.quantite }}</td>
                                        <td>
                                          <v-icon
                                            small
                                            @click="supprimerLigne(item)"
                                            >mdi-delete</v-icon
                                          >
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>
                                </v-expand-transition>
                              </template>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        tile
                        color="red accent-4"
                        @click="close"
                        dark
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon
                        >{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="blue accent-4"
                        @click.once="save"
                        :disabled="isValidFacture"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{
                      $t("deleting")
                    }}</v-card-title>
                    <v-card-text class="black--text mt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="red darken-1"
                        @click="delete_dialog = false"
                        text
                        >{{ $t("no") }}</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                        >{{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchReception"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('reception.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <template v-if="isDirector">
        <v-data-table
          :headers="headersAdmin"
          :items="itemList"
          :loading="loading"
          :search="searchReception"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ $t("reception.shp") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.depot.libelle }}</td>
              <td>{{ item.user.username }}</td>
              <td v-if="item.chauffeure != null">{{ item.chauffeure.nom }}</td>
              <td v-else>{{ item.nomChaufeur }}</td>
              <td v-if="item.chauffeure != null">
                {{ item.chauffeure.telephone }}
              </td>
              <td v-else>{{ item.contactChauffeur }}</td>
              <td>{{ convertDate(item.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="showReception(item)"
                      v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("reception.show") }}</span>
                </v-tooltip>
                <v-tooltip v-if="isDirector || isGerant" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="deleteItem(item, 0)"
                      v-on="on"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>{{ $t("reception.delete") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <v-pagination
          v-if="searchReception"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </template>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="itemList"
          :loading="loading"
          :search="searchReception"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>{{ $t("reception.shp") }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.depot.libelle }}</td>
              <td v-if="item.chauffeure != null">{{ item.chauffeure.nom }}</td>
              <td v-else>{{ item.nomChaufeur }}</td>
              <td v-if="item.chauffeure != null">
                {{ item.chauffeure.telephone }}
              </td>
              <td v-else>{{ item.contactChauffeur }}</td>
              <td>{{ convertDate(item.createdAt) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="showReception(item)"
                      v-on="on"
                      >mdi-eye</v-icon
                    >
                  </template>
                  <span>{{ $t("reception.show") }}</span>
                </v-tooltip>
                <v-tooltip v-if="isDirector || isGerant" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="deleteItem(item, 0)"
                      v-on="on"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>{{ $t("reception.delete") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <p>{{ $t("facture.nodata") }}</p>
          </template>
        </v-data-table>
        <div clas="mt-30">
          <v-pagination
            v-if="searchReception"
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosSearch"
            circle
          >
          </v-pagination>
          <v-pagination
            v-else
            v-model="current_pag"
            :total-visible="5"
            :length="total_page"
            @input="axiosList"
            circle
          >
          </v-pagination>
        </div>
      </template>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
import i18n from "../../i18n";
//import $ from "jquery";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
//import PageTitle from "../components/titles/PageTitle";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import axios from "axios";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    //PageTitle,
  },
  data: () => ({
    mask: "########",
    searchReception: "",
    searchFac: "",
    storage: window.localStorage,
    facture: null,
    reception: {},
    current_pag: 1,
    dialog: false,
    dialogView: false,
    delete_dialog: false,
    dialogOneFac: false,
    loading: false,
    modelSelectedChaufeur: null,
    modelSelectedArticles: null,
    modelSelectedFournisseur: null,
    modelSelectedDepot: null,
    deleteMessage: null,
    newchauff: false,
    nomChaufeur: "",
    contactChauffeur: "",
    search: "",
    inputRemise: false,
    articleList: new Array(),
    ajoutArticle: new Array(),
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    curMax: 0,
    nomClient: "",
    contactClient: "",
    quantite: "",
    quantiteArt: 0,
    oldRemise: 0,
    newRemise: "",
    showItem: {
      idReception: 0,
      user: {
        idUser: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      file: "",
      dateReception: "",
      nomChaufeur: "",
      contactChauffeur: "",
    },
    editedItem: {
      idReception: 0,
      user: {
        idUser: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      file: "",
      dateReception: "",
      nomChaufeur: "",
      contactChauffeur: "",
    },

    defaultItem: {
      idReception: 0,
      user: {
        idUser: 0,
      },
      depot: {
        idDepot: 0,
        libelle: "",
      },
      file: "",
      dateReception: "",
      nomChaufeur: "",
      contactChauffeur: "",
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("reception.depot"),
          value: "depot.libelle",
        },
        { text: this.$t("reception.name"), value: "chauffeure.nom" },
        { text: this.$t("reception.contact"), value: "chauffeure.telephone" },
        { text: this.$t("reception.date_reception"), value: "date_reception" },
        { text: "Actions", value: "action" },
      ];
    },

    headersAdmin() {
      return [
        {
          text: this.$t("reception.depot"),
          value: "depot.libelle",
        },
        {
          text: this.$t("login.username"),
          value: "ligneBoutique.boutiquesPK.user.nom",
        },
        { text: this.$t("reception.name"), value: "chauffeure.nom" },
        { text: this.$t("reception.contact"), value: "chauffeure.telephone" },
        { text: this.$t("reception.date_reception"), value: "date_reception" },
        { text: "Actions", value: "action" },
      ];
    },

    total_page() {
      if (this.reception && this.reception.last_page) {
        return this.reception.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.reception != null && this.reception.data) {
        return this.reception.data;
      } else {
        return [];
      }
    },
    matchedChauffeurs() {
      if (this.allChauffeur != null) {
        return this.allChauffeur;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.reception && this.reception.last_page) {
        return this.reception.last_page;
      } else {
        return 0;
      }
    },
    ...mapGetters({
      gettoken: "login/gettoken",
      _role: "login/getUrole",
      allArticles: "articles/getAllArticles",
      allChauffeur: "chauffeur/getAllChauffeur",
      allReception: "reception/getAllReceptions",
      allFournisseurs: "fournisseur/getAllFournisseurs",
      allDepots: "depot/getAllDepotsBoutique",
    }),

    formTitle() {
      return this.editedIndex === -1
        ? this.$t("reception.save")
        : this.$t("reception.update");
    },

    formAction() {
      return this.editedIndex === -1
        ? this.$t("reception.save")
        : this.$t("reception.update");
    },

    matchedArticles() {
      return this.allArticles.map((article) => {
        const articles = article.designation;
        return Object.assign({}, article, { articles });
      });
    },
    itemF() {
      return this.allFournisseurs.map((fournisseur) => {
        const raison = fournisseur.raisonSociale;
        return Object.assign({}, fournisseur, { raison });
      });
    },

    matchedFournisseur() {
      return this.allFournisseurs;
    },

    matchedDepot() {
      return this.allDepots;
    },

    // eslint-disable-next-line
    selectedArticles() {
      if (!this.modelSelectedArticles) return [];
    },

    curArticles() {
      return this.modelSelectedArticles;
    },

    isValidFacture: {
      get () {
        if (this.ajoutArticle.length === 0) return true;
      else return false;
      },

      set (value) {
        console.log(value)
        return value
      }
      
    },

    isValidPayement() {
      if (this.editedItem.montant === "" || this.editedItem.montantPaye === "")
        return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    },

    isDirector() {
      const isObjectPresent = this._role.find((o) => o.idRole === 2);
      return isObjectPresent;
      //return this._role <= 2 ? true : false;
    },
    isGerant() {
      const isObjectPresent = this._role.find((o) => o.idRole === 3);
      return isObjectPresent;
      //return this._role <= 3 ? true : false;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.axiosList();
  },

  created() {
    this.$store.dispatch("depot/FETCH_ALL_DEPOTS_BOUTIQUE");
    this.$store.dispatch("articles/FETCH_ALL_ARTICLES_STOCK");
    this.$store.dispatch("fournisseur/FETCH_ALL_FOURNISSEURS");
    this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");

    if (this.isDirector) {
      this.$store
        .dispatch("reception/FETCH_ALL_RECEPTIONS_BOUTIQUE")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.$store
        .dispatch("reception/FETCH_ALL_RECEPTIONS_BOUTIQUE_USER")
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    ...mapActions({
      depots: "depot/FETCH_ALL_DEPOTS_BOUTIQUE",
      chauffeurs: "chauffeur/FETCH_ALL_CHAUFFEUR",
      addnewchauffeur: "chauffeur/ADD_CHAUFFEUR",
      receptions: "reception/FETCH_ALL_RECEPTIONS_BOUTIQUE",
      articles: "articles/FETCH_ALL_ARTICLES_STOCK",
      fournisseurs: "fournisseur/FETCH_ALL_FOURNISSEURS",
      addNewReception: "reception/ADD_RECEPTION",
      updateReception: "reception/UPDATE_RECEPTION",
      deleteReception: "reception/DELETE_RECEPTION",
    }),
    savechauffeur() {
      const chauff = {};
      chauff.nom = this.nomChaufeur;
      chauff.telephone = this.contactChauffeur;
      chauff.boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
      this.addnewchauffeur(chauff).then(() => {
        this.newchauff = false;
        this.nom = "";
        this.prenom = "";
        this.telephone = "";
        this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEUR");
      });
    },
    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/reception/directeur/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.reception = response.data;
            if (this.reception.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      console.log(this.searchReception);
      if (this.searchReception) {
        await axios
          .get(
            APIENDPOINT +
              "/api/reception/directeur/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchReception,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.reception = response.data;
              if (this.reception.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },
    isItemOnList() {
      let tab1 = [];
      tab1 = this.ajoutArticle;
      const exist = tab1.find(
        (el) => el.id === this.modelSelectedArticles.article.idArticle
      );
      if (exist != undefined) {
        return exist;
      } else {
        return false;
      }
    },
    convertDate(date) {
      let convert_date = "";
      if (i18n.locale === "en") {
        convert_date = moment(String(date)).format("YYYY/MM/DD hh:mm:ss");
      } else {
        convert_date = moment(String(date)).format("DD/MM/YYYY à HH:mm:ss");
      }
      if (date != null) return convert_date;
      else return null;
    },

    ajout() {
      let article = {};
      let factureArt = {};

      // let total = this.totalArt;
      let tab = [];
      let tabArt = [];

      let tab1 = [];
      let tabArt1 = [];

      tab1 = this.ajoutArticle;
      tabArt1 = this.articleList;

      article.id = this.modelSelectedArticles.article.idArticle;
      article.designation = this.modelSelectedArticles.article.designation;

      article.prix = this.modelSelectedArticles.article.prix;
      article.quantite = this.quantite;

      factureArt.articles = this.modelSelectedArticles.article.idArticle;
      factureArt.nombre = parseInt(this.quantite);

      //Liste des articles choisis
      if (this.isItemOnList() !== false || article.quantite === "") {
          var msg1 = this.$t(
            "Le produit a ajouter existe deja dans la liste ou n'a pas de quantiter definie"
          );
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg1,
          });
        } else {
          //Liste des articles choisis
          tabArt.push(factureArt);
          this.articleList = tabArt1.concat(tabArt);
          console.log(this.articleList);

          tab.push(article);

          //Ajout des articles dans le tableau
          // this.totalArt = parseInt(total + article.tot);
          this.ajoutArticle = tab1.concat(tab);
        }
      
      this.axiosList();
      return this.ajoutArticle;
    },

    removeById(arr, id) {
      const requiredIndex = arr.findIndex((el) => {
        return el.id === String(id);
      });

      return arr.splice(requiredIndex - 1, 1);
    },

    supprimerLigne(item) {
      //const designation = item.designation;
      // const tot = this.totalArt;
      // const prixTotArt = item.prix * item.quantite;
      // const newTot = tot - prixTotArt;

      for (var i = 0; i < this.ajoutArticle.length; i++) {
        if (this.ajoutArticle[i].designation === item.designation) {
          this.ajoutArticle.splice(i, 1);
        }
      }

      for (var j = 0; j < this.articleList.length; j++) {
        if (this.articleList[j].articles === item.id) {
          this.articleList.splice(j, 1);
        }
      }

      // this.totalArt = newTot;
      return this.ajoutArticle;
    },

    async showReception(item) {
      await instance
        .get(APIENDPOINT + "/api/downloadFile/" + item.file, {
          responseType: "arraybuffer",
          timeout: 30000,
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch({
          //console.log("Error:" + err);
        });
    },

    async deleteItem(item, confirm) {
      this.delete_item = item;
      if (confirm == 0) {
        this.deleteMessage =
          this.$t("item.msg1") + this.$t("item.msg2") + this.$t("item.msg3");
        this.delete_dialog = true;
      } else if (confirm == 1) {
        this.loading = true;
        await this.deleteReception(item)
          .then(() => {
            this.loading = false;
            var msg = this.$t("deleteMsg");
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            var msg = this.$t("item.warning2");
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.delete_item = null;
        this.delete_dialog = false;
        this.axiosList();
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.modelSelectedDepot = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.modelSelectedFournisseur = null;
        this.ajoutArticle = [];
        this.quantite = "";

        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.$refs.formFacture.validate()) {
        this.isValidFacture = true;
        if (this.newRemise === "") {
          let boutiqueId = JSON.parse(
            window.localStorage.getItem("boutiqueId")
          );
          this.loading = true;
          const items = {};
          items.chauffeure = this.modelSelectedChaufeur.id;
          items.fournisseur = this.modelSelectedFournisseur.idFournisseur;
          items.depot = this.modelSelectedDepot.idDepot;
          items.boutique = boutiqueId;
          items.article = this.articleList;

          this.loading = true;
          this.addNewReception(items)
            .then(() => {
              var msg = this.$t("facture.msg");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });

              this.modelSelectedChaufeur = null;
              this.modelSelectedArticles = null;
              this.modelSelectedTypeFacture = null;
              this.quantite = "";
              this.nomClient = "";
              this.contactClient = "";
              this.articles = [];
              this.ajoutArticle = [];
              this.newRemise = "";
              this.totalArt = 0;
              this.facture = {};
              this.loading = false;
            })
            .catch(() => {
              var msg = this.$t("facture.msg1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
              this.loading = false;
            });
          this.axiosList();
          this.close();
        } else {
          this.loading = true;

          let boutiqueId = JSON.parse(
            window.localStorage.getItem("boutiqueId")
          );

          const items = {};

          items.nomClient = this.nomClient;
          items.contactClient = this.contactClient;
          items.boutique = boutiqueId;
          items.article = this.articlesList;
          items.remise = 0;
          items.remise = parseInt(this.newRemise);
          items.typeFactures = this.modelSelectedTypeFacture.idTypeFacture;
          console.log(items);

          this.loading = true;
          this.addNewReception(items)
            .then(() => {
              var msg = this.$t("facture.msg");
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
              this.modelSelectedClient = null;
              this.modelSelectedArticles = null;
              this.modelSelectedTypeFacture = null;
              this.quantite = "";
              this.nomClient = "";
              this.contactClient = "";
              this.articles = [];
              this.ajoutArticle = [];
              this.newRemise = "";
              this.totalArt = 0;
              this.facture = {};
              this.loading = false;
            })
            .catch(() => {
              var msg = this.$t("facture.msg1");
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
              this.loading = false;
            });
          this.axiosList();
          this.close();
        }
      }
    },
  },
};
</script>
<style scoped>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
