import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import Home from '../views/Home.vue'
import nav from "../components/menu/Navbar.vue"
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgetPass from '../views/auth/ForgetPass.vue'
import RegisterClientS2asport from '../views/auth/RegisterClientS2asport.vue'
import RegisterClientWin from '../views/auth/RegisterClientWin.vue'
import Default from '../views/Default.vue'
import Utilisateur from '../views/Utilisateur.vue'
import UtilisateurDirecteur from '../views/UtilisateurDirecteur.vue'
import UtilisateurActivateDirecteur from '../views/UtilisateurActivateDirecteur.vue'
import Depot from '../views/depots/Depot.vue'
import DepotUser from '../views/depots/DepotUser.vue'
import Articles from '../views/articles/Articles.vue'
import ArticlesPaginate from '../views/articles/ArticlesPaginate.vue'
import ArticlesInStock from '../views/articles/ArticlesInstock.vue'
import ArticlesSoldOut from '../views/articles/ArticlesSoldOut.vue'
import ArticlesCategorie from '../views/articles/ArticlesCategorie.vue'
import ListeMouvementArticle from '../views/articles/ListeMouvementArticle.vue'
import Inventaire from '../views/inventaires/Inventaire.vue'
import Categorie from '../views/categories/Categorie.vue'
import Client from '../views/clients/Client.vue'
import ClientMeilleur from '../views/clients/ClientMeilleur.vue'
import Facture from '../views/factures/Facture.vue'
import TypeFacture from '../views/factures/TypeFacture.vue'
import FacturesCaissier from '../views/factures/FacturesCaissier.vue'
import FactureLivraison from '../views/factures/FactureLivraison.vue'
import FacturePayer from '../views/factures/FacturePayer.vue'
import FactureNonSolde from '../views/factures/FactureNonSolde.vue'
import FactureSolde from '../views/factures/FactureSolde.vue'
import Fournisseur from '../views/fournisseurs/Fournisseur.vue'
import Livraison from '../views/livraisons/Livraison.vue'
import Boutique from '../views/boutiques/Boutique.vue'
import Chauffeur from '../views/chauffeur/chauffeur.vue'
//import ListeQuantiteStock from '../views/ListeQuantiteStock.vue'
//import ListeQuantiteArticleSortie from '../views/ListeQuantiteArticleSortie.vue'
import UtilisateurAdmin from '../views/UtilisateurAdmin.vue'
import FirstPage from '../views/FirstPage.vue'
import Raport from '../views/Raport.vue'
import Receptions from '../views/receptions/Receptions.vue'
import reactiveStorage from 'vue-reactive-storage';
import i18n from '../i18n'
//RegisterClientS2asport.vue
// RegisterClientWin

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

Vue.use(reactiveStorage, {
  user: {}
});

let perm_admin = 1;
let perm_director = 2;
let perm_gerant = 3;
let perm_simple = 4;
let perm_magasinier = 5;

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang/',
    name: 'Default',
    component: Default,
    children: [
      {
        path:'/',
        name:'index',
        component: index,
      },
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'ForgetPass',
        name: 'ForgetPass',
        component: ForgetPass
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'registers2asport',
        name: 'registers2asport',
        component: RegisterClientS2asport
      },
      {
        path: 'registerwin',
        name: 'registerswin',
        component: RegisterClientWin
      },
      {
        path: 'nav',
        name: 'nav',
        component: nav
      },
      {
        path: 'app/accueil',
        name: 'Home',
        component: Home,
      },
      {
        path: 'app/firt-page',
        name: 'FirstPage',
        component: FirstPage,
      },
      {
        path: 'app/client',
        name: 'Client',
        component: Client,
        meta: { requiresAuth: true, permission: perm_simple },
      },
      {
        path: 'app/boutique',
        name: 'Boutique',
        component: Boutique,
        meta: { requiresAuth: true, permission: perm_director },
      },
      {
        path: 'app/chauffeur',
        name: 'Chauffeur',
        component: Chauffeur,
        meta: { requiresAuth: true, permission: perm_director },
      },
      {
        path: 'app/depot',
        name: 'Depot',
        component: Depot,
        meta: { requiresAuth: true, permission: perm_simple },
      }, {
        path: 'app/depot-user',
        name: 'DepotUser',
        component: DepotUser,
        meta: { requiresAuth: true, permission: perm_simple },
      },
      {
        path: 'app/facture',
        name: 'Facture',
        component: Facture,
        meta: { requiresAuth: true, permission: perm_simple },
      },
      {
        path: 'app/facture-non-solde',
        name: 'FactureNonSolde',
        component: FactureNonSolde,
        meta: { requiresAuth: true, permission: perm_simple },
      },
      {
        path: 'app/facture-solde',
        name: 'FactureSolde',
        component: FactureSolde,
        meta: { requiresAuth: true, permission: perm_magasinier },
      },
      {
        path: 'app/facture-caissier',
        name: 'FactureCaissier',
        component: FacturesCaissier,
        meta: { requiresAuth: true, permission: perm_simple },
      },
      {
        path: 'app/type-facture',
        name: 'Type de facture',
        component: TypeFacture,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/factures-payees',
        name: 'Les factures payees',
        component: FacturePayer,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/articles',
        name: 'Articles',
        component: Articles,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/articlesPaginate',
        name: 'ArticlesPaginate',
        component: ArticlesPaginate,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/article-quantite-sortie',
        name: 'Sortie des articles',
        component: ArticlesSoldOut,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/article-quantite-stock',
        name: 'Stock des articles',
        component: ArticlesInStock,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/mouvement-articles',
        name: 'Mouvement des articles',
        component: ListeMouvementArticle,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/fournisseur',
        name: 'Fournisseur',
        component: Fournisseur,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/categorie',
        name: 'Categorie',
        component: Categorie,
        meta: { requiresAuth: true, permission: perm_gerant },
      },
      {
        path: 'app/inventaire',
        name: 'Inventaire',
        component: Inventaire,
        meta: { requiresAuth: true, permission: perm_director },
      },
      {
        path: 'app/utilisateur-boutique',
        name: 'Utilisateur',
        component: Utilisateur,
        meta: { requiresAuth: true, permission: perm_admin },
      },{
        path: 'app/utilisateur-directeur',
        name: 'UtilisateurDirecteur',
        component: UtilisateurDirecteur,
        meta: { requiresAuth: true, permission: perm_director },
      },{
        path: 'app/utilisateur-activatedirecteur',
        name: 'UtilisateurActivateDirecteur',
        component: UtilisateurActivateDirecteur,
        meta: { requiresAuth: true, permission: perm_director },
      },{
        path: 'app/client-meilleur',
        name: 'ClientMeilleur',
        component: ClientMeilleur,
        meta: { requiresAuth: true, permission: perm_director },
      }, {
        path: 'app/facture-livraison',
        name: 'FactureLivraison',
        component: FactureLivraison,
        meta: { requiresAuth: true, permission: perm_magasinier },
      }, {
        path: 'app/livraison',
        name: 'Livraison',
        component: Livraison,
        meta: { requiresAuth: true, permission: perm_magasinier },
      }, {
        path: 'app/utilisateur-admin',
        name: 'UtilisateurAdmin',
        component: UtilisateurAdmin,
        meta: { requiresAuth: true, permission: perm_director },
      }, {
        path: 'app/raport',
        name: 'Raport',
        component: Raport,
        meta: { requiresAuth: true, permission: perm_director },
      }, {
        path: 'app/reception',
        name: 'Reception',
        component: Receptions,
        meta: { requiresAuth: true, permission: perm_director },
      },
      {
        path: 'app/article-categorie',
        name: 'ArticlesCategorie',
        component: ArticlesCategorie,
        meta: { requiresAuth: true, permission: perm_director },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'fr'
  }
  // set the current language for i18n.
  i18n.locale = language

  next()
})

export default router



/*router.beforeEach((to, from, next) => {
  const authUser = JSON.parse(window.localStorage.getItem('conUser'))
  if (!authUser || !authUser.token) {
    next({
      path: '/',
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    const connected_user = JSON.parse(window.localStorage.user);
    switch (to.meta.permission) {
      case perm_simple:
        if (connected_user.role.idRole <= 4) next();
        else next({
          path: '/app/accueil',
          query: {
            returnUrl: to.path
          }
        })
        break;
      case perm_gerant:
        if (connected_user.role.idRole <= 3) next();
        else next({
          path: '/app/accueil',
          query:{
            returnUrl: to.path 
          }
        })
        break;
    }
  }

})*/