var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('SuccessSnackBar'),_c('ErrorSnackBar'),_c('v-divider'),_c('v-layout',[_c('v-row',[_c('v-col',{staticClass:"default",attrs:{"cols":"12","md":"12"}},[_c('div',[_c('v-app-bar',{staticClass:"elevation-20",attrs:{"color":"grey lighten-4"}},[_c('v-toolbar-title'),_c('v-spacer'),(_vm.isDirector)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('facture.search'),"single-line":"","hide-details":""},on:{"input":_vm.axiosSearchDir},model:{value:(_vm.searchFacDir),callback:function ($$v) {_vm.searchFacDir=$$v},expression:"searchFacDir"}}):_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('facture.search'),"single-line":"","hide-details":""},on:{"input":_vm.axiosSearch},model:{value:(_vm.searchFac),callback:function ($$v) {_vm.searchFac=$$v},expression:"searchFac"}})],1)],1)])],1)],1),_c('v-container',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"100%","max-width":"100%"},attrs:{"id":"scroll-target"}},[(_vm.isDirector)?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersAdmin,"items":_vm.itemListDir,"search":_vm.searchFacDir,"loading":_vm.loading,"hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("facture.bill1")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.facture.numeroFacture))]),_c('td',[_vm._v(" "+_vm._s(item.facture.ligneBoutique.boutiquesPK.user.username)+" ")]),(item.facture.commande.client)?_c('td',[_vm._v(_vm._s(item.facture.commande.client.nom))]):_c('td'),_c('td',[_vm._v(_vm._s(item.facture.montantFacture + " FCFA"))]),_c('td',[(item.facture.solde)?[_c('v-chip',{attrs:{"color":"blue","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("yes"))+" ")])]:[_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")])]],2),_c('td',[_vm._v(_vm._s(item.reste + " FCFA"))]),_c('td',[_vm._v(_vm._s(_vm.convertDate(item.facture.createdAt)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.showFacture(item)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("facture.see")))])])],1)])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("facture.nodata")))])]},proxy:true}],null,false,1762519537)}),_c('div',{staticClass:"mt-4"},[(_vm.searchFacDir)?_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.total_page,"circle":""},on:{"input":_vm.axiosSearchDir},model:{value:(_vm.current_pag),callback:function ($$v) {_vm.current_pag=$$v},expression:"current_pag"}}):_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.total_page,"circle":""},on:{"input":_vm.axiosListDir},model:{value:(_vm.current_pag),callback:function ($$v) {_vm.current_pag=$$v},expression:"current_pag"}})],1)]:[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemList,"loading":_vm.loading,"search":_vm.searchFac,"sort-by":"commande.client.nom","hide-default-footer":"","disable-filtering":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("datatable-title.facture.listefacturecaissier")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('v-icon',[_vm._v("mdi-file-document-box-multiple-outline")])],1),_c('td',[_vm._v(_vm._s(item.facture.numeroFacture))]),_c('td',[_vm._v(_vm._s(item.facture.commande.client.nom))]),_c('td',[_vm._v(_vm._s(item.facture.montantFacture - item.remise + " FCFA"))]),_c('td',[(item.facture.solde)?[_c('v-chip',{attrs:{"color":"blue","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("yes"))+" ")])]:[_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("no"))+" ")])]],2),_c('td',[_vm._v(_vm._s(item.facture.reste + " FCFA"))]),_c('td',[_vm._v(_vm._s(_vm.convertDate(item.facture.createdAt)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.showFacture(item)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("facture.see")))])])],1)])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t("facture.nodata")))])]},proxy:true}])}),_c('div',{staticClass:"mt-4"},[(_vm.searchFac)?_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.total_page,"circle":""},on:{"input":_vm.axiosSearch},model:{value:(_vm.current_pag),callback:function ($$v) {_vm.current_pag=$$v},expression:"current_pag"}}):_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.total_page,"circle":""},on:{"input":_vm.axiosList},model:{value:(_vm.current_pag),callback:function ($$v) {_vm.current_pag=$$v},expression:"current_pag"}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }