<template>
  <v-footer class="align-center justify-center" app>
    <span>&copy; 2019-{{ year }} S2A - {{ $t("right") }}</span>
  </v-footer>
</template>

<script>
import moment from "moment";

export default {
  computed: {
    year() {
      return moment().year();
    },
  },
};
</script>
