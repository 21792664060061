import { APIENDPOINT , instance} from "@/api/app.config";

const state= {
    abonnement:[]
};

const getters =
{
    getAllAbonnement: (state) => state.abonnement
};

const actions=
{
    FETCH_ABONNEMENT:({commit}) =>
    {
    return new Promise((resolve, reject) => {
        instance
          .get(
            APIENDPOINT +
              "/api/abonnements/", 
            {
              timeout: 3000,
            }
          )
          .then(function(response) {
            const users =response.data
          
            commit("ALL_ABONNEMENT", users)
            resolve();
          })
          .catch(function(err) {
            //console.log(err)
            reject(err);
          });
      });
    }
    
}

const mutations ={
    ALL_ABONNEMENT:( state, abonnement) =>
    {
        state.abonnement= abonnement 
    }
}
export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations,
}