<template v-if="loaded">
  <v-container class="circular mt-10"> </v-container>
</template>

<script>
export default {
  data: () => ({
    storage: window.localStorage,
    loaded: false,
    image: "../../public/logo.png",
  }),
  mounted() {
    this.storage = window.localStorage;
    this.loaded = true;
  },
  computed: {
    //  fullName() {
    //   const connected_user = JSON.parse(this.storage.user);
    //   return connected_user.nom + " " + connected_user.prenom;
    // }
  },
};
</script>
<style scoped lang="css">
.circular {
  height: 500px;
  width: 500px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-image: url("../../public/logo.png");
}
</style>
