<template>
  <v-dialog :value="value"  persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        {{$t('wait')}}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean
  }
};
</script>