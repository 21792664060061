 import { APIENDPOINT , instance} from "@/api/app.config";
 

const state= {
    usersDirecteur:[]

};

const getters =
{
    setAllUserDirecteur: (state) => state.usersDirecteur
};

const actions= {
    ADD_DIRECTEUR: ({ commit }, value) => {
       
        return new Promise((resolve, reject) => {
          instance
            .post(APIENDPOINT + "/api/auth/users/abonnement", value, {
              timeout: 3000,
              Headers:{
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(function(response) {
              const user = response.data;
              console.log(user)
              commit("NEW_DIRECTEUR", user);
              resolve();
            })
            .catch(function(err) {
              reject(err);
            });
        });
      },
    ADD_DIRECTEUR_CUSTOM: ({ commit }, value) => {
       
        return new Promise((resolve, reject) => {
          instance
            .post(APIENDPOINT + "/api/auth/users/abonnement/personnalise", value, {
              timeout: 3000,
              Headers:{
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(function(response) {
              const user = response.data;
              console.log(user)
              commit("OTHER_DIRECTEUR", user);
              resolve();
            })
            .catch(function(err) {
              reject(err);
            });
        });
      },
 };

const mutations = {
        ALL_USERS_DIRECTEUR: (state, usersDirecteur) =>
        {
            state.usersDirecteur= usersDirecteur
        },
        NEW_DIRECTEUR: (state, usersDirecteur) => {
            state.usersDirecteur.push(usersDirecteur);
          },
        OTHER_DIRECTEUR: (state, usersDirecteur) => {
            state.usersDirecteur.push(usersDirecteur);
          },
};

 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations,
   };
  