<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <LoadingDialog :value="isLoading" />
    <!-- Fin Components -->
    <v-col cols="auto">
      <v-dialog v-model="active" transition="dialog-top-transition" max-width="600">
        <template>
          <v-card>
            <v-toolbar color="primary" class="justify-center" dark>{{
              $t("registerform.dialog.title")
            }}</v-toolbar>
            <v-card-text>
              <v-col class="d-flex mt-4">
                <v-autocomplete
                  class="required"
                  :items="matchAbonnement"
                  v-model="modelSelectedabonnement"
                  item-text="libelle"
                  item-value="libelle"
                  label="$t('abonnement.titre')"
                  outlined
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-select
                  class="required"
                  v-model="modelSelectedPeriode"
                  :items="periode"
                  item-text="text"
                  item-value="text"
                  type="number"
                  label="Periode d'abonement (en annees)"
                  outlined
                  return-object
                ></v-select>
              </v-col>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="blue darken-1" text @click="activate">Save</v-btn>
              <v-btn text @click="close">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchDir"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('users.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchDir"
        hide-default-footer
        disable-filtering
        disable-sort
        disable-pagination
        sort-by="username"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("users.users") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.users.username }}</td>
            <td>{{ item.users.nom }}</td>
            <td>{{ item.users.prenom }}</td>
            <td>
              {{ item.abonnement.libelle }}
            </td>
            <td>
              {{ item.abonnement.boutiques }}
            </td>
            <td>
              {{ item.abonnement.boutiques }}
            </td>
            <td>
              {{ item.abonnement.users }}
            </td>
            <td>
              {{ item.users.telephone }}
            </td>
            <td>
              <a :href="item.preuve" class="glightbox" data-gallery="images-gallery">
                <img :src="item.preuve" alt="preuve" height="50px" />
              </a>
            </td>
            <td>
              <v-btn @click="activer(item)" block color="primary">Activer</v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <v-pagination
        v-if="searchDir"
        v-model="current_pag"
        :total-visible="5"
        :length="total_page"
        @input="axiosSearch"
        circle
      >
      </v-pagination>
      <v-pagination
        v-else
        v-model="current_pag"
        :total-visible="5"
        :length="total_page"
        @input="axiosList"
        circle
      >
      </v-pagination>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import SuccessSnackBar from "../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../components/snackbar/ErrorSnackBar";
import LoadingDialog from "../components/dialog/LoadingDialog";
import { mask } from "vue-the-mask";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    LoadingDialog,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    modelSelectedPeriode: null,
    active: false,
    modelSelectedabonnement: null,
    storage: window.localStorage,
    searchDir: "",
    searchFournisseur: "",
    directeur: {},
    dialog: false,
    current_pag: 1,
    snackbar: false,
    loading: false,
    dialog_save: false,
    delete_dialog: false,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    editedIndex: -1,
    activated: {
      idUser: 0,
      idAbonnement: 0,
      periode: 0,
    },
    defaultItem: {
      idUser: 0,
      idAbonnement: 0,
      periode: 0,
    },
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("user"),
          align: "left",
          sortable: true,
          value: "username",
        },
        { text: this.$t("users.name1"), value: "nom", sortable: false },
        { text: this.$t("users.name2"), value: "prenom", sortable: false },
        { text: this.$t("users.abonnement"), value: "role", sortable: false },
        { text: this.$t("users.boutique"), value: "role", sortable: false },
        { text: this.$t("users.depot"), value: "role", sortable: false },
        { text: this.$t("users.user"), value: "role", sortable: false },
        { text: this.$t("users.telephone"), value: "telephone", sortable: false },
        { text: this.$t("users.preuve"), value: "preuve", sortable: false },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
    total_page() {
      if (this.directeur && this.directeur.last_page) {
        return this.directeur.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.directeur != null && this.directeur.data) {
        return this.directeur.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.directeur && this.directeur.last_page) {
        return this.directeur.last_page;
      } else {
        return 0;
      }
    },
    ...mapGetters({
      allUtilisateurs: "utilisateur/allUsersAdmin",
      allabonnement: "abonnement/getAllAbonnement",
    }),

    formTitle() {
      return this.editedIndex === -1 ? this.$t("users.save") : this.$t("users.update");
    },

    formAction() {
      return this.editedIndex === -1 ? this.$t("item.save1") : this.$t("item.update1");
    },

    isLoading() {
      return this.loading;
    },
    periode() {
      let time = {};
      return (time.time = [1, 2, 3, 4]);
    },
    matchAbonnement() {
      return this.allabonnement.map((abonnement) => {
        const libelle = abonnement.libelle;

        return Object.assign({}, abonnement, { libelle });
      });
    },
  },

  watch: {},
  mounted() {
    this.axiosList();
  },
  created() {
    this.loading = true;
  },

  methods: {
    ...mapActions({
      abonnement: "abonnement/FETCH_ABONNEMENT",
      utilisateur: "utilisateur/FETCH_USERS_ADMIN",
      activation: "utilisateur/ACTIVATE_USER_DIRECTEUR",
    }),

    async axiosList() {
      this.loading = true;
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(APIENDPOINT + "/api/ligne/abonnement/" + this.current_pag, {
          headers: {
            Authorization: `Bearer ${token.token} `,
          },
        })
        .then((response) => {
          if (response.data) {
            this.directeur = response.data;
            console.log(response.data);
            if (this.directeur.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchDir) {
        await axios
          .get(
            APIENDPOINT +
              "/api/ligne/abonnement/" +
              this.current_pag +
              "/" +
              this.searchDir,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.directeur = response.data;
              if (this.directeur.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },
    activer(item) {
      this.activated = Object.assign({}, item);
      let active = {};
      active.idUser = this.activated.users.idUser;
      active.abonnement = this.activated.users.abonnementId;
      this.activation(active)
        .then(() => {
          this.axiosList();
          const msg = this.$t("activation.message");
          this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
            show: true,
            text: msg,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    close() {
      this.modelSelectedPeriode = null;
      this.modelSelectedabonnement = null;
      this.active = false;
    },
    async activate() {
      // active.jours = this.modelSelectedPeriode * 365;
      // await this.activation(active)
      //   .then(() => {
      //     this.modelSelectedabonnement = null;
      //     this.modelSelectedPeriode = null;
      //     this.active = false;
      //     this.axiosList();
      //     const msg = this.$t("activation.message");
      //     this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
      //       show: true,
      //       text: msg,
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },

    // close() {
    //   this.dialog = false;
    //   this.dialog_save = false;
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.value = [];
    //     this.modelSelectedBoutique = null;
    //     this.editedIndex = -1;
    //   }, 300);
    // },
  },
};
</script>
<style scoped>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
