<template dark>
  <div>
    <v-container class="cadre" fluid>
      <SuccessSnackBar />
      <ErrorSnackBar />
      <v-toolbar-title>
        <v-dialog v-model="dialog" scrollable persistent max-width="700">
          <v-card>
            <v-card-title class="headline blue justify-center">
              <span class="headline white--text">{{ $t("login.choose") }}</span>
            </v-card-title>

            <v-card-text style="height: 150px;">
              <v-container grid-list-md>
                <v-form ref="formBoutique">
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                      <v-autocomplete
                        v-model="modelSelectedBoutique"
                        :items="allBoutiqueForUser"
                        item-text="nom"
                        item-value="idBoutique"
                        :label="$t('login.choose1')"
                        :placeholder="$t('login.choose2')"
                        prepend-icon="mdi-database-search"
                        return-object
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-form>
                <small>*{{ $t("login.desc") }}</small>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                tile
                outlined
                color="blue accent-4"
                @click="save"
                :disabled="isValidBoutique"
              >
                <v-icon>mdi-content-save</v-icon>
                {{ $t("login.open") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-layout align-center justify-center color="primary">
        <v-flex login-form text-xs-center>
          <v-card>
            <v-card-text class="subheading">
              <template>
                <img style="width: 20rem ; padding-left: 10rem ;" src="../../../public/logo.png"/>
                <div class="d-flex" style="justify-content: space-between;">
                  <div>
                    {{ $t("login.titre") }}
                  </div>
                  <div class="nav-item">
                    <v-menu open-on-hover offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-1" text v-bind="attrs" v-on="on">
                          <v-icon color="grey">
                            mdi-earth
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-title>
                          <v-btn text width="100%" @click="changedtofr">
                            Français
                          </v-btn>
                        </v-list-item-title>

                        <v-list-item-title>
                          <v-btn text width="100%" @click="changedtoen">
                            English
                          </v-btn>
                        </v-list-item-title>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </template>
              <v-form @keyup.native.enter="loginUser" ref="loginForm">
                <v-text-field
                  v-model="logins.username"
                  prepend-icon="mdi-account"
                  :label="$t('login.username')"
                  type="text"
                >
                </v-text-field>
                <v-text-field
                  v-model="logins.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-icon="mdi-lock"
                  :label="$t('login.pwd')"
                  counter
                  @click:append="show1 = !show1"
                  :type="show1 ? 'text' : 'password'"
                >
                </v-text-field>
                <v-container>
                  <v-btn
                    @click.prevent="loginUser"
                    block
                    class="mt-4"
                    color="primary"
                    type="submit"
                  >
                    <span
                      v-if="request_status === '' || request_status === 'error'"
                    >
                      {{ $t("login.connect") }}
                      <v-icon>mdi-login</v-icon>
                    </span>
                    <span v-if="request_status === 'loading'"
                      >{{ $t("login.wait") }}...</span
                    >
                  </v-btn>
                  <p class="mt-4">
                  <a :href="forgetPass">Mot de passe oublié ?</a>
                </p>
                <p class="mt-4">Vous n'avez pas  de compte ?
                  <a :href="href">S'inscrire</a>
                </p>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import i18n from "../../i18n";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";

export default {
  name: "Login",
  request: "Connexion",
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  data() {
    return {

      href:"register",
      forgetPass:"ForgetPass",
      show1: false,
      image_src: require("../../assets/logo.png"),
      modelSelectedBoutique: null,
      dialog: false,
      logins: {
        username: "",
        password: "",
      },
      defaultLogin: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      boutiques: "boutique/FETCH_ALL_BOUTIQUES_CURRENT_USER",
    }),

    loginUser: async function() {
      //var app = this;

      if (this.$refs.loginForm.validate()) {
        await this.$store
          .dispatch("login/LOGIN_AUTH", this.logins)
          .then(() => {
            let us = JSON.parse(window.localStorage.getItem("user"));

            if (us.boutiques != null) {
              if (us.boutiques.length > 1) {
                this.$store.dispatch(
                  "boutique/FETCH_ALL_BOUTIQUES_CURRENT_USER"
                );
                this.dialog = true;
              } else if (us.boutiques.length == 1) {
                window.localStorage.setItem(
                  "boutiqueId",
                  JSON.stringify(us.boutiques[0])
                );
                //console.log(us.boutiques[0]);
                this.$router
                  .push(`/${i18n.locale}/app/accueil`)
                  .catch(() => {});
                //this.dialog = true;
              } else {
                if (us.role != 1) {
                  if (us.role.length == 1) {
                    this.$router
                      .push(`/${i18n.locale}/app/accueil`)
                      .catch(() => {});
                  }
                }
              }
            }
          })
          .catch((err) => {
            // console.log(err);
            this.logins = Object.assign({}, this.defaultLogin);
            if (err.request_status == 401) {
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: this.$t("login.error1"),
              });
              // alert(`${this.$t("login.error1")}.`);
            } else {
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: this.$t("login.error1"),
              });
            }
            // alert(`${this.$t("login.error2")}.`);
          });
      }
    },
    loginAuth: function() {
      var app = this;
      const status = JSON.parse(window.localStorage.getItem("conUser"));
      const boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
      if (status === null || boutique === null || status === undefined) {
        this.$router.push(`/${i18n.locale}/login`).catch(() => {});
      } else {
        app.$router.push(`/${i18n.locale}/app/accueil`).catch(() => {});
      }
    },

    resetLogForm() {
      (this.logins.username = ""), (this.logins.password = "");
    },

    save() {
      //this.loading = true;
      var app = this;
      window.localStorage.setItem(
        "boutiqueId",
        JSON.stringify(this.modelSelectedBoutique)
      );

      app.$router.push(`/${i18n.locale}/app/accueil`).catch(() => {});
    },

    close() {
      this.dialog = false;
      this.dialog_save = false;
      setTimeout(() => {
        this.modelSelectedBoutique = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    changedtofr: function() {
      i18n.locale = "fr";

      let language = this.$route.params.lang;
      if (language === "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/fr" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },

    changedtoen: function() {
      i18n.locale = "en";
      let language = this.$route.params.lang;
      if (language != "en") {
        let splitted = this.$route.path.split(`${language}`);
        let url = "/en" + splitted[1];
        this.$router.push(url).catch(() => {});
      }
    },
  },
  computed: {
    request_status() {
      return this.$store.state.login.status;
    },
    isDisabled() {
      if (this.request_status === "loading") return true;
      else return false;
    },

    isValidBoutique() {
      if (this.modelSelectedBoutique === null) return true;
      else return false;
    },

    ...mapGetters({
      allBoutiqueForUser: "boutique/getAllBoutiquesCurrentUser",
    }),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created: function() {
    
    this.loginAuth();
  },
};
</script>
<style scoped>
body {
  background-color: black;
}
.login-form {
  max-width: 500px;
}
.cadre {
  margin-top: 200px;
}
</style>
