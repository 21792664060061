<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Payements Clients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <LoadingDialog :value="isLoading" />
    <!-- Fin Components -->

    <v-divider></v-divider>
    
    <v-layout>
      <v-row>
        <v-col cols="12" md="12">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>

                <!-- Début dialog de payement d'une facture -->
                <v-dialog v-model="dialogView" scrollable persistent max-width="700">
                
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">Détails du payement de la facture</span>
                    </v-card-title>

                    <v-card-text style="height: 500px;">
                      <v-container grid-list-md>
                        <v-form ref="formPayement">
                          <v-layout wrap>
                            <v-col class="col-6 mb-3">
                              <span>
                                N° Facture: 
                                <h1 class="red--text" >{{ showItem.facture.numeroFacture}}</h1>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <v-card
                                elevation="1"
                                outlined
                                shaped
                                tile
                                color="amber"
                                height="60"
                              >
                                <span class="ml-2">
                                  Client:
                                  <h2 class="black--text ml-2">{{ showItem.facture.commande.client.nom+' '+ showItem.facture.commande.client.prenom }}</h2>
                                </span>
                              </v-card>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Caisse:
                                <h3 class="black--text" >{{ showItem.caisse.libelle}}</h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Date de payement:
                                <h3 class="black--text" >{{ convertDateTime(showItem.datePaiement)}}</h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Type de facture:
                                <h2 class="red--text" >{{ showItem.facture.typeFacture.libelle }}</h2>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Montant de la facture:
                                <h3 class="red--text" id="total" name="total" >{{ showItem.montant }} FCFA</h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Montant payé:
                                <h3 class="red--text" >{{ showItem.montantPaye }}</h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Reste à payé:
                                <h3 class="red--text">{{ showItem.reste }} FCFA</h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Reliquat:
                                <h3 class="red--text" >{{ showItem.reliquat }}</h3>
                              </span>
                            </v-col>
                            <v-col class="col-6 mb-3">
                              <span>
                                Etat de la facture:
                                <h3 class="red--text">{{ showItem.facture.solde ? "Soldé" : "Non soldé" }}</h3>
                              </span>
                            </v-col>
                            
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{$t('close')}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!--Fin dialog de payement d'une facture -->
                
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchFac"
                append-icon="mdi-magnify"
                label="Rechercher une facture..."
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->
    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="allPayements"
        :search="searchFac"
        sort-by="commande.client.nom"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>FACTURES PAYEES</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.facture.numeroFacture }}</td>
            <td>{{ item.facture.commande.client.nom+' '+item.facture.commande.client.prenom }}</td>
            <td>{{ item.montant+" FCFA" }}</td>
            <td>{{ item.montantPaye + " FCFA" }}</td>
            <td>{{ item.reliquat +" FCFA" }}</td>
            <td>{{ item.reste + " FCFA" }}</td>
            <td>{{ item.facture.solde ? "Soldé" : "Non soldé" }}</td>
            <td>{{ convertDate(item.datePaiement) }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small @click="showPayement(item)" v-on="on">mdi-eye</v-icon>
                </template>
                <span>Voir les détails du payement</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>Aucune donnée disponible pour le moment.</p>
        </template>
      </v-data-table>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { APIENDPOINT, instance } from "@/api/app.config";
import moment from "moment";
//import $ from "jquery";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
//import PageTitle from "../components/titles/PageTitle";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import LoadingDialog from "../../components/dialog/LoadingDialog";

export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    //PageTitle,
    LoadingDialog
  },
  data: () => ({
    mask: "########",
    searchFac: "",
    storage: window.localStorage,
    facture: null,
    dialog: false,
    dialogView: false,
    dialogOneFac: false,
    modelSelectedCaisse: null,
    loading: false,
    search: null,
    inputRemise: false,
    articles: new Array(),
    ajoutArticle: new Array(),
    //ajouter: false,
    editedIndex: -1,
    remise: 0,
    totalArt: 0,
    quantite: "",
    quantiteArt: 0,
    reste: 0,
    oldRemise: 0,
    newRemise: 0,
    editedItem: {
      caisse: {},
      facture: 0,
      montant: "",
      montantPaye: "",
    },
    defaultItem: {
      caisse: {},
      facture: 0,
      montant: "",
      montantPaye: "",
    },
    showItem: {
      idPayement: 0,
      caisse: {
          idCaisse: 0,
          libelle: ""
      },
      datePaiement: "",
      facture: {
          idFacture: 0,
          commande: {
            client: {
                idClient: 0,
                nom: "",
                prenom: ""
            }
          },
          typeFacture: {
            idTypeFacture: 0,
            libelle: ""
          },
          numeroFacture: "",
          dateFacturation: ""
      },
      
      montant: 0,
      montantPaye: 0,
      reliquat: 0,
      reste: 0,
      solde: false,
      createdAt: ""
    }
  }),

  computed: {
    headers()
    {
      return[
         
      {
        text: this.$t("datatable-header.facture.idfacture"),
        align: "left",
        value: "numeroFacture",
        sortable: true,
      },
      { text: "Client", value: "facture.commande.client.nom" },
      { text: "Montant facture", value: "montant" },
      { text: "Montant payé", value: "montantPaye" },
      { text: "Reliquat", value: "reliquat" },
      { text: "Reste", value: "reste" },
      { text: "Soldée", value: "solde" },
      { text: "Date de payement", value: "datePaiement" },
      { text: "Actions", value: "action", sortable: true }
    ];
    },
    ...mapGetters({
        allPayements: "payement/getAllPayements",
    }),

    formAction() {
      return this.editedIndex === -1 ? "Enrégistrer le payement" : "Modifier le payement";
    },

    itemC() {
      return this.allCaisses.map(caisse => {
        const libelles = caisse.libelle;
        return Object.assign({}, caisse, { libelles });
      });
    },

    isValidPayement() {
      if (this.modelSelectedCaisse === null && this.editedItem.montantPaye === "") return true;
      else return false;
    },

    isLoading() {
      return this.loading;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    
  },

  created() {
    this.loading = true;
    this.$store
      .dispatch("payement/FETCH_ALL_PAYEMENTS")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });

  },

  methods: {
    ...mapActions({

    }),

    convertDate(date) {
      let convert_date = moment(String(date)).format("DD/MM/YYYY à HH:mm:ss");
      if (date != null) return convert_date;
      else return null;
    },

    convertDateTime(date) {
      let convert_date = moment(String(date)).format("DD/MM/YYYY à HH:mm:ss");
      if (date != null) return convert_date;
      else return null;
    },

    calculReste(){
      const rest = this.showItem.montantPayement - parseInt(this.editedItem.montantPaye);
      this.reste = rest;
    },

    showPayement(item){
      console.log(item);
      this.editedIndex = this.allPayements.indexOf(item);
      this.showItem = Object.assign({}, item);
      this.dialogView = true;
    },

    closePayement() {
      this.dialogView = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async downloadPayement(item) {
      await instance
        .get(APIENDPOINT + "/e2v/api/downloadFile/" + item.codeFac, {
          responseType: "arraybuffer",
          timeout: 30000
        })
        .then(function(response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch( {
          //console.log("Error:" + err);
        });
    },

    getTotalOnePayement(facture) {
      let somme = 0;
      facture.analyseList.forEach(element => {
        somme += !element.netPayBeneficiaire
          ? element.price
          : element.netPayBeneficiaire;
      });
      return somme - facture.remise;
    },

    close() {
      this.dialogView = false;
      setTimeout(() => {
        this.editedItem.montantPaye = "";
        this.modelSelectedCaisse = null;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
       
      if (this.$refs.formPayement.validate()) {
        this.loading = true;
        let items = {};

        items.caisse = this.modelSelectedCaisse.idCaisse;
        items.facture = this.showItem.idPayement;
        items.montant = this.showItem.montantPayement;
        items.montantPaye = parseInt(this.editedItem.montantPaye);
        
        await this.payerPayement(items)
          .then(() => {
            this.loading = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            const msg = "Le payement a été effectué avec succès.";
            this.reste = 0
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg
            });
            this.$store
              .dispatch("facture/FETCH_ALL_PAYEMENT")
              .then(() => {
                this.loading = true;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.loading = false;
            const msg =
              "Une erreur est survenue lors de lors du payement, veuillez réessayer svp.";
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg
            });
          });
          this.close();
        }
      
    }

  }
};
</script>