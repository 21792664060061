import { APIENDPOINT, instance } from "@/api/app.config";

const state = {
  listchauffeurs:{},
  chauffeurs: {},
  chauffeur:{}
};

const getters = {
  getAllChauffeur: (state) => state.chauffeurs,
  chauffeur: (state) => state.chauffeur,
  allchauffeurs:(state)=> state.listchauffeurs
};
const actions = {
  UPDATE_CHAUFFEUR: ({ commit },{id,value}) => {
    // let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .put(APIENDPOINT + "/api/chauffeur/"+id,value)
        .then(function(response) {
          const chauffeurs = response.data;
          commit("NEW_CHAUFFEUR", chauffeurs);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  }, 
  FETCH_ALL_CHAUFFEUR: ({ commit }) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/chauffeur/boutique/"+boutiqueId.idBoutique)
        .then(function(response) {
          const chauffeurs = response.data;
          commit("ALL_CHAUFFEUR", chauffeurs);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  }, 
  FETCH_ALL_CHAUFFEURS: ({ commit },page) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/chauffeur/page/"+boutiqueId.idBoutique+"/"+page,)
        .then(function(response) {
          const chauffeurs = response.data;
          commit("ALL_CHAUFFEURS", chauffeurs);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  }, 
  SEARCH_ALL_CHAUFFEURS: ({ commit },{page,search}) => {
    let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
    return new Promise((resolve, reject) => {
      instance
        .get(APIENDPOINT + "/api/chauffeur/page/"+boutiqueId.idBoutique+"/"+page+"/"+search,)
        .then(function(response) {
          const chauffeurs = response.data;
          commit("ALL_CHAUFFEURS", chauffeurs);
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  }, 
  ADD_CHAUFFEUR: ({ commit }, c) => {
    return new Promise((resolve, reject) => {
      instance
        .post(APIENDPOINT + "/api/chauffeur", c, {
          timeout: 3000,
        })
        .then(function(response) {
          const chauffeur = response.data;
          commit("NEW_CHAUFFEUR", chauffeur);
          resolve();
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        });
    });
  },
  DELETE_CHAUFFEUR: ({commit },id) => {
    return new Promise((resolve, reject) => {
      instance
        .delete(APIENDPOINT + "/api/chauffeur/" + id)
        .then(function() {
          commit("NEW_CHAUFFEUR");
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
};

const mutations = {
  ALL_CHAUFFEUR: (state, f) => {
    state.chauffeurs = f;
  },
  ALL_CHAUFFEURS: (state, f) => {
    state.listchauffeurs = f;
  },
  NEW_CHAUFFEUR: (state, chauffeur) => {
    state.chauffeur= chauffeur
  },
};

export default {
  namespaced:true,
  state,
  getters,
  actions,
  mutations,
};
