<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />

    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-btn
                  @click="newchauff = true"
                  color="primary"
                  dark
                  class="mb-2"
                  >{{ $t("datatable-header.chauffeur.add") }}</v-btn
                >
                <v-dialog
                  v-model="newchauff"
                  scrollable
                  persistent
                  max-width="500"
                >
                  <template v-slot:default="dialog">
                    <v-form ref="formadd" @submit.prevent="add">
                      <v-card>
                        <v-card-text style="height: 200px;">
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="nomChauffeur"
                                  :label="$t('livraison.driver')"
                                  clearable
                                  ref="clear"
                                  type="text"
                                  :rules="[rules.required]"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="contactChauffeur"
                                  :label="
                                    $t(
                                      'datatable-header.livraison.contactchauffeur'
                                    )
                                  "
                                  type="number"
                                  ref="clear1"
                                  :rules="[rules.required]"
                                  prepend-inner-icon="mdi-phone"
                                  clearable
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            class="ma-2"
                            tile
                            color="red accent-4"
                            @click="dialog.value = false"
                            dark
                          >
                            <v-icon left>mdi-close-circle-outline</v-icon
                            >{{ $t("close") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="ma-2"
                            tile
                            outlined
                            color="blue accent-4"
                            @click="savechauffeur()"
                          >
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </template>
                </v-dialog>
                <v-dialog
                  v-model="updatechauff"
                  scrollable
                  persistent
                  max-width="500"
                >
                  <template v-slot:default="dialog">
                    <v-form ref="formupdate" @submit.prevent="add">
                      <v-card>
                        <v-card-text style="height: 200px;">
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="nomChauffeur"
                                  :label="$t('livraison.driver')"
                                  clearable
                                  ref="clear"
                                  type="text"
                                  :rules="[rules.required]"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm12 md12>
                                <v-text-field
                                  v-model="contactChauffeur"
                                  :label="
                                    $t(
                                      'datatable-header.livraison.contactchauffeur'
                                    )
                                  "
                                  type="number"
                                  ref="clear1"
                                  :rules="[rules.required]"
                                  prepend-inner-icon="mdi-phone"
                                  clearable
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            class="ma-2"
                            tile
                            color="red accent-4"
                            @click="dialog.value = false"
                            dark
                          >
                            <v-icon left>mdi-close-circle-outline</v-icon
                            >{{ $t("close") }}
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            class="ma-2"
                            tile
                            outlined
                            color="blue accent-4"
                            @click="updatechauffeur()"
                          >
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </template>
                </v-dialog>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                @input="searchresult"
                :label="$t('datatable-header.chauffeur.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        sort-by="libelle"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        mobile-breakpoint="800"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{
              $t("datatable-header.chauffeur.list")
            }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.action="{ item }">
          <td>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openupdatechauffeur(item)"
                  color="#f7ca18"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>{{ $t("chauffeur.update") }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="#cf000f"
                  @click="opendeleted(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>{{ $t("chauffeur.delete") }}</span>
            </v-tooltip>
          </td>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-model="current_pag"
          :total-visible="5"
          :total-rows="rows"
          :length="total_page"
          circle
        >
        </v-pagination>
      </div>
      <v-dialog v-model="delete_dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline red darken-1 white--text">{{
            $t("caisse.deleting")
          }}</v-card-title>
          <v-card-text class="black--text mt-2">
            <p>{{ deleteMessage }}</p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="red darken-1" @click="delete_dialog = false" text>{{
              $t("no")
            }}</v-btn>
            <v-btn
              color="blue darken-1"
              @click="deleted"
              text
              >{{ $t("yes") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../../components/snackbar/ErrorSnackBar";
import { mask } from "vue-the-mask";
export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    current_pag: 1,
    newchauff: false,
    updatechauff: false,
    nomChauffeur: "",
    delete_dialog: false,
    contactChauffeur: "",
    searchDepot: "",
    nom: "",
    id: "",
    idboutique: "",
    items: [],
    dialog: false,
    snackbar: false,
    loading: false,
    search: null,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => !!v || "Ce champs est requis",
    },
    dialog_save: false,
    deleteMessage: null,
    modelSelectedBoutique: null,
    delete_item: null,
  }),

  computed: {
    ...mapGetters({
      allchauffeurs: "chauffeur/allchauffeurs",
    }),
    headers() {
      return [
        {
          text: this.$t("client.name1"),
          align: "left",
          sortable: true,
          value: "nom",
        },
        {
          text: this.$t("livraison.contact"),
          align: "left",
          sortable: true,
          value: "telephone",
        },
        { text: "Actions", value: "action", sortable: false },
      ];
    },
    total_page() {
      if (this.allchauffeurs && this.allchauffeurs.last_page) {
        return this.allchauffeurs.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.allchauffeurs != null && this.allchauffeurs.data) {
        return this.allchauffeurs.data;
      } else {
        return [];
      }
    },
    rows() {
      return this.items.length;
    },
    lastpage() {
      if (this.allchauffeurs && this.allchauffeurs.last_page) {
        return this.allchauffeurs.last_page;
      } else {
        return 0;
      }
    },
  },

  watch: {
    current_pag() {
      this.next();
    },
    search(newVal) {
      if (newVal.trim() == "") {
        this.$store.dispatch(
          "chauffeur/FETCH_ALL_CHAUFFEURS",
          this.current_pag
        );
      } else {
        this.$store.dispatch("chauffeur/SEARCH_ALL_CHAUFFEURS", {
          page: this.current_pag,
          search: this.search.trim(),
        });
      }
    },
  },

  created() {},

  mounted() {
    this.$store.dispatch("chauffeur/FETCH_ALL_CHAUFFEURS", this.current_pag);
  },
  methods: {
    ...mapActions({
      chauffeurs: "chauffeur/FETCH_ALL_CHAUFFEURS",
      addnewchauffeur: "chauffeur/ADD_CHAUFFEUR",
    }),
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
    },
    save() {},
    next() {
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch(
          "chauffeur/FETCH_ALL_CHAUFFEURS",
          this.current_pag
        );
      } else {
        this.$store.dispatch("chauffeur/SEARCH_ALL_CHAUFFEURS", {
          page: this.current_pag,
          search: this.search.trim(),
        });
      }
    },
    deleted()
    {
      this.$store.dispatch(
          "chauffeur/DELETE_CHAUFFEUR",
          this.id
        ).then(()=> {
          this.delete_dialog=false
          this.$store.dispatch(
              "chauffeur/FETCH_ALL_CHAUFFEURS",
              this.current_pag
            );
            var msg = "Suppresion effectuer";
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            var msg = "error";
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
    },
    opendeleted(item) {
      this.delete_dialog=true
      this.deleteMessage =
          this.$t("item.msg1")+ this.$t("item.msg2") +
          this.$t("item.msg3");
      this.id=item.id
    },
    searchresult() {},
    openupdatechauffeur(item) {
      console.log(item);
      this.id = item.id;
      this.nomChauffeur = item.nom;
      this.contactChauffeur = item.telephone;
      this.idboutique = item.boutique.idBoutique;
      this.updatechauff = true;
    },
    updatechauffeur() {
      if (this.$refs.formupdate.validate()) {
        const chauff = {};
        chauff.nom = this.nomChauffeur;
        chauff.telephone = this.contactChauffeur;
        chauff.boutique = this.idboutique;
        this.$store
          .dispatch("chauffeur/UPDATE_CHAUFFEUR", {
            id: this.id,
            value: chauff,
          })
          .then(() => {
            this.updatechauff = false;
            this.reset();
            this.$store.dispatch(
              "chauffeur/FETCH_ALL_CHAUFFEURS",
              this.current_pag
            );
            var msg = "Modification effectuer";
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            var msg = "error";
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
      }
    },
    savechauffeur() {
      if (this.$refs.formadd.validate()) {
        const chauff = {};
        chauff.nom = this.nomChauffeur;
        chauff.telephone = this.contactChauffeur;
        chauff.boutique = JSON.parse(window.localStorage.getItem("boutiqueId"));
        this.addnewchauffeur(chauff)
          .then(() => {
            this.newchauff = false;
            this.reset();
            this.$store.dispatch(
              "chauffeur/FETCH_ALL_CHAUFFEURS",
              this.current_pag
            );
            var msg = "Enregistrement effectuer";
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            var msg = "error";
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
      }
    },
    close() {},
  },
};
</script>
<style>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
