import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import login from './modules/login'
import client from './modules/client'
import facture from './modules/facture'
import article from './modules/article'
import articles from './modules/articles'
import snackbar from './modules/snackbar'
import caisse from './modules/caisse'
import boutique from './modules/boutiques'
import categorie from './modules/categorie'
import fournisseur from './modules/fournisseur'
import typefacture from './modules/typefacture'
import inventaire from './modules/inventaire'
import payement from './modules/payement'
import dashboard from './modules/dashboard'
import utilisateur from './modules/utilisateur'
import depot from './modules/depot'
import reception from './modules/reception'
import register from './modules/register'
import abonnement  from './modules/abonnement'
import chauffeur from './modules/chauffeur'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login: login,
    client: client,
    facture: facture,
    article: article,
    articles: articles,
    snackbar: snackbar,
    caisse: caisse,
    boutique: boutique,
    categorie: categorie,
    fournisseur: fournisseur,
    typefacture: typefacture,
    inventaire: inventaire,
    payement: payement,
    dashboard: dashboard,
    utilisateur: utilisateur,
    depot: depot,
    reception: reception,
    register: register,
    abonnement:abonnement,
    chauffeur:chauffeur,
  }
})
