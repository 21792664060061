import { APIENDPOINT, instance } from "@/api/app.config";
import axios from "axios";

const state = {
  status,
  token: localStorage.getItem("conUser") || "",
  urole: [],
};

const getters = {
  gettoken: (state) => state.token,
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  getUrole: (state) => state.urole,
};

const actions = {
  LOGIN_AUTH: async ({ commit }, value) => {
    commit("AUTH_REQUEST");
    await axios
      .post(APIENDPOINT + "/api/auth/signin", value, {
        timeout: 30000,
      })
      .then(async function(response) {
        const token = response.data.accessToken;

        commit("AUTH_SUCCESS", token);
        const connected_user = response.data.user;
        commit("CONNECTED_USER", connected_user);
        return Promise.resolve();
      })
      .catch(function(err) {
        commit("AUTH_ERROR");
        return Promise.reject(err);
      });
  },

  FORGET_PASS: async ({ commit },value) => {
    console.log(value);
    commit("AUTH_LOGOUT");
    await axios
      .post(APIENDPOINT + "/api/auth/forget_password", value, {
        timeout: 30000,
      })
      .then(async function() {
        return Promise.resolve();
      })
      .catch(function(err) {
        // commit("AUTH_ERROR");
        return Promise.reject(err);
      });
  },

  LOGIN_AUTH_ROLE: async ({ commit }) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    commit("AUTH_ROLE", user.role);
  },

  LOGOUT_AUTH: ({ commit }) => {
    return new Promise(function() {
      commit("AUTH_LOGOUT");
    });
  },

  UPDATE_PROFIL: async ({ commit }, user) => {
    await instance
      .put(APIENDPOINT + "/e2v/api/profil/" + user.idU, user)
      .then(function(response) {
        commit("CHANGE_PROFILE", response.data);
      });
  },
};

const mutations = {
  AUTH_REQUEST: (state) => {
    state.status = "loading";
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = "success";
    const authUser = {};
    authUser.token = token;
    state.token = token;
    window.localStorage.setItem("conUser", JSON.stringify(authUser));
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  AUTH_ERROR: (state) => {
    state.status = "error";
  },

  CONNECTED_USER: (state, connected_user) => {
    state.urole = connected_user.role;
    window.localStorage.setItem("user", JSON.stringify(connected_user));
  },

  AUTH_ROLE: (state, role) => {
    state.urole = role;
  },

  AUTH_LOGOUT: (state) => {
    localStorage.removeItem("conUser");
    localStorage.removeItem("user");
    localStorage.removeItem("boutiqueId");
    //state.isLoggedIn = false
    state.status = "";
    state.token = "";
    delete axios.defaults.headers.common["Authorization"];
  },

  CHANGE_PROFILE: (state, new_profile) => {
    window.localStorage.setItem("user", JSON.stringify(new_profile));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
