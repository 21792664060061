<template>
  <v-container fluid>
    <!-- Début page title -->
    <!-- <PageTitle title="Infos patients" /> -->
    <!-- Fin page title -->

    <!-- Début Components -->
    <SuccessSnackBar />
    <ErrorSnackBar />
    <!-- Fin Components -->

    <v-layout>
      <v-row>
        <v-col cols="12" md="12" class="default">
          <div>
            <v-app-bar color="grey lighten-4" class="elevation-20">
              <v-toolbar-title>
                <v-dialog v-model="dialog" scrollable persistent max-width="650">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="dialog = true"
                      color="primary"
                      dark
                      class="mb-2"
                      v-on="on"
                    >
                      <v-icon left>mdi-plus-circle-outline</v-icon>{{ $t("users.new") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 500px">
                      <v-container grid-list-md>
                        <v-form ref="formUser">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.username"
                                :rules="[(v) => !!v || $t('users.required3')]"
                                :label="$t('user')"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.nom"
                                :label="$t('users.name1')"
                                :rules="[(v) => !!v || $t('users.required1')]"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.prenom"
                                :rules="[(v) => !!v || $t('users.required2')]"
                                :label="$t('users.name2')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.email"
                                :rules="[(v) => !!v || $t('users.required2')]"
                                :label="$t('users.email')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <!-- <v-flex xs12 sm12 md12>
                              <v-autocomplete
                                v-model="modelSelectedBoutique"
                                :items="matchedBoutiques"
                                item-text="nom"
                                item-value="idBoutique"
                                :label="$t('users.boutique')"
                                return-object
                              ></v-autocomplete>
                            </v-flex> -->
                            <v-flex xs12 sm12 md12>
                              <label class="typo__label">{{
                                $t("users.boutique")
                              }}</label>
                              <multiselect
                                v-model="shop"
                                :tag-placeholder="$t('users.addRole')"
                                :placeholder="$t('users.searchBoutique')"
                                label="nom"
                                track-by="idBoutique"
                                :options="matchedBoutiques"
                                :multiple="true"
                                :taggable="true"
                                @tag="addTag"
                              ></multiselect>
                            </v-flex>

                            <v-flex xs12 sm12 md12>
                              <label class="typo__label">{{ $t("users.role") }}</label>
                              <multiselect
                                v-model="value"
                                :tag-placeholder="$t('users.addRole')"
                                :placeholder="$t('users.searchRole')"
                                label="libelle"
                                track-by="idRole"
                                :options="matchedRoles"
                                :multiple="true"
                                :taggable="true"
                                @tag="addTag"
                              ></multiselect>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="editedItem.password"
                                :rules="[(v) => !!v || $t('users.required4')]"
                                :label="$t('pass')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogedit" scrollable persistent max-width="650">
                  <v-card>
                    <v-card-title class="headline blue justify-center">
                      <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 500px">
                      <v-container grid-list-md>
                        <v-form ref="formUser">
                          <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.username"
                                :rules="[(v) => !!v || $t('users.required3')]"
                                :label="$t('user')"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.nom"
                                :label="$t('users.name1')"
                                :rules="[(v) => !!v || $t('users.required1')]"
                                required
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.prenom"
                                :rules="[(v) => !!v || $t('users.required2')]"
                                :label="$t('users.name2')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md6>
                              <v-text-field
                                v-model="editedItem.email"
                                :rules="[(v) => !!v || $t('users.required2')]"
                                :label="$t('users.email')"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <label class="typo__label">{{
                                $t("users.boutique")
                              }}</label>
                              <multiselect
                                v-model="shop"
                                :tag-placeholder="$t('users.addRole')"
                                :placeholder="$t('users.searchBoutique')"
                                label="nom"
                                track-by="idBoutique"
                                :options="matchedBoutiques"
                                :multiple="true"
                                :taggable="true"
                                @tag="addTag"
                              ></multiselect>
                            </v-flex>

                            <v-flex xs12 sm12 md12>
                              <label class="typo__label">{{ $t("users.role") }}</label>
                              <multiselect
                                v-model="value"
                                :tag-placeholder="$t('users.addRole')"
                                :placeholder="$t('users.searchRole')"
                                label="libelle"
                                track-by="idRole"
                                :options="matchedRoles"
                                :multiple="true"
                                :taggable="true"
                                @tag="addTag"
                              ></multiselect>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="close" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="save"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- Début Dialog de suppression   -->
                <v-dialog v-model="delete_dialog" persistent max-width="500">
                  <v-card>
                    <v-card-title class="headline red darken-1 white--text">{{
                      $t("furnisher.deleting")
                    }}</v-card-title>
                    <v-card-text class="black--text pt-2">
                      <p>{{ deleteMessage }}</p>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="red darken-1" @click="delete_dialog = false" text>{{
                        $t("no")
                      }}</v-btn>
                      <v-btn
                        color="blue darken-1"
                        @click="deleteItem(delete_item, 1)"
                        text
                        >{{ $t("yes") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogpass" persistent max-width="500">
                  <v-card>
                    <v-card-title> </v-card-title>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-form ref="formUser">
                          <v-layout wrap>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="password"
                                :rules="[(v) => !!v || $t('users.required4')]"
                                :label="$t('pass')"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show1 = !show1"
                                :type="show1 ? 'text' : 'password'"
                                required
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm12 md12>
                              <v-text-field
                                v-model="confirmpass"
                                :rules="[(v) => !!v || $t('users.required4')]"
                                :label="$t('confirmpass')"
                                required
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="closes" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="saves"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogshop" persistent max-width="750">
                  <v-card>
                    <v-card-title> </v-card-title>
                    <v-card-text style="height: 250px">
                      <v-container grid-list-md>
                        <v-card-title class="headline red darken-1 white--text">{{
                          $t("users.deletings")
                        }}</v-card-title>
                        <v-card-text class="black--text pt-2">
                          <p>{{ $t("users.deleteMessage") }}</p>
                        </v-card-text>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="ma-2" tile color="red accent-4" @click="closses" dark>
                        <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("close") }}
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :disabled="isLoading"
                        :loading="isLoading"
                        tile
                        outlined
                        color="blue accent-4"
                        @click="savess"
                      >
                        <v-icon>mdi-content-save</v-icon>
                        {{ formAction }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Dialog de suppression   -->
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchUser"
                @input="axiosSearch"
                append-icon="mdi-magnify"
                :label="$t('users.search')"
                single-line
                hide-details
              ></v-text-field>
            </v-app-bar>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <!-- Début DataTable -->

    <v-container
      id="scroll-target"
      style="max-height: 100%; max-width: 100%"
      class="overflow-y-auto"
    >
      <v-data-table
        :headers="headers"
        :items="itemList"
        :loading="loading"
        :search="searchUser"
        sort-by="username"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ $t("users.users") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.username }}</td>
            <td>{{ item.nom }}</td>
            <td>{{ item.prenom }}</td>
            <td>
              <span v-for="rol in item.role" :key="rol.id">{{ rol.libelle }} | </span>
            </td>
            <td>
              <span v-for="shop in item.boutiques" :key="shop.idBoutique"
                >{{ shop.nom }} |
              </span>
            </td>
            <td>
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="editpass(item)">mdi-account-key</v-icon>
              <v-icon small class="mr-2" @click="editshop(item)">mdi-delete</v-icon>
              <!-- <v-icon small @click="deleteItem(item, 0)">mdi-delete</v-icon> -->
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("facture.nodata") }}</p>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-if="searchUser"
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosSearch"
          circle
        >
        </v-pagination>
        <v-pagination
          v-else
          v-model="current_pag"
          :total-visible="5"
          :length="total_page"
          @input="axiosList"
          circle
        >
        </v-pagination>
      </div>
    </v-container>
    <!-- Fin DataTable -->
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SuccessSnackBar from "../components/snackbar/SuccessSnackBar";
import ErrorSnackBar from "../components/snackbar/ErrorSnackBar";
import { mask } from "vue-the-mask";
import { APIENDPOINT } from "@/api/app.config";
import axios from "axios";
import Multiselect from "vue-multiselect";
export default {
  components: {
    SuccessSnackBar,
    ErrorSnackBar,
    Multiselect,
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: "########",
    storage: window.localStorage,
    searchUser: "",
    user: {},
    current_pag: 1,
    searchFournisseur: "",
    dialog: false,
    snackbar: false,
    show1: false,
    loading: false,
    modelSelectedRole: null,
    modelSelectedBoutique: null,
    dialog_save: false,
    delete_dialog: false,
    dialogshop: false,
    dialogpass: false,
    dialogedit: false,
    deleteMessage: null,
    delete_item: null,
    valid: true,
    select: null,
    password: null,
    confirmpass: null,
    editedIndex: -1,
    editedItem: {
      idUser: 0,
      username: "",
      password: "",
      nom: "",
      prenom: "",
      email: "",
      boutique: [
        {
          idBoutique: 0,
        },
      ],
      role: [
        {
          idRole: 0,
        },
      ],
    },
    defaultItem: {
      idUser: 0,
      username: "",
      password: "",
      nom: "",
      prenom: "",
      email: "",
      boutique: [
        {
          idBoutique: 0,
        },
      ],
      role: [
        {
          idRole: 0,
        },
      ],
    },
    value: [],
    shop: [],
    options: [],
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("user"),
          align: "left",
          sortable: true,
          value: "username",
        },
        { text: this.$t("users.name1"), value: "nom", sortable: true },
        { text: this.$t("users.name2"), value: "prenom", sortable: true },
        { text: this.$t("users.role"), value: "role", sortable: true },
        { text: this.$t("users.shop"), value: "shop", sortable: true },
        { text: "Actions", value: "action", sortable: false },
      ];
    },

    total_page() {
      if (this.user && this.user.last_page) {
        return this.user.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.user != null && this.user.data) {
        return this.user.data;
      } else {
        return [];
      }
    },
    lastpage() {
      if (this.user && this.user.last_page) {
        return this.user.last_page;
      } else {
        return 0;
      }
    },

    ...mapGetters({
      gettoken: "login/gettoken",
      allUtilisateurs: "utilisateur/getAllUsers",
      allRoles: "utilisateur/getAllRoles",
      allBoutiques: "boutique/getAllBoutiquesCurrentUser",
      allBoutiquesCurrentUser: "boutique/getAllBoutiquesCurrentUser",
    }),

    matchedRoles() {
      //if (!this.modelSelectedArticles) return [];
      return this.allRoles;
    },

    matchedBoutiques() {
      //if (!this.modelSelectedArticles) return [];
      return this.allBoutiquesCurrentUser;
    },

    formTitle() {
      return this.editedIndex === -1 ? this.$t("users.save") : this.$t("users.update");
    },

    formAction() {
      return this.editedIndex === -1 ? this.$t("item.save1") : this.$t("item.update1");
    },

    isLoading() {
      return this.loading;
    },
  },

  watch: {
    createDossierDialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.axiosList();
  },

  created() {
    this.loading = true;

    this.matchedRoles;
    this.utilisateur();
    this.boutiques();
    this.$store
      .dispatch("utilisateur/FETCH_ALL_USERS_DIRECTEUR")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
    this.role();
  },

  methods: {
    ...mapActions({
      utilisateur: "utilisateur/FETCH_ALL_USERS_DIRECTEUR",
      role: "utilisateur/FETCH_ALL_ROLES",
      boutiques: "boutique/FETCH_ALL_BOUTIQUES_CURRENT_USER",
      addNewUtilisateurDirecteur: "utilisateur/ADD_USER",
      updateUtilisateur: "utilisateur/UPDATE_USER",
      updatePassword: "utilisateur/UPDATE_PASS",
      updateShop: "utilisateur/UPDATE_SHOP",
      deleteUtilisateur: "utilisateur/DELETE_USER",
    }),

    async axiosList() {
      this.loading = true;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      await axios
        .get(
          APIENDPOINT +
            "/api/boutique/user/page/" +
            boutiqueId.idBoutique +
            "/" +
            this.current_pag,
          {
            headers: {
              Authorization: `Bearer ${token.token} `,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.user = response.data;
            if (this.user.current_page) {
              this.current_pag = response.data.current_page;
            } else {
              this.current_pag = 1;
            }
          } else {
            this.current_pag = 1;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },
    async axiosSearch() {
      this.loading = false;
      let boutiqueId = JSON.parse(window.localStorage.getItem("boutiqueId"));
      let token = JSON.parse(window.localStorage.getItem("conUser"));
      if (this.searchuser) {
        await axios
          .get(
            APIENDPOINT +
              "/api/depot/user/page/" +
              boutiqueId.idBoutique +
              "/" +
              this.current_pag +
              "/" +
              this.searchuser,
            {
              headers: {
                Authorization: `Bearer ${token.token} `,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              this.user = response.data;

              if (this.user.current_page) {
                this.current_pag = response.data.current_page;
              } else {
                this.current_pag = 1;
              }
            } else {
              this.current_pag = 1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.axiosList();
      }
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    editItem(item) {
      this.editedIndex = 0;
      this.editedItem = Object.assign({}, item);
      this.value = this.editedItem.role;
      this.shop = this.editedItem.boutiques;
      this.dialogedit = true;
    },
    editpass(item) {
      this.dialogpass = true;
      this.editedItem = Object.assign({}, item);
    },
    editshop(item) {
      this.editedItem = Object.assign({}, item);
      this.shop = this.editedItem.boutiques;
      this.dialogshop = true;
    },
    async deleteItem(item) {
      this.delete_item = item;
      console.log(this.delete_item.idUser);
      console.log(this.deleteUtilisateur(item));
      // if (confirm == 0) {
      //   this.deleteMessage =
      //     this.$t("furnisher.msg1") +

      //     this.$t("furnisher.msg2") +
      //     this.$t("furnisher.msg3");
      //   this.delete_dialog = true;
      // } else if (confirm == 1) {
      //   this.loading = true;
      //   await this.deleteUtilisateur(item)
      //     .then(() => {
      //       this.loading = false;
      //       var msg = this.$t("furnisher.warning1");
      //       this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
      //         show: true,
      //         text: msg,
      //       });
      //     })
      //     .catch(() => {
      //       this.loading = false;
      //       var msg = this.$t("furnisher.warning2");
      //       this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
      //         show: true,
      //         text: msg,
      //       });
      //     });
      //   this.delete_item = null;
      //   this.delete_dialog = false;
      //   this.axiosList();
      // }
    },
    closes() {
      this.password = "";
      this.confirmpass = "";
      this.dialogpass = false;
    },
    closses() {
      this.shop = [];
      this.dialogshop = false;
    },
    async savess() {
      const id = JSON.parse(this.storage.user);
      let users = {};
      users = Object.assign(this.editedItem);
      users.idBoutique = id.boutiques[0].idBoutique;
      users.boutiques = this.shop;

      await this.updateShop(users)
        .then(() => {
          this.loading = false;
          const msg = "La modification de l'utilisateur a bien été pris en compte.";
          this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
            show: true,
            text: msg,
          });
        })
        .catch(() => {
          this.loading = false;
          const msg =
            "Une erreur est survenue lors de la modification de l'utilisateur, veuillez réessayer svp.";
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg,
          });
        });
      this.axiosList();
      this.close();
    },
    saves() {
      if (this.password != null && this.confirmpass != null) {
        if (this.password == this.confirmpass) {
          let users = {};
          let pass = {};
          users = Object.assign(this.editedItem);
          pass.idUser = users.idUser;
          pass.nouveau = this.password;

          this.updatePassword(pass);

          let msg = "La modification de l'utilisateur a bien été pris en compte.";
          this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
            show: true,
            text: msg,
          });
          this.closes();
        } else {
          const msg = "le deux mots de passe ne corresponde pas, veuillez réessayer svp.";
          this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
            show: true,
            text: msg,
          });
        }
      }
    },
    close() {
      if (this.dialog == true) {
        this.dialog = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.value = [];
          this.shop = [];
          this.editedIndex = -1;
        }, 300);
      } else {
        this.dialogedit = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.value = [];
          this.shop = [];
          this.editedIndex = -1;
        }, 300);
      }
    },

    async save() {
      if (this.editedIndex > -1) {
        this.loading = true;
        let users = {};
        users = Object.assign(this.editedItem);
        users.boutiques = this.shop;
        users.role = this.value;
        users.token = "";
        users.langue = "FR";
        console.log(users)
        await this.updateUtilisateur(users)
          .then(() => {
            this.loading = false;
            const msg = "La modification de l'utilisateur a bien été pris en compte.";
            this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
              show: true,
              text: msg,
            });
          })
          .catch(() => {
            this.loading = false;
            const msg =
              "Une erreur est survenue lors de la modification de l'utilisateur, veuillez réessayer svp.";
            this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
              show: true,
              text: msg,
            });
          });
        this.axiosList();
        this.close();
      } else {
        if (this.$refs.formUser.validate()) {
          this.loading = true;
          //let boutiqueId = JSON.parse(window.localStorage.getItem('boutiqueId'));
          let users = {};
          users = Object.assign(this.editedItem);
          users.boutiques = this.shop;
          users.role = this.value;
          // users.password="ji",
          users.token = "";
          users.langue = "FR";
          await this.addNewUtilisateurDirecteur(users)
            .then(() => {
              this.loading = false;
              this.editedItem = Object.assign({}, this.defaultItem);
              const msg = "Le nouvel utilisateur a bien été ajouté.";
              this.$store.dispatch("snackbar/ADD_SNACK_SUCCESS", {
                show: true,
                text: msg,
              });
            })
            .catch(() => {
              this.loading = false;
              const msg =
                "Une erreur est survenue lors de l'enrégistrement de l'utilisateur, veuillez réessayer svp.";
              this.$store.dispatch("snackbar/ADD_SNACK_ERROR", {
                show: true,
                text: msg,
              });
            });
          this.axiosList();
          this.close();
        }
      }
    },
  },
};
</script>
<style scoped>
.default {
  padding-top: 3.5rem !important;
  padding-bottom: 1.5rem !important;
}
</style>
